import { Link } from 'react-router-dom'

import { buildSrc } from 'domains/Sanity/lib/helpers'

import PremiumPill from 'components/PremiumPill'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { stripMarkdown } from 'utils/stripMarkdown'

import { formatTitle } from '../utils'
import { ContentResultItemProps } from './SimilaritySearchResultItem'

const GuideItem = ({
  searchDocument,
  searchQuery,
  onResultClick,
  impressionRef,
  currentUser
}: ContentResultItemProps) => {
  const { title, highlight, thumbnail, tags, url, isFree } = searchDocument

  const thumbnailSrc = thumbnail && buildSrc(thumbnail, { width: 176 })

  return (
    <Link to={url} onClick={onResultClick}>
      {(!currentUser || currentUser?.is?.premember) && !isFree && (
        <div className="absolute top-3 right-3 z-1">
          <PremiumPill />
        </div>
      )}
      <div
        ref={impressionRef}
        className="flex rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6"
      >
        <div className="relative flex-1">
          <RfParagraphLarge className="mb-0 font-medium">
            <span dangerouslySetInnerHTML={{ __html: formatTitle(searchQuery, title) }} />
          </RfParagraphLarge>
          <div className="mt-1">
            <span
              dangerouslySetInnerHTML={{
                __html: formatTitle(searchQuery, stripMarkdown(highlight), 190)
              }}
              className="overflow-wrap break-words line-clamp-4 text-rb-black"
            />
          </div>
          <div className="mt-4 flex items-center justify-center space-x-2 sm:items-start sm:justify-start">
            {tags.map((tag, idx) => {
              return (
                <div
                  key={`tag-${idx}`}
                  className="text-ellipsis rounded-full bg-rb-gray-100 py-1 px-3 text-sm line-clamp-1 sm:text-xs"
                >
                  {tag}
                </div>
              )
            })}
          </div>
        </div>
        <div className="relative hidden pl-6 tl:block lgr:hidden xl:block">
          {thumbnailSrc && (
            <img src={thumbnailSrc} className="h-fit max-h-28 object-right" alt="" />
          )}
        </div>
      </div>
    </Link>
  )
}

export default GuideItem
