import { useMemo } from 'react'

import Button from 'components/Button'
import { PlanAndPricingInfo } from 'components/PlanOptionCard/components/PlanAndPricingInfo'
import { getPrice } from 'components/PlanOptionCard/helpers'
import { Tag } from 'components/Tag'

import {
  MAX_WIDTH_TAILWIND_TL,
  MAX_WIDTH_TAILWIND_XS,
  MIN_WIDTH_TAILWIND_SM
} from 'constants/breakpoints'
import { PlanOptionFields } from 'constants/plans'

import { CohortPassPriceTier, PlanName } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as CheckmarkIcon } from 'images/icon--checkmark-thin.svg'

enum MarketingPricingPlanCardLayout {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

interface MarketingPricingPlanCardProps {
  plan: PlanOptionFields
  cohortPassPriceTier: CohortPassPriceTier
  prevPlan?: PlanOptionFields
  className?: string
}

const MarketingPricingPlanCard = ({
  plan,
  cohortPassPriceTier,
  prevPlan,
  className
}: MarketingPricingPlanCardProps) => {
  const { removeTeamsSelfServe } = useFeatureFlags()
  const price = getPrice(plan, cohortPassPriceTier, removeTeamsSelfServe)
  const isXsWidth = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const minSMWidth = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_SM})`)
  const maxTLWidth = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)
  const isMostPopular = plan.name === PlanName.SCALE

  const layout = useMemo(() => {
    if (plan.name === PlanName.ENTERPRISE) {
      if (minSMWidth && maxTLWidth) return MarketingPricingPlanCardLayout.HORIZONTAL
      return MarketingPricingPlanCardLayout.VERTICAL
    }

    if (plan.name === PlanName.INDIVIDUAL) {
      if (minSMWidth) return MarketingPricingPlanCardLayout.HORIZONTAL
      return MarketingPricingPlanCardLayout.VERTICAL
    }

    return MarketingPricingPlanCardLayout.VERTICAL
  }, [maxTLWidth, minSMWidth, plan.name])

  const ctaText =
    plan.name === PlanName.ENTERPRISE ||
    (plan.name !== PlanName.INDIVIDUAL && removeTeamsSelfServe)
      ? 'Get in touch'
      : 'Get started'
  const destination =
    plan.name === PlanName.ENTERPRISE ||
    (plan.name !== PlanName.INDIVIDUAL && removeTeamsSelfServe)
      ? '/teams/get-in-touch'
      : `/login?fwd=${window.location.origin}/subscribe/choose-plan?internal_ref=pricing`

  const onCtaClick = () => {
    trackCtaClicked({
      cta_location: '/pricing',
      cta_type: 'button',
      destination,
      text: ctaText,
      related_identifiers: {
        plan_name: plan.name
      }
    })
  }

  const CtaButton = () => (
    <Button
      size="small"
      shape="rounded"
      onClick={onCtaClick}
      href={destination}
      className="shrink-0 whitespace-nowrap"
    >
      {ctaText}
    </Button>
  )

  const ctaForMobileIndividualAndEnterprise =
    isXsWidth && [PlanName.INDIVIDUAL, PlanName.ENTERPRISE].includes(plan.name)

  const features =
    plan.name === PlanName.STARTER
      ? [...(prevPlan?.features || []), ...plan.features]
      : plan.features

  return (
    <div
      className={cn(
        'bg-rb-white rounded-xl w-full p-6 sm:p-8 flex gap-6',
        layout === MarketingPricingPlanCardLayout.HORIZONTAL &&
          'flex-row items-center justify-between',
        layout === MarketingPricingPlanCardLayout.VERTICAL && 'flex-col',
        isMostPopular && 'shadow-[2px_2px_4px_0px_rgba(8,10,10,0.09)]',
        isMostPopular &&
          '[&]:[background:radial-gradient(162.84%_163.49%_at_-6.87%_-57.01%,#F0F2FF_0%,rgba(240,242,255,0.00)_100%),#FFF]',
        className
      )}
    >
      <div>
        <h3 className="text-[28px] lg:text-[49px] tracking-[-0.56px] lg:tracking-[-0.98px] !leading-[1] font-normal mb-1 flex items-center">
          {plan.title}

          {isMostPopular && (
            <Tag variant="blue" className="uppercase font-sans ml-4">
              Most popular
            </Tag>
          )}
        </h3>

        <p className="mb-0 text-sm lg:text-lg !leading-[1.35]">{plan.subtitle}</p>

        <div className="flex flex-col gap-2">
          <div className="pt-4 pb-2">
            <PlanAndPricingInfo price={price} />
          </div>
          <p className="m-0 text-sm !leading-[1.35]">{plan.secondSubtitle}</p>
        </div>
      </div>

      {layout === MarketingPricingPlanCardLayout.VERTICAL &&
        !ctaForMobileIndividualAndEnterprise && <CtaButton />}

      <div className="py-2">
        <p className="mb-1 text-sm lg:text-lg !leading-[1.35] font-semibold">
          {prevPlan && plan.name !== PlanName.STARTER
            ? `Everything in ${prevPlan.title}, plus`
            : 'Key Features'}
        </p>
        <ul
          className={cn(
            'flex list-none flex-col gap-3 p-0 pt-2',
            layout === MarketingPricingPlanCardLayout.HORIZONTAL &&
              'xl:grid xl:grid-cols-3 xl:gap-x-6 xl:gap-y-3'
          )}
        >
          {features.map((feature, i) => (
            <li className="flex items-center gap-4 text-sm !leading-[1.35]" key={i}>
              <CheckmarkIcon className="h-4 w-4 shrink-0" />
              {feature}
            </li>
          ))}
        </ul>
      </div>

      {(layout === MarketingPricingPlanCardLayout.HORIZONTAL ||
        ctaForMobileIndividualAndEnterprise) && (
        <div className="lg:w-[170px] xl:w-[204px] flex items-center xl:items-start">
          <CtaButton />
        </div>
      )}
    </div>
  )
}

export default MarketingPricingPlanCard
