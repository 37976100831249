import Lottie from 'lottie-react'
import { ReactElement, useEffect, useRef, useState } from 'react'

import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'

import { cn } from 'utils/tailwind'

import { ReactComponent as PlusIcon } from 'images/icon--plus-sign.svg'
import expertAndrew from 'images/marketing-extension/expert-andrew.png'
import expertCasey from 'images/marketing-extension/expert-casey.png'
import expertCrystal from 'images/marketing-extension/expert-crystal.png'
import expertDan from 'images/marketing-extension/expert-dan.png'
import expertElena from 'images/marketing-extension/expert-elena.png'
import expertJiaona from 'images/marketing-extension/expert-jiaona.png'
import expertKira from 'images/marketing-extension/expert-kira.png'
import expertRavi from 'images/marketing-extension/expert-ravi.png'
import expertShaun from 'images/marketing-extension/expert-shaun.png'
import { ReactComponent as ArcLogo } from 'images/marketing-extension/logo--arc.svg'
import { ReactComponent as BraveLogo } from 'images/marketing-extension/logo--brave.svg'
import { ReactComponent as ChromeLogo } from 'images/marketing-extension/logo--chrome.svg'
import { ReactComponent as EdgeLogo } from 'images/marketing-extension/logo--edge.svg'
import logoAmplitude from 'images/marketing-extension/logo-amplitude.png'
import logoBloomreach from 'images/marketing-extension/logo-bloomreach.png'
import logoCoda from 'images/marketing-extension/logo-coda.png'
import logoConfluence from 'images/marketing-extension/logo-confluence.png'
import logoEbay from 'images/marketing-extension/logo-ebay.png'
import logoGoogleDocs from 'images/marketing-extension/logo-google-docs.png'
import logoGrubhub from 'images/marketing-extension/logo-grubhub.png'
import logoJira from 'images/marketing-extension/logo-jira.png'
import logoLinear from 'images/marketing-extension/logo-linear.png'
import logoMeta from 'images/marketing-extension/logo-meta.png'
import logoMongoDB from 'images/marketing-extension/logo-mongodb.png'
import logoNotion from 'images/marketing-extension/logo-notion.png'
import logoToggl from 'images/marketing-extension/logo-toggl.png'
import logoWord from 'images/marketing-extension/logo-word.png'
import slide1 from 'images/marketing-extension/slide1.png'
import slide2 from 'images/marketing-extension/slide2.png'
import slide3 from 'images/marketing-extension/slide3.png'
import slide4 from 'images/marketing-extension/slide4.png'
import testimonialChris from 'images/marketing-extension/testimonial-chris.jpg'
import testimonialEryn from 'images/marketing-extension/testimonial-eryn.jpg'
import testimonialMargo from 'images/marketing-extension/testimonial-margo.jpg'

import animationDocumentStack from './animation-document-stack.json'

const supportedBrowsers = [ArcLogo, ChromeLogo, EdgeLogo, BraveLogo]

const supportedApps = [
  { title: 'Google Docs', logo: logoGoogleDocs },
  { title: 'Notion', logo: logoNotion },
  { title: 'Confluence', logo: logoConfluence },
  { title: 'Coda', logo: logoCoda },
  { title: 'Word', logo: logoWord },
  { title: 'Jira', logo: logoJira },
  { title: 'Linear', logo: logoLinear }
]

const questionsAndAnswers = [
  {
    question: 'What data do you use and store?',
    answer: (
      <p>
        The extension only uses the content of the page you&apos;re viewing to tailor
        advice, without accessing or storing any other personal or company information.
        All data processing is temporary, ensuring your privacy during each session.
      </p>
    )
  },
  {
    question: 'How do you ensure privacy with your providers?',
    answer: (
      <p>
        Our technology partners process data temporarily and adhere to strict privacy
        policies that prohibit retaining or using your information beyond providing
        immediate feedback. This ensures your data&apos;s security and confidentiality.
      </p>
    )
  },
  {
    question: 'How does this tool differ from other AI tools like ChatGPT?',
    answer: (
      <p>
        Our responses are based on expert insights from Reforge, offering higher quality
        and more targeted advice than general AI tools. Links to in-depth Reforge material
        provide further learning opportunities.
      </p>
    )
  },
  {
    question: 'Is a paid Reforge membership required?',
    answer: (
      <p>
        No paid membership is required to use the extension. Access to certain linked
        Reforge materials may require a membership.
      </p>
    )
  },
  {
    question: 'Which platforms and browsers does this extension support?',
    answer: (
      <div>
        <p className="mb-4">
          The extension works in Chrome, Arc, Edge, and Brave but not in Safari. It
          integrates with Notion, Coda, Confluence, and Google Docs.
        </p>
      </div>
    )
  }
]

type ShowcaseType = {
  title: string
  leading: string
  image: string
}

const showcases: ShowcaseType[] = [
  {
    title: 'Instant feedback, as you work',
    leading:
      'Execute faster with strategic and tactical suggestions from those who have done it before.',
    image: slide1
  },
  {
    title: 'Create documents like an expert',
    leading:
      'Generate drafts in minutes using Reforge templates—answer a few questions and get 80% of the work done instantly.',
    image: slide2
  },
  {
    title: 'Issue suggestions in Jira and Linear',
    leading:
      'Suggestions to help you write well thought out issues to ensure you don’t miss anything.',
    image: slide3
  },
  {
    title: 'Built with privacy first',
    leading:
      'Your data remains completely private because we don’t store or use it for training—ensuring that your sensitive information is secure and never retained beyond your immediate use.',
    image: slide4
  }
]

const experts = [
  {
    headshotImage: expertShaun,
    alt: 'Shaun Clowes, CPO at Confluent'
  },
  {
    headshotImage: expertCasey,
    alt: 'Casey Winters, ex-CPO at Eventbrite'
  },
  {
    headshotImage: expertCrystal,
    alt: 'Crystal Widjaja, ex-Chief of staff at GoJek'
  },
  {
    headshotImage: expertDan,
    alt: 'Dan Hockenmaier, Chief Strategy Officer at Faire'
  },
  {
    headshotImage: expertRavi,
    alt: 'Ravi Mehta, Ex-CPO at Tinder'
  },
  {
    headshotImage: expertJiaona,
    alt: 'Jiaona Zhang, CPO at Linktree'
  },
  {
    headshotImage: expertAndrew,
    alt: 'Andrew Chen, VC at A16Z'
  },
  {
    headshotImage: expertKira,
    alt: 'Kira Klass, Head of Campaigns at Notion'
  },
  {
    headshotImage: expertElena,
    alt: 'Elena Verna, Head of Growth at Dropbox'
  }
]

const InstallCTA = () => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-center mb-6">
        {supportedBrowsers.map((BrowserComponent, i) => (
          <div key={i} className="w-6 h-6 flex items-center justify-center mx-2">
            <BrowserComponent />
          </div>
        ))}
      </div>
      <ButtonMarketingCTA
        href="https://chromewebstore.google.com/detail/reforge-extension/iheecpmppehgghpjbhdpgjdllemcmdnk"
        location="/chrome page"
        ctaText="Add to chrome"
        target="_blank"
        className="text-xl font-semibold rounded-lg h-16 px-10"
      >
        <span>Install for free</span>
      </ButtonMarketingCTA>
    </div>
  )
}

const Showcase = ({ showcase, index }: { showcase: ShowcaseType; index: number }) => {
  const alternate = index % 2 === 1
  const last = index === showcases.length - 1
  return (
    <div
      className={cn(
        'flex flex-col lg:flex-row w-full items-center justify-center mb-32 md:mb-40',
        { 'lg:flex-row-reverse': alternate && !last },
        { 'lg:flex-col': last }
      )}
    >
      <div
        className={cn(
          'w-full lg:flex lg:items-center lg:justify-center lg:pr-24',
          { 'lg:pr-0 lg:pl-[36px]': alternate && !last },
          { 'lg:px-0': last }
        )}
      >
        <div>
          <h2
            className={cn(
              'text-[#0C0F0D] text-[32px] md:text-5xl leading-[120%] md:leading-[116%] font-polysans !font-semibold text-center mb-3 md:mb-4 lg:text-left',
              {
                'lg:text-center': last
              }
            )}
          >
            {showcase.title}
          </h2>
          <p
            className={cn('leading-[150%] text-center text-lg mb-16 lg:text-left', {
              'lg:text-center': last
            })}
          >
            {showcase.leading}
          </p>
        </div>
      </div>

      <div
        className={cn('lg:min-w-[530px] max-w-[530px]', {
          'lg:min-w-[442px] max-w-[442px]': last
        })}
        style={{ margin: '0 auto' }}
      >
        <img src={showcase.image} alt="" width="530" className="inline-block" />
      </div>
    </div>
  )
}

const Experts = () => {
  /* negative margin matches <main /> element */
  return (
    <div className="overflow-hidden -mx-4 md:-mx-[4vw]">
      <ul className="list-none pl-0 w-full flex justify-center">
        {experts.map((expert, i) => (
          <li key={i} className="shrink-0 mx-4">
            <img
              src={expert.headshotImage}
              alt={expert.alt}
              width="280"
              height="340"
              className="rounded-2xl"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

const QuestionAndAnswer = ({
  question,
  answer
}: {
  question: string
  answer: ReactElement
  key: number
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggle = () => {
    setExpanded(!expanded)
  }
  return (
    <div className="border-b border-black/10 mb-6 last:mb-0">
      <div className="flex justify-between items-start mb-6">
        <h3 className="!font-sans font-normal text-2xl leading-[130%] w-full grow text-[#0C0F0D] mb-0 mr-4">
          <button onClick={toggle} className="block text-left">
            {question}
          </button>
        </h3>
        <button
          onClick={toggle}
          className={cn('transition-all duration-500', expanded && 'rotate-45')}
        >
          <PlusIcon width={16} height={16} />
        </button>
      </div>
      <div
        className={cn(
          'max-h-0 overflow-hidden transition-all duration-500 max-w-[700px]',
          expanded && 'max-h-[150px]'
        )}
      >
        <div className="mb-0 pb-6 leading-[150%]">{answer}</div>
      </div>
    </div>
  )
}

const usedByLogos = [
  { title: 'Grubhub', file: logoGrubhub },
  { title: 'Meta', file: logoMeta },
  { title: 'Toggle', file: logoToggl },
  { title: 'Amplitude', file: logoAmplitude },
  { title: 'Ebay', file: logoEbay },
  { title: 'Bloomreach', file: logoBloomreach },
  { title: 'MongoDB', file: logoMongoDB }
]

type TestimonialType = {
  quote: ReactElement
  avatar: string
  author: string
  role: string
}

const testimonials: TestimonialType[] = [
  {
    quote: (
      <>
        I love having Reforge in Notion, helping to suggest and draft improvements
        grounded in the industry&apos;s best knowledge.
      </>
    ),
    avatar: testimonialChris,
    author: 'Christopher Ming',
    role: 'Principal PM at Persefoni'
  },
  {
    quote: (
      <>
        Provides valuable feedback, like your peers or boss would, challenging your
        briefs. And when it comes to drafting something, it gives you a great head start,
        with a more concise and authentic tone than other LLMs out there.
      </>
    ),
    avatar: testimonialMargo,
    author: 'Margo Rey',
    role: 'Senior PM at MadKudu'
  },
  {
    quote: (
      <>
        Easy to use and helpful for finding product artifacts and AI tips to make your
        work life easier.
      </>
    ),
    avatar: testimonialEryn,
    author: 'Eryn Hernandez',
    role: 'Principal PM at Twilio'
  }
]

const Testimonial = ({ testimonial }: { testimonial: TestimonialType }) => {
  return (
    <div className="p-6 rounded-lg bg-black/[0.03]">
      <blockquote className="not-italic text-base">
        <p className="leading-[150%] text-[#0C0F0D] !mb-12">{testimonial.quote}</p>
        <footer className="flex before:content-['']">
          <img
            className="w-12 h-12 mr-4 bg-black rounded-full"
            width="48px"
            height="48px"
            alt={`${testimonial.author} Avatar`}
            src={testimonial.avatar}
          />
          <div>
            <h3 className="!font-sans !font-semibold text-base mb-0 leading-[150%] text-[#0C0F0D]">
              {testimonial.author}
            </h3>
            <p className="text-sm leading-[150%] text-[#717171]">{testimonial.role}</p>
          </div>
        </footer>
      </blockquote>
    </div>
  )
}

export const ExtensionMarketingPageContent = () => {
  const setHeaderBackgroundColor = () => {
    const header = document.querySelector('.marketing-site header')
    if (header) {
      const utilityBar = document.querySelector('.marketing-site .invisible')
      if (utilityBar) {
        header.classList.add('!bg-[#F5F5F0]')
        utilityBar.classList.add('!bg-[#F5F5F0]')
      }
    }
  }

  const ref = useRef(false)
  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  if (ref.current) {
    setTimeout(() => {
      setHeaderBackgroundColor()
    }, 2000)
  }

  return (
    <main className="px-[16px] pt-14 lg:pt-[76px] pb-14 md:px-[4vw] flex flex-col items-center text-[#0C0F0D] bg-[#F5F5F0]">
      <section className="max-w-[1048px] w-full flex flex-col items-center mb-16">
        <aside className="mb-4 flex items-center leading-[150%] font-medium text-lg">
          Reforge extension
        </aside>
        <h1 className="text-5xl md:text-[80px] leading-[120%] mb-4 font-polysans text-center text-[#0C0F0D] !font-semibold">
          <span className="inline-block bg-[linear-gradient(274deg,_#9487FF_50.4%,_#64D7FF_84.3%)] bg-clip-text text-transparent">
            Supercharge
          </span>{' '}
          your work
        </h1>
        <p className="mb-12 text-center text-[22px] leading-[150%]">
          Make smarter decisions with expertise from leading tech experts in your
          workflow.
        </p>
        <div className="mb-16">
          <InstallCTA />
        </div>
        <div className="w-full">
          <Lottie
            animationData={animationDocumentStack}
            loop={true}
            className="chromatic-ignore"
          />
        </div>
      </section>

      <section className="max-w-[560px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="!font-sans font-normal text-[#0C0F0D] text-lg leading-[150%] text-center mb-8">
          Helping teams do better work
        </h2>
        <ul className="list-none pl-0 w-full flex flex-wrap gap-x-12 gap-y-8 justify-center items-center">
          {usedByLogos.map((logo) => (
            <li key={logo.title} className="flex justify-center">
              <img src={logo.file} className="h-8" alt={`${logo.title} Logo`} />
            </li>
          ))}
        </ul>
      </section>

      <section className="w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-5xl leading-[105%] md:leading-[120%] font-polysans !font-semibold text-center mb-3 md:mb-4">
          Grounded in the work from leading experts
        </h2>
        <p className="leading-[150%] tracking-[-0.176px] text-center text-lg mb-10">
          Make better decisions with the expertise from those who have done it before.
        </p>
        <Experts />
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center px-4 md:p-0">
        {showcases.map((showcase, i) => (
          <Showcase showcase={showcase} index={i} key={i} />
        ))}
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center px-4 md:p-0 mb-[72px] md:mb-[94px]">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-5xl leading-[105%] md:leading-[120%] font-polysans !font-semibold text-center mb-3 md:mb-4">
          Works where you work
        </h2>
        <p className="leading-[150%] tracking-[-0.176px] text-center text-lg mb-10 md:mb-14">
          For a seamless workflow.
        </p>
        <ul className="list-none pl-0 flex flex-row flex-wrap items-center justify-center gap-8 mb-14">
          {supportedApps.map((app) => (
            <li
              key={app.title}
              className="flex flex-col items-center justify-center rounded-xl bg-black/[0.03] w-[144px] h-[144px] md:w-[184px] md:h-[184px]"
            >
              <img
                src={app.logo}
                alt={`${app.title} Logo`}
                className="mb-6 shrink-0 w-8 md:w-12"
              />
              {app.title}
            </li>
          ))}
        </ul>
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-5xl leading-[105%] md:leading-[120%] font-polysans !font-semibold text-center mb-10">
          Teams doing better work
        </h2>
        <ul className="list-none pl-0 w-full grid gap-8 grid-cols-1 md:grid-cols-3">
          {testimonials.map((testimonial) => (
            <li key={testimonial.author}>
              <Testimonial testimonial={testimonial} />
            </li>
          ))}
        </ul>
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-5xl leading-[105%] md:leading-[120%] font-polysans !font-semibold text-center mb-3 md:mb-4">
          FAQs
        </h2>
        <ul className="list-none pl-0 w-full">
          {questionsAndAnswers.map((qa, i) => (
            <QuestionAndAnswer key={i} question={qa.question} answer={qa.answer} />
          ))}
        </ul>
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-5xl md:leading-[130%] font-polysans !font-semibold text-center mb-8 md:mb-12">
          Work with an expert by your side
        </h2>
        <div>
          <InstallCTA />
        </div>
      </section>
    </main>
  )
}

export default ExtensionMarketingPageContent
