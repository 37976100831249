import GuidesHeroImage from 'guides-hero-bg.png'
import RectangleStack from 'rectangle-stack.png'

export const Hero = () => {
  return (
    <div className="mb-12 flex h-[193px] items-center justify-between overflow-hidden rounded-xl bg-rb-orange-25 py-0 pl-6 sm:mr-6">
      <div className="my-6 mr-6 flex w-[560px] flex-col">
        <h2 className="font-polysans text-lg !font-light sm:text-2xl">
          Your shortcut to getting work done
        </h2>
        <p className="text-sm sm:text-base">
          Step-by-step guides you can finish in 30 minutes or less give you a head start
          with the knowledge you need, right when you need it
        </p>
      </div>
      <div className="relative ml-auto hidden h-full w-[250px] md:block">
        <img
          className="absolute right-0 h-full w-full"
          alt="Guides Hero"
          src={GuidesHeroImage}
        />
        <img
          className="absolute top-[20px] right-[55px] h-[136px]"
          alt="Rectangle Stack"
          src={RectangleStack}
        />
      </div>
    </div>
  )
}

export default Hero
