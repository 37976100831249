import { FC } from 'react'

import { AnnouncementBanner } from '../AnnouncementBanner/AnnouncementBanner'
import { AnnouncementBannerProps } from '../AnnouncementBanner/AnnouncementBannerCarousel'

export const RecommendationsBanner: FC<AnnouncementBannerProps> = ({
  isVisible,
  completeProductTour
}) => {
  return (
    <AnnouncementBanner
      firstCta={{
        text: 'Dismiss',
        url: '/',
        onClick: completeProductTour
      }}
      color="#F5D6C3"
      textColor="#000"
      header="See your latest recommendations"
      subHeader="Your homepage now includes more personalized course and content recommendations based on your preferences and recent activity"
      isVisible={isVisible}
    />
  )
}
