import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import {
  CollapsibleContentBlock,
  typeForDisplay
} from 'pages/UnitLandingPage/UnitContentBlock'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CclContentBlockPartsFragment,
  CourseDashboardCourseSessionPartsFragment,
  CourseDetailCourseBlockPartsFragment
} from 'gql'

import {
  CourseRelatedIdentifierProperties,
  trackCourseDashboardContentClicked,
  useContentMode
} from 'utils/contentTrackingUtils'
import { buildCourseOnDemandLink, buildCourseSessionLink } from 'utils/courseUtils'

import { ReactComponent as LightBulbRaysIcon } from 'images/icon--light-bulb-rays.svg'
import { ReactComponent as VideoIcon } from 'images/icon--video.svg'

interface CourseContentBlockUnitProps {
  courseBlockIndex: number
  cclCourseBlock: CourseDetailCourseBlockPartsFragment
  contentBlock: CclContentBlockPartsFragment
  courseSlug: string
  isOnDemandCourse?: boolean
  courseSession?: CourseDashboardCourseSessionPartsFragment
  courseRelatedIdentifiers?: CourseRelatedIdentifierProperties
}

const CourseContentBlockUnit = ({
  cclCourseBlock,
  contentBlock,
  courseBlockIndex,
  courseSlug,
  courseSession,
  isOnDemandCourse,
  courseRelatedIdentifiers
}: CourseContentBlockUnitProps) => {
  const history = useHistory()
  const contentMode = useContentMode({ courseSession })

  const thereIsLink =
    cclCourseBlock.kind !== 'module' &&
    (contentBlock.description || contentBlock.contentBlockCollapsible)

  const onContentBlockClick = () => {
    if (!thereIsLink) {
      return null
    }

    trackCourseDashboardContentClicked({
      contentType: 'flex_content',
      contentTitle: contentBlock.header || '',
      contentCclLocalId: contentBlock.id,
      contentIndex: contentBlock.order || 0,
      courseBlock: cclCourseBlock,
      courseBlockIndex,
      contentMode: contentMode || '',
      courseRelatedIdentifiers
    })

    if (courseSession) {
      const destination = buildCourseSessionLink({
        courseBlockIndex,
        sessionId: courseSession.id,
        courseSlug,
        contentBlockSlug: contentBlock.slug
      })

      history.push(destination)
    }

    if (isOnDemandCourse) {
      const destination = buildCourseOnDemandLink({
        courseBlockIndex,
        courseSlug,
        contentBlockSlug: contentBlock.slug
      })
      history.push(destination)
    }
  }
  const renderCourseBlockContent = () => {
    return (
      <>
        {contentBlock.description && (
          <UnitComplexPortableText
            renderParaAsSubheading
            content={JSON.parse(contentBlock.description)}
            forcePhotoLoad
          />
        )}
        {contentBlock.contentBlockCollapsible &&
          contentBlock.contentBlockCollapsible.map((collapsibleContentItem, i) => (
            <CollapsibleContentBlock
              key={i}
              type={typeForDisplay(collapsibleContentItem.type)}
              label={collapsibleContentItem.label}
              description={JSON.parse(collapsibleContentItem.description)}
            />
          ))}
      </>
    )
  }

  return (
    <div
      data-test="course_block_unit"
      id={`${courseBlockIndex}-${contentBlock.slug}`}
      className={twMerge('group flex items-center', thereIsLink ? 'cursor-pointer' : '')}
      role={thereIsLink ? 'button' : undefined}
      tabIndex={0}
      onClick={onContentBlockClick}
      onKeyUp={onContentBlockClick}
    >
      <div
        className={twMerge(
          'group relative mr-6 flex h-[50px] min-w-[50px] items-center justify-center rounded-xl bg-rb-gray-50',
          thereIsLink ? 'group-hover:bg-rb-orange-25' : ''
        )}
      >
        {contentBlock.hasVideoContent ? (
          <VideoIcon height="16" width="16" />
        ) : (
          <LightBulbRaysIcon height="16" width="16" />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <RfParagraphSmall>
          <span className="text-rb-gray-400">{contentBlock.header}</span>
        </RfParagraphSmall>
        {cclCourseBlock.kind === 'module' && renderCourseBlockContent()}
      </div>
    </div>
  )
}

export default CourseContentBlockUnit
