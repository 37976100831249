import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'
import ClientLogos from 'components/MarketingSite/ClientLogos/ClientLogos'

import { Page, useTeamsGetInTouchPageQuery } from 'gql'

import TeamsHubspotContactForm from './TeamsHubspotContactForm/TeamsHubspotContactForm'

export const TeamsContactPageContent = () => {
  return (
    <>
      <section className="relative mb-[40px] flex flex-col items-center justify-center bg-teams-get-in-touch bg-cover bg-center bg-no-repeat py-[4vmax] px-[16px] md:items-start md:px-[4vw]">
        <div className="w-full">
          <h1 className="mb-[32px] text-center font-polysans !font-light leading-none tracking-tight fluid-text-6xl md:text-left">
            Contact Reforge for Teams
          </h1>

          <p className="text-center text-lg md:text-left">
            Reach out about pricing, a demo, or team enrollment. We’ll be in touch shorty!
          </p>

          <div className="justify-items-center] mb-[50px] grid auto-rows-min items-center gap-[20px] md:mb-0 md:grid-cols-2 md:gap-[50px]">
            <TeamsHubspotContactForm />
            <blockquote className="max-w-[350px] justify-self-center text-2xl">
              <p className="ml-[24px] font-serif">
                <i>
                  “Reforge provides thinking tools — patterns, frameworks and language —
                  to simplify and discuss complex topics, and if the team was all familiar
                  with this content, they would likely do everything at least 5% better.”
                </i>
              </p>
              <footer className="mt-[16px]">
                <i>
                  Paul Scharf, <cite>CTO at Toggl</cite>
                </i>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
      <section className="mt-[120px] bg-rb-background-beige px-[16px] pb-[6.6vmax] md:px-[4vw]">
        <ClientLogos />
        <div className="flex justify-center">
          <ButtonMarketingCTA href="/teams">Back to Teams</ButtonMarketingCTA>
        </div>
      </section>
    </>
  )
}

const TeamsGetInTouchPage = () => {
  const { data, loading } = useTeamsGetInTouchPageQuery()

  return (
    <MarketingLayout page={(data?.teamsGetInTouchPage || {}) as Page}>
      {loading ? <Loading /> : <TeamsContactPageContent />}
    </MarketingLayout>
  )
}

export default TeamsGetInTouchPage
