import { SearchLinkType } from 'domains/Search/useSearchResultsFilters'

import { SkeletonSearchResultItem } from 'components/skeletons/cards/SkeletonSearchResultItem'
import SkeletonRfHeader from 'components/skeletons/typography/SkeletonRfHeader'

import { CurrentUserPartsFragment, SimilarityContentFragment } from 'gql'

import SimilaritySearchResultSection, {
  UserCanProps
} from './SimilaritySearchResultSection'

interface SimilaritySearchResultsListProps {
  searchQuery: string
  userCan: UserCanProps
  currentUser: CurrentUserPartsFragment | null
  handleResultClick: (
    urL: string,
    path: string,
    searchPosition: number,
    resultComponent?: string,
    type?: string,
    subtype?: string | null,
    doc?: object | null
  ) => void
  itemsDisplayed?: number
  sectionHeader: string
  hideHeader?: boolean
  loading: boolean
  searchResults?: SimilarityContentFragment[]
  showViewMoreLink?: boolean
  linkType: SearchLinkType
}

const SimilaritySearchResultsList = ({
  searchQuery,
  handleResultClick,
  currentUser,
  userCan,
  loading,
  searchResults,
  sectionHeader,
  linkType,
  itemsDisplayed = -1,
  hideHeader = false,
  showViewMoreLink = true
}: SimilaritySearchResultsListProps) => {
  if (loading) {
    return (
      <div>
        <SkeletonRfHeader className="mb-2 mt-4 h-8 w-1/4" />
        <div className="mt-6">
          {Array.from({ length: 3 }).map((_, i) => (
            <SkeletonSearchResultItem className="mb-4" key={i} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <SimilaritySearchResultSection
      itemsDisplayed={itemsDisplayed}
      sectionHeader={sectionHeader}
      sectionResults={searchResults || []}
      sectionCount="more"
      hideHeader={hideHeader}
      showViewMoreLink={showViewMoreLink}
      userCan={userCan}
      currentUser={currentUser}
      linkType={linkType}
      handleResultClick={handleResultClick}
      searchQuery={searchQuery}
      impressionTrackingProperties={{
        location: `search__${sectionHeader.toLowerCase().replace(' ', '_')}_tab`
      }}
    />
  )
}

export default SimilaritySearchResultsList
