import clsx from 'clsx'
import React, { useRef, useState } from 'react'

import { SVGIcon } from 'components/Icon'

import { UserSearchFilters } from 'gql'

import useOnClickOutside from 'hooks/useOnClickOutside'

import { onSpaceKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

const PlusSign = <SVGIcon name="reveal-plus" height="20" width="20" fill="#0f0f0f" />
const MinusSign = <SVGIcon name="hide-minus" height="20" width="20" fill="#0f0f0f" />
const UncheckedBox = (
  <SVGIcon name="checkbox-empty" fill="#d3d2d3" width="20" height="20" />
)
const CheckedBox = (
  <SVGIcon name="checkbox-ticked" fill="#1e1e1e" width="20" height="20" />
)

type Checkbox = {
  name: string
  boxLabel: string
  checked: boolean
}

interface CheckboxFilterProps {
  checkboxes: Checkbox[]
  name: keyof UserSearchFilters
  label?: string
  className?: string
  filters: UserSearchFilters
  setCheckboxes: (checkboxes: Checkbox[]) => void
  setFilters: (filters: UserSearchFilters) => void
  text?: string
  neverCollapse?: boolean
  hideReset?: boolean
  hideBorder?: boolean
  collapseOnOutsideClick?: boolean
}

const CheckboxFilter = ({
  checkboxes,
  label,
  name,
  filters,
  setCheckboxes,
  setFilters,
  text = '',
  className,
  neverCollapse = false,
  hideReset = false,
  hideBorder = false,
  collapseOnOutsideClick = true
}: CheckboxFilterProps) => {
  const [isExpanded, setIsExpanded] = useState(neverCollapse)

  const getParameters = () =>
    checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.name)

  const canReset = getParameters().length !== 0

  const toggleCheckbox = (boxName: string) => {
    const updatedCheckboxes = checkboxes.map((box) => {
      if (box.name === boxName) {
        box.checked = !box.checked
      }
      return box
    })
    setCheckboxes(updatedCheckboxes)
    setFilters({ ...filters, [name]: getParameters() })
  }

  const handleReset = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation()
    setFilters({ ...filters, [name]: '' })
  }

  const ref = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, () => collapseOnOutsideClick && setIsExpanded(false), {
    enabled: isExpanded
  })

  return (
    <div ref={ref}>
      <div
        className={clsx(
          'mt-5 rounded-sm px-[11px] text-sm',
          !hideBorder && 'border border-rb-gray-100',
          isExpanded && 'pb-6',
          className
        )}
      >
        <div
          className={cn('flex items-center justify-between', label && 'h-[46px]')}
          onClick={() => !neverCollapse && setIsExpanded(!isExpanded)}
          onKeyUp={onSpaceKeyPress(() => !neverCollapse && setIsExpanded(!isExpanded))}
          role="menu"
          tabIndex={0}
        >
          {label && (
            <label className="mb-0 block text-sm font-medium capitalize leading-[14px]">
              {label}
            </label>
          )}
          <div className="flex">
            {!hideReset && canReset && (
              <div
                className="cursor-pointer px-[7px] text-center text-sm leading-6 text-rb-gray-300 hover:text-rb-teal-200"
                onClick={(e) => handleReset(e)}
                onKeyUp={onSpaceKeyPress((e) => handleReset(e))}
                role="button"
                tabIndex={0}
              >
                Reset
              </div>
            )}
            {!neverCollapse && (
              <div className="mb-[3px]">{isExpanded ? MinusSign : PlusSign}</div>
            )}
          </div>
        </div>
        {isExpanded && (
          <>
            {text && <p className="m-0 w-[95%] text-sm leading-5">{text}</p>}
            <div>
              {checkboxes.map(({ name, checked, boxLabel }) => (
                <div
                  key={name}
                  className="flex cursor-pointer items-center py-2.5 pl-2 hover:bg-rb-gray-50"
                  onClick={() => toggleCheckbox(name)}
                  onKeyUp={onSpaceKeyPress(() => toggleCheckbox(name))}
                  role="checkbox"
                  tabIndex={0}
                  aria-checked={checked}
                >
                  {checked ? CheckedBox : UncheckedBox}
                  <div
                    className={clsx(
                      'pl-[9px] text-rb-gray-300 ',
                      checked && 'font-medium text-rb-gray-500'
                    )}
                  >
                    {boxLabel}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CheckboxFilter
