import { useEffect, useRef, useState } from 'react'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import { useGlobalChatTracking } from 'domains/Ai/GlobalChatTrackingProvider'

import Button from 'components/Button'
import CalloutV2 from 'components/CalloutV2'
import Dropdown from 'components/dropdowns/Dropdown'
import { ChevronDownThin } from 'components/icons'
import { PaperAndPencilIcon } from 'components/icons/PaperAndPencilIcon'
import { SparkleIcon } from 'components/icons/SparkleIcon'

import {
  ProductTourKey,
  useCompleteProductTourMutation,
  useCompletedProductToursQuery
} from 'gql'

import { trackMenuClicked } from 'utils/tracking/analytics'

import { getArtifactLinks, openOpenInLink } from './utils'

interface StartDraftFromButtonProps {
  artifact: any // @todo: define type
  showCallout?: boolean
}

const StartDraftFromButton = ({
  artifact,
  showCallout = false
}: StartDraftFromButtonProps) => {
  const { trackChatDraftStarted } = useGlobalChatTracking()
  const { data: productTourData } = useCompletedProductToursQuery()
  const [completeProductTour] = useCompleteProductTourMutation()
  const calloutHasBeenShown = useRef(false)
  const [isCalloutShown, setIsCalloutShown] = useState(false)
  const { openChatAndSendMessage, setMode, endSession, messages, chatId } =
    useGlobalChat()

  const artifactLinks = getArtifactLinks(artifact) || []

  useEffect(() => {
    if (
      showCallout &&
      productTourData &&
      !productTourData.currentUser?.completedProductTours?.startDraftFromButton &&
      !calloutHasBeenShown.current
    ) {
      setTimeout(() => {
        setIsCalloutShown(true)
      }, 2000)
      calloutHasBeenShown.current = true
    }
    completeProductTour({
      variables: {
        input: {
          productTourKey: ProductTourKey.START_DRAFT_FROM_BUTTON
        }
      }
    })
  }, [showCallout, productTourData, completeProductTour])

  const handleDraftClick = () => {
    const templateName = artifact.draftTemplateName
    const labelName = artifact.draftLabelName
    if (messages.length > 0) endSession()

    trackChatDraftStarted({
      chatId: chatId,
      templateName: labelName,
      location: 'artifact-navigation'
    })
    setMode({
      mode: 'document_generation',
      modeOptions: { command: 'draft', template_name: templateName, label: labelName }
    })
    openChatAndSendMessage(`Help me draft a ${labelName} document`, 'artifact_viewer', {
      mode: 'document_generation',
      modeOptions: { command: 'draft', template_name: templateName, label: labelName }
    })
  }

  const triggerElement = (
    <Button
      color="teal"
      size="medium"
      className="h-10 w-[170px] cursor-pointer justify-center font-semibold px-4"
      iconBefore={<PaperAndPencilIcon />}
      iconAfter={<ChevronDownThin className="ml-2 h-4 w-3 fill-current" />}
    >
      Create draft
    </Button>
  )

  return (
    <div className="relative">
      <Dropdown
        triggerElement={triggerElement}
        positions={['bottom']}
        padding={10}
        align="end"
        dismissOnClick={true}
        containerClassName="shadow-2xl"
      >
        <div className="border border-rb-gray-100 bg-white px-1 py-2 w-60">
          <button
            onClick={handleDraftClick}
            className="flex h-8 w-full items-center px-2 py-1 hover:bg-rb-gray-50 hover:rounded-md"
          >
            <SparkleIcon className="mr-2" pathClassName="fill-rb-black" />
            Draft this for me
          </button>
          {artifactLinks.map(({ key, src, type }) => {
            return (
              <button
                key={key}
                onClick={() => {
                  trackMenuClicked({
                    content_id: artifact.id,
                    content_title: artifact.title,
                    menu_name: 'use in tool',
                    trigger: 'selection made',
                    selection: type
                  })

                  openOpenInLink({ href: artifact[key], external: true })
                }}
                className="flex h-8 w-full items-center px-2 py-1 hover:bg-rb-gray-50 hover:rounded-md"
              >
                <div className="h-4 w-4">
                  <img alt={type} src={src} className="h-4 w-4" />{' '}
                </div>
                <span className="ml-2">Open in {type}</span>
              </button>
            )
          })}
        </div>
      </Dropdown>
      {isCalloutShown && (
        <CalloutV2
          position="bottomLeft"
          ctaAction={() => {
            setIsCalloutShown(false)
            handleDraftClick()
          }}
          betaTag={true}
          ctaText="Try it now"
          title="Create a draft"
          bodyText="Turn select artifacts into your own draft in seconds"
        />
      )}
    </div>
  )
}

export default StartDraftFromButton
