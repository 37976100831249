import { useLocation } from 'react-router-dom'

import { COMMENT_CLASSNAME } from 'constants/artifacts'

import AirtableSrc from 'assets/images/airtable.svg'
import CodaSrc from 'assets/images/coda.svg'
import FigmaSrc from 'assets/images/figma.svg'
import GoogleDriveSrc from 'assets/images/google-drive.svg'
import MiroSrc from 'assets/images/miro.svg'
import MuralSrc from 'assets/images/mural.png'
import NotionSrc from 'assets/images/notion.svg'
import WhimsicalSrc from 'assets/images/whimsical.png'

import type { AuthorBylineProps } from './artifact-types'

const isAuthorCurrentlyAtWorkEntry = (
  author: { companyName: string; position: string },
  workEntry: { companyName: string; position: string }
) => {
  return (
    author &&
    workEntry &&
    author.companyName === workEntry.companyName &&
    author.position === workEntry.position
  )
}

const getArtifactLinks = (
  artifact: any
): { key: string; type: string; src: string }[] => {
  const supportedApps = [
    { key: 'airtableUrl', type: 'Airtable', src: AirtableSrc },
    { key: 'codaUrl', type: 'Code', src: CodaSrc },
    { key: 'figmaUrl', type: 'Figma', src: FigmaSrc },
    { key: 'googleUrl', type: 'Google', src: GoogleDriveSrc },
    { key: 'notionUrl', type: 'Notion', src: NotionSrc },
    { key: 'miroUrl', type: 'Miro', src: MiroSrc },
    { key: 'muralUrl', type: 'Mural', src: MuralSrc },
    { key: 'whimsicalUrl', type: 'Whimsical', src: WhimsicalSrc }
  ]

  return supportedApps.filter(({ key }) => {
    if (!artifact[key]) {
      return false
    }
    return Boolean(artifact[key]?.trim())
  })
}

const scrollToLastComment = () => {
  const comments = document.getElementsByClassName(COMMENT_CLASSNAME)
  if (comments.length > 0) {
    comments[comments.length - 1].scrollIntoView({ behavior: 'smooth' })
  }
}

const authorsToProps = (authors: any): AuthorBylineProps[] => {
  return authors.map((author: any) => {
    const relevantWorkEntry = author?.relevantWorkEntry

    // this is the artifact's source if it is different from the author's current position
    const source =
      isAuthorCurrentlyAtWorkEntry(author, relevantWorkEntry) || !relevantWorkEntry
        ? undefined
        : relevantWorkEntry.specificCompany === false
          ? { companyName: undefined, role: relevantWorkEntry.generalRole } // the source is independent, so no companyName
          : {
              companyName: relevantWorkEntry.companyName,
              role: relevantWorkEntry.position
            }

    return { author, source }
  })
}

export function createCreatorsLandingPageUrl(location: ReturnType<typeof useLocation>) {
  const internalRef =
    location.pathname === '/' ? 'home' : location.pathname.split('/')[1] || ''

  return `/creators${internalRef ? `?internal_ref=${internalRef}` : ''}`
}

const openOpenInLink = ({
  href,
  external = true
}: {
  href: string
  external: boolean
}) => {
  try {
    if (href) {
      window.open(href, `${external ? '_blank' : '_self'}`)
    }
  } catch (err) {
    console.error('Error opening link:', err)
  }
}

export { getArtifactLinks, scrollToLastComment, authorsToProps, openOpenInLink }
