import { useEffect, useState } from 'react'

interface CountdownProps {
  seconds: number
  includeZero?: boolean
}

export const Countdown = ({ seconds, includeZero = false }: CountdownProps) => {
  const [counter, setCounter] = useState(seconds)

  useEffect(() => {
    counter > (includeZero ? 0 : 1) && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter, includeZero])

  return <>{counter}</>
}

export default Countdown
