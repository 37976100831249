export const writeItDown = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1305_445)">
      <path
        d="M18.2401 31H2.42673C2.23825 31 2.05163 30.9627 1.87767 30.8901C1.7037 30.8176 1.54583 30.7113 1.41318 30.5775C1.28053 30.4436 1.17572 30.2847 1.10481 30.11C1.0339 29.9355 0.998301 29.7485 1.00006 29.56V2.44C0.998301 2.25153 1.0339 2.06457 1.10481 1.88995C1.17572 1.71531 1.28053 1.55645 1.41318 1.42256C1.54583 1.28867 1.7037 1.18239 1.87767 1.10986C2.05163 1.03733 2.23825 0.999992 2.42673 1H22.5601C22.7491 1 22.9365 1.03725 23.1111 1.10961C23.2858 1.18198 23.4446 1.28805 23.5783 1.42176C23.7121 1.55548 23.8181 1.71423 23.8905 1.88893C23.9629 2.06364 24.0001 2.25089 24.0001 2.44V9.61333"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6001 8.50684H18.2134"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.24 15.1334C20.5222 14.6736 19.7688 14.2723 18.9866 13.9334C18.5366 13.7122 18.0353 13.6164 17.5354 13.6563C17.0357 13.6962 16.5558 13.8703 16.1466 14.16L13.68 15.92C13.2983 16.1926 12.9761 16.54 12.7332 16.9412C12.4902 17.3424 12.3314 17.7888 12.2666 18.2534L11.8933 20.92"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14185 14.1533C4.8657 14.1533 4.64185 13.9295 4.64185 13.6533C4.64185 13.3772 4.8657 13.1533 5.14185 13.1533"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M5.14185 14.1533C5.41799 14.1533 5.64185 13.9295 5.64185 13.6533C5.64185 13.3772 5.41799 13.1533 5.14185 13.1533"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M5.14185 8.99316C4.8657 8.99316 4.64185 8.7693 4.64185 8.49316C4.64185 8.21702 4.8657 7.99316 5.14185 7.99316"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M5.14185 8.99316C5.41799 8.99316 5.64185 8.7693 5.64185 8.49316C5.64185 8.21702 5.41799 7.99316 5.14185 7.99316"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M30.9999 30.0002L23.8533 18.5869"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.28 21.9997L25.7067 17.453C26.1656 17.1409 26.4868 16.6641 26.6038 16.1216C26.7206 15.579 26.624 15.0122 26.3343 14.5389C26.0444 14.0656 25.5835 13.722 25.0471 13.5793C24.5107 13.4368 23.94 13.5061 23.4534 13.773L10 22.0264L7.45337 26.1064L12.24 25.693L15 23.9997"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 22H17C16.4696 22 15.9608 22.2107 15.5857 22.5857C15.2107 22.9608 15 23.4696 15 24C15 24.5304 15.2107 25.0392 15.5857 25.4143C15.9608 25.7893 16.4696 26 17 26H20.6533L23 31"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1305_445">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const stayAccountable = (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1077_64176)">
      <path
        d="M3.66663 5H29.6666C29.6666 5 31.6666 5 31.6666 7V29C31.6666 29 31.6666 31 29.6666 31H3.66663C3.66663 31 1.66663 31 1.66663 29V7C1.66663 7 1.66663 5 3.66663 5Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M1.66663 13H31.6666"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.66663 8V1"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6666 8V1"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6666 18L15.6666 26L11.6666 22"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1077_64176">
        <rect width="32" height="32" fill="white" transform="translate(0.666626)" />
      </clipPath>
    </defs>
  </svg>
)

export const reachYourgoals = (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0214 15.8119L20.5774 10.2559"
      stroke="#2D2F2F"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5774 10.256L21.3347 5.256L25.6654 1L26.3347 4.49867L29.8334 5.168L25.5774 9.49867L20.5774 10.256Z"
      stroke="#2D2F2F"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4067 12.6841C29.1133 15.3628 28.9457 18.1973 27.9283 20.7741C26.911 23.3509 25.097 25.5353 22.7509 27.0088C20.4049 28.4823 17.6495 29.1677 14.8865 28.9652C12.1235 28.7628 9.49751 27.6829 7.39134 25.8831C5.28518 24.0834 3.80905 21.6579 3.1782 18.9603C2.54736 16.2627 2.79479 13.4341 3.88441 10.887C4.97404 8.33984 6.84888 6.20744 9.23553 4.80069C11.6222 3.39394 14.3958 2.78642 17.152 3.06673"
      stroke="#2D2F2F"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6293 14.316C22.9801 15.7303 22.8808 17.2187 22.3454 18.5739C21.81 19.9291 20.8652 21.0834 19.6425 21.8761C18.4199 22.6687 16.9804 23.0602 15.5247 22.9958C14.069 22.9315 12.6697 22.4146 11.5218 21.5172C10.3738 20.6198 9.53447 19.3865 9.12072 17.9894C8.70696 16.5923 8.73942 15.1009 9.21356 13.7231C9.68771 12.3453 10.5799 11.1497 11.7658 10.3031C12.9517 9.45648 14.3722 9.00094 15.8293 9"
      stroke="#2D2F2F"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.02136 27L8.02136 31"
      stroke="#2D2F2F"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0214 27L24.0214 31"
      stroke="#2D2F2F"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
