import { twMerge } from 'tailwind-merge'

import { CclCourseSessionCourseDetailEnrollmentBadgesPartsFragment } from 'gql'

import { numDaysBetweenDates, withinDays } from 'utils/date'

const ALMOST_FULL_THRESHOLD = 20
const RECENTLY_ADDED_THRESHOLD_NUM_DAYS = 14
const STARTING_SOON_THRESHOLD_NUM_DAYS = 14

interface CourseDetailEnrollmentBadgesProps {
  courseSession: CclCourseSessionCourseDetailEnrollmentBadgesPartsFragment
  isSessionEnrollable: boolean
}

const CourseDetailEnrollmentBadges = ({
  courseSession,
  isSessionEnrollable
}: CourseDetailEnrollmentBadgesProps) => {
  const isCourseAlmostFull = () => {
    if (!courseSession.maxEnrollmentPaid || !courseSession.numEnrolledPaid) return false

    return (
      courseSession.maxEnrollmentPaid - courseSession.numEnrolledPaid <=
        ALMOST_FULL_THRESHOLD && !courseSession.isEnrollmentFull
    )
  }

  const isStartingSoon = (
    courseSession: CclCourseSessionCourseDetailEnrollmentBadgesPartsFragment
  ) => {
    const daysBetween = numDaysBetweenDates(new Date(), new Date(courseSession.startsAt))
    return daysBetween <= STARTING_SOON_THRESHOLD_NUM_DAYS && daysBetween >= 1
  }

  const isRecentlyAdded = (
    courseSession: CclCourseSessionCourseDetailEnrollmentBadgesPartsFragment
  ) => {
    return withinDays(courseSession.createdAt, RECENTLY_ADDED_THRESHOLD_NUM_DAYS)
  }

  if (isCourseAlmostFull() && isSessionEnrollable) {
    return <CourseAvailabilityBadge title="Almost full" className="bg-rb-orange" />
  }

  if (courseSession.isEnrollmentFull && isSessionEnrollable) {
    return <CourseAvailabilityBadge title="Course full" className="bg-rb-orange" />
  }

  if (isStartingSoon(courseSession) && isSessionEnrollable) {
    return <CourseAvailabilityBadge title="Starts soon" className="bg-rb-green-75" />
  }

  if (isRecentlyAdded(courseSession) && isSessionEnrollable) {
    return <CourseAvailabilityBadge title="New" className="bg-[#FFA2CF]" />
  }

  return <></>
}

export const CourseAvailabilityBadge = ({
  className,
  title
}: {
  className: string
  title: string
}) => {
  return (
    <div
      className={twMerge(
        'full flex w-fit items-center gap-1 rounded-full bg-rb-orange py-0.5 px-2 text-center text-xs text-rb-gray-400',
        className
      )}
    >
      {title}
    </div>
  )
}

export default CourseDetailEnrollmentBadges
