import Lottie from 'lottie-react'

import animationData from 'components/animations/loading-animation.json'

export const LoadingAnimation = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-white border border-gray-300 rounded m-4 p-4 max-w-md mx-auto">
      <Lottie
        animationData={animationData}
        loop={true}
        className="chromatic-ignore w-1/6"
      />
      <span className="text-2xl text-black mt-2">Creating your goal</span>
      <span className="text-rb-gray-300 text-center">
        Setting up your plan based on your motivations & experience level
      </span>
    </div>
  )
}
