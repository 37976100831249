export const SparkleIcon = ({
  className,
  pathClassName = 'fill-rb-teal-600'
}: {
  className?: string
  pathClassName?: string
}) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.95256 4.00098C6.12323 4.00098 6.27237 4.11621 6.31543 4.28136C6.69546 5.73874 7.12953 6.70518 7.82019 7.43895C8.5121 8.17406 9.49732 8.71362 11.0589 9.24013C11.2114 9.29156 11.3141 9.43458 11.3141 9.59554C11.314 9.7565 11.2113 9.89949 11.0588 9.95086C9.49702 10.4769 8.51184 11.0164 7.82002 11.7515C7.12941 12.4853 6.69546 13.4517 6.31542 14.9086C6.27235 15.0738 6.12322 15.189 5.95257 15.189C5.78192 15.189 5.63279 15.0738 5.58971 14.9086C5.20969 13.452 4.77562 12.4857 4.08494 11.7519C3.39302 11.0168 2.4078 10.4771 0.846302 9.95084C0.693764 9.89943 0.591049 9.75641 0.591064 9.59544C0.59108 9.43447 0.693822 9.29147 0.84637 9.24009C2.40811 8.71409 3.39327 8.17454 4.08509 7.43936C4.7757 6.70547 5.20966 5.73879 5.5897 4.28136C5.63276 4.11621 5.7819 4.00098 5.95256 4.00098ZM2.05015 9.59557C3.15808 10.0459 3.9892 10.5559 4.63107 11.2378C5.21461 11.8578 5.6235 12.6005 5.95252 13.5223C6.2815 12.6003 6.69033 11.8575 7.27386 11.2375C7.91575 10.5555 8.74694 10.0455 9.85512 9.59532C8.74713 9.14493 7.91597 8.63497 7.27406 7.953C6.6905 7.33301 6.28161 6.59022 5.95259 5.66811C5.62361 6.5903 5.2148 7.33326 4.63129 7.95334C3.98942 8.63543 3.15826 9.14541 2.05015 9.59557Z"
      className={pathClassName}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0582 1.34698C12.2287 1.34702 12.3777 1.46206 12.4209 1.62699C12.6326 2.43552 12.8684 2.94711 13.2302 3.32984C13.5933 3.71404 14.1192 4.00638 14.9905 4.299C15.1432 4.35029 15.2461 4.49342 15.2461 4.65453C15.2461 4.81565 15.1432 4.95874 14.9904 5.01C14.1193 5.30232 13.5933 5.59489 13.2302 5.97926C12.8684 6.36222 12.6326 6.87398 12.4209 7.68203C12.3777 7.84697 12.2286 7.96198 12.0581 7.96198C11.8876 7.96198 11.7386 7.84697 11.6954 7.68203C11.4837 6.87405 11.2477 6.3624 10.8859 5.97949C10.5226 5.59512 9.99663 5.30255 9.1257 5.00996C8.97298 4.95866 8.8701 4.81554 8.87012 4.65444C8.87014 4.49333 8.97306 4.35024 9.12579 4.29898C9.99664 4.00666 10.5225 3.7141 10.8857 3.32971C11.2475 2.94672 11.4834 2.43491 11.6954 1.62683C11.7386 1.46192 11.8877 1.34695 12.0582 1.34698ZM10.2664 4.65457C10.7392 4.87806 11.1206 5.13591 11.431 5.46436C11.6926 5.74118 11.8935 6.05727 12.0581 6.42255C12.2226 6.05723 12.4235 5.74107 12.685 5.46421C12.9954 5.13572 13.3767 4.87786 13.8495 4.65443C13.3768 4.43106 12.9954 4.17336 12.6851 3.84501C12.4234 3.56813 12.2225 3.25191 12.0579 2.8864C11.8933 3.25173 11.6924 3.56789 11.4308 3.84476C11.1205 4.17326 10.7392 4.43113 10.2664 4.65457Z"
      className={pathClassName}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0211 10.805C13.1909 10.8051 13.3395 10.9192 13.3833 11.0833C13.5376 11.6613 13.7061 12.0121 13.9562 12.2712C14.2084 12.5326 14.5801 12.7385 15.2196 12.9488C15.3735 12.9994 15.4775 13.1431 15.4775 13.3051C15.4774 13.4671 15.3734 13.6107 15.2195 13.6613C14.5801 13.8713 14.2084 14.0772 13.9562 14.3386C13.7061 14.5978 13.5376 14.9486 13.3833 15.5267C13.3395 15.6908 13.1909 15.8049 13.0211 15.805C12.8513 15.8051 12.7027 15.691 12.6587 15.527C12.5039 14.9489 12.3352 14.5981 12.0849 14.3388C11.8326 14.0774 11.4608 13.8715 10.8213 13.6612C10.6675 13.6106 10.5634 13.4669 10.5635 13.3049C10.5635 13.1429 10.6676 12.9993 10.8215 12.9487C11.4608 12.7387 11.8326 12.5328 12.0849 12.2714C12.3352 12.0121 12.5039 11.6611 12.6587 11.083C12.7027 10.919 12.8513 10.8049 13.0211 10.805ZM11.925 13.3051C12.1976 13.4492 12.4286 13.615 12.6245 13.8179C12.7828 13.9819 12.9118 14.1634 13.0206 14.365C13.1293 14.1634 13.2582 13.9818 13.4165 13.8178C13.6123 13.6149 13.8434 13.4489 14.1159 13.3049C13.8434 13.1608 13.6123 12.9949 13.4165 12.792C13.2583 12.6281 13.1294 12.4465 13.0207 12.245C12.9119 12.4466 12.7829 12.6282 12.6245 12.7923C12.4287 12.9952 12.1976 13.1611 11.925 13.3051Z"
      className={pathClassName}
    />
  </svg>
)
