import React from 'react'
import { Link } from 'react-router-dom'

import { MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import { Loading } from 'components'
import ArtifactCard from 'components/cards/Content/ArtifactCard'
import CourseCard from 'components/cards/Content/CourseCard'

import {
  CclArtifact,
  CclCourse,
  PopularCoursesByTopic,
  usePopularContentQuery
} from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

export interface PopularContentProps {
  topic: Topic
  trackingLocation: string
}

export type Topic = {
  key: keyof PopularCoursesByTopic
  label: string
  slug: string
}

const PopularContent = ({ topic, trackingLocation }: PopularContentProps) => {
  const { data, loading } = usePopularContentQuery()

  return (
    <div className="2xl:px-[4vw]">
      <div className="mx-auto 2xl:max-w-[1360px] bg-rb-gray-50 py-12 2xl:rounded-3xl flex flex-col items-center">
        <SubSectionTitle
          title="Go deep with expert-led courses"
          subtitle="Get unlimited access to our 40+ courses, live or on-demand."
        />

        {loading ? (
          <Loading className="py-24" />
        ) : (
          <PopularCourses
            courses={data?.popularContent.courses[topic.key] as CclCourse[]}
            topic={topic}
            trackingLocation={trackingLocation}
          />
        )}

        <SubSectionTitle
          title="Never start from scratch"
          subtitle="Access 1,000+ real work artifacts to solve daily challenges."
        />

        {loading ? (
          <Loading className="py-24" />
        ) : (
          <PopularArtifacts
            artifacts={data?.popularContent.artifacts[topic.key] as CclArtifact[]}
            topic={topic}
            trackingLocation={trackingLocation}
          />
        )}
      </div>
    </div>
  )
}

const SubSectionTitle = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <div className="px-4">
      <h3 className="mb-1 text-2xl lg:text-4xl !leading-[1] tracking-[-0.48px] lg:tracking-[-0.72px] text-center">
        {title}
      </h3>
      <MarketingH3 as="p" className="mb-12">
        {subtitle}
      </MarketingH3>
    </div>
  )
}

const PopularCourses = ({
  courses,
  topic,
  trackingLocation
}: {
  courses: CclCourse[]
  topic: Topic
  trackingLocation: string
}) => {
  return (
    <>
      <div className="overflow-x-auto max-w-full">
        <div className="flex flex-col md:flex-row items-center gap-8 mb-6 w-min px-4 md:px-12">
          {courses.map((course) => (
            <CourseCard
              key={course.id}
              course={course}
              styleVariant={course.live ? 'live-home' : 'on-demand-home'}
              hideBookmarkButton
              showSessionHostInThumbnail
              courseSession={course.upcomingSessions?.[0] || null}
              showPremiumIconOverwrite={false}
              baseCardClassName="w-[340px] xs:w-[360px] sm:w-[400px]"
            />
          ))}
        </div>
      </div>
      <Link
        to={`/courses?tab=live&topics=${topic.slug}`}
        className="text-rb-black text-base xs:text-lg leading-[1.35] underline text-center mb-24"
        onClick={(e) => onViewAllLinkClicked(e, trackingLocation)}
      >
        View all {topic.label} courses {'->'}
      </Link>
    </>
  )
}

const PopularArtifacts = ({
  artifacts,
  topic,
  trackingLocation
}: {
  artifacts: CclArtifact[]
  topic: Topic
  trackingLocation: string
}) => {
  return (
    <>
      <div className="overflow-x-auto max-w-full">
        <div className="flex flex-col md:flex-row items-center gap-8 mb-6 w-min px-4 md:px-12">
          {artifacts.map((artifact) => (
            <ArtifactCard
              key={artifact.id}
              artifact={artifact}
              hideBookmarkButton
              showPremiumIconOverwrite={false}
              baseCardClassName="w-[340px] xs:w-[360px] sm:w-[400px]"
              pageLocation={trackingLocation}
            />
          ))}
        </div>
      </div>
      <Link
        to={`/artifacts?topics=${topic.slug}`}
        className="text-rb-black text-base xs:text-lg leading-[1.35] underline text-center"
        onClick={(e) => onViewAllLinkClicked(e, trackingLocation)}
      >
        View all {topic.label} artifacts {'->'}
      </Link>
    </>
  )
}

const onViewAllLinkClicked = (
  e: React.MouseEvent<HTMLAnchorElement>,
  trackingLocation: string
) => {
  trackNavigationClicked({
    destination: e.currentTarget.getAttribute('href') as string,
    text: e.currentTarget.textContent as string,
    location: trackingLocation,
    type: 'hyperlink text'
  })
}

export default PopularContent
