import { useNavigate } from 'react-router-dom-v5-compat'

import Button from 'components/Button'
import Modal from 'components/Modal'

import { cn } from 'utils/tailwind'

export interface JoinRequestSentModalProps {
  isModalOpen: boolean
  isSignUpFlow?: boolean
  onClose: () => void
  destination: string
}

export function JoinRequestSentModal({
  isModalOpen,
  isSignUpFlow,
  onClose,
  destination
}: JoinRequestSentModalProps) {
  const navigate = useNavigate()

  const handleClose = () => {
    onClose()
    navigate(destination)
  }

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={handleClose}
      scrollContent={false}
      className="max-w-3xl"
    >
      <div
        data-test="join-request-sent-modal"
        className={cn(
          'flex flex-col justify-center items-center max-w-[480px] mx-auto mt-6',
          isSignUpFlow ? 'mb-20' : 'mb-12'
        )}
      >
        <div className="mb-6 flex text-center font-medium text-[28px] leading-[1.2]">
          We’ve sent your request!
        </div>
        <div className="text-center text-base pb-8 leading-[1.6]">
          We will notify you when your request to join has been reviewed.{' '}
          {isSignUpFlow ? 'Explore Reforge while you wait.' : ''}
        </div>
        {isSignUpFlow ? (
          <Button
            size="medium"
            variant="outline"
            className="w-[175px]"
            href={destination}
            onClick={onClose}
          >
            Start exploring
          </Button>
        ) : (
          <Button size="medium" variant="outline" className="w-[175px]" onClick={onClose}>
            Close
          </Button>
        )}
      </div>
    </Modal>
  )
}
