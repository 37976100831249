import React, { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

// Consolidated imports
import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'

import { Loading } from 'components'
import { ReactComponent as IconChevronLeft } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import { ReactComponent as IconChevronRight } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-right.svg'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  useBookmarkFoldersQuery,
  useExploreSubtopicPageQuery,
  useSavedArtifactsAndGuidesForUserQuery,
  useSavedCoursesForUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import ExploreSwimlane from '../components/ExploreSwimlane'
import MoreTopicsToExplore from '../components/MoreTopicsToExplore'
import { TOPICS } from '../consts'

const PAGE_LOCATION = 'subtopics index'

export function BreadcrumbLink({
  to,
  onClick = () => {},
  children
}: {
  to: string
  onClick?: () => void
  children: React.ReactNode
}) {
  return (
    <Link to={to} className="text-black hover:text-black underline" onClick={onClick}>
      {children}
    </Link>
  )
}

function Breadcrumbs({
  topic,
  subtopic,
  isLoggedIn
}: {
  topic: { title?: string | undefined; slug?: string | undefined; onClick: () => void }
  subtopic: { title: string; slug: string } | undefined
  isLoggedIn: boolean
}) {
  const isBelowXs = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS}`)

  if (isBelowXs) {
    return (
      <div className="flex gap-2 items-center">
        <IconChevronLeft fill={'#080A0A'} width={14.54} height={16} />
        <BreadcrumbLink to={`/explore/${topic?.slug}`} onClick={topic?.onClick}>
          Back to {topic?.title}
        </BreadcrumbLink>
      </div>
    )
  }

  return (
    <div className="flex gap-2 items-center">
      {isLoggedIn && (
        <>
          <BreadcrumbLink
            to="/explore"
            onClick={() => {
              trackNavigationClicked({
                type: 'hyperlink text',
                text: 'back to explore',
                location: location.pathname,
                destination: '/explore'
              })
            }}
          >
            Explore
          </BreadcrumbLink>
          <IconChevronRight fill={'#080A0A'} width={14.54} height={16} />
        </>
      )}
      <BreadcrumbLink to={`/explore/${topic?.slug}`} onClick={topic?.onClick}>
        {topic?.title}
      </BreadcrumbLink>
      <IconChevronRight fill={'#080A0A'} width={14.54} height={16} />
      {subtopic?.title}
    </div>
  )
}

interface ExploreSubtopicPageProps {
  setPage?: (page: GenericPageType) => void
}

export default function ExploreSubtopicPage({ setPage }: ExploreSubtopicPageProps) {
  const location = useLocation()
  const { currentUser, isLoggedIn } = useCurrentUser()
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const { topic, subtopic } = useParams<{ topic: string; subtopic: string }>()

  const { data, loading } = useExploreSubtopicPageQuery({
    variables: {
      subtopicSlug: subtopic,
      slugs: [topic, subtopic]
    }
  })

  const { artifacts, courses, guides } = data?.exploreSubtopicContent || {}

  const numRows = [artifacts?.length, courses?.length, guides?.length].filter(
    Boolean
  ).length

  const { data: savedData } = useSavedArtifactsAndGuidesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    },
    skip: !isLoggedIn
  })

  const { data: savedCourseData } = useSavedCoursesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    },
    skip: !isLoggedIn
  })

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()

  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const topicData = data?.exploreTopicsForSlugs?.find((t) => t.slug === topic)
  const subtopicData = data?.exploreTopicsForSlugs?.find((t) => t.slug === subtopic)

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })

  useEffect(() => {
    const title = `${subtopicData?.title} Courses, Guides, and Templates - Reforge`
    const desc = `Learn ${subtopicData?.title} from the leading experts in product, growth, and marketing. Gain the skills and knowledge you need to become a ${topicData?.title} expert.`
    const topicConstantData = TOPICS.find((t: any) => t.slug === topicData?.slug)
    const topicImage = topicConstantData?.icon

    setPage?.({
      title,
      seo: {
        metaTitle: title,
        shareTitle: title,
        metaDesc: desc,
        shareDesc: desc,
        shareGraphic: {
          asset: topicImage?.props.src
        },
        shareGraphicAlt: `${topicData?.title} Courses, Guides, and Templates at Reforge`
      }
    } as GenericPageType)
  }, [setPage, subtopicData, topicData])

  if (loading) {
    return <Loading />
  }

  const artifactsSwimlaneTitle = 'See real examples & templates'
  const guidesSwimlaneTitle = isMobileView
    ? 'Follow step-by-step guides'
    : 'Follow our helpful step-by-step guides'
  const coursesSwimlaneTitle = 'Dive into courses on this topic'

  const swimlanes = [
    { contentType: 'artifact', title: artifactsSwimlaneTitle, data: artifacts },
    { contentType: 'guide', title: guidesSwimlaneTitle, data: guides },
    { contentType: 'course', title: coursesSwimlaneTitle, data: courses }
  ].filter(({ data }) => data?.length)

  return (
    <>
      <div>
        <div className="mb-8">
          <Breadcrumbs
            topic={{
              ...topicData,
              onClick: () => {
                trackNavigationClicked({
                  type: 'hyperlink text',
                  text: `back to ${topicData?.title}`.toLowerCase(),
                  location: location.pathname,
                  destination: `/explore/${topicData?.slug}`
                })
              }
            }}
            subtopic={subtopicData}
            isLoggedIn={isLoggedIn}
          />
        </div>

        <h1 className="font-polysans font-normal text-3xl md:text-5xl mb-6">
          {subtopicData?.title}
        </h1>

        <div className="flex flex-col gap-12">
          {swimlanes.map(({ title, contentType, data }, idx) => {
            if (!data) return null

            return (
              <ExploreSwimlane
                key={`${contentType}-${idx}`}
                idx={idx}
                loading={loading}
                topicId={topicData?.id}
                subtopicId={subtopicData?.id}
                subtopicTitle={subtopicData?.title}
                title={title}
                items={data}
                savedData={savedData}
                savedCourseData={savedCourseData}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                tracking={{
                  location: PAGE_LOCATION,
                  locationId: subtopicData?.id,
                  contentType,
                  section: title,
                  sectionIndex: idx,
                  relatedIdentifiers: {
                    locationName: subtopicData?.title
                  }
                }}
              />
            )
          })}
        </div>
        <MoreTopicsToExplore
          tracking={{
            locationType: PAGE_LOCATION,
            locationId: subtopicData?.id,
            sectionIndex: numRows
          }}
        />
      </div>

      {!currentUser?.is?.freeUser && (
        <>
          <AddBookmarkToFolderModal
            isOpen={isAddToBookmarkFolderModalOpen}
            handleClose={closeAddToBookmarkFolderModal}
            bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
            openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdown}
            showCollectionsOnboardingInfo={
              !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
            }
          />
          <CreateBookmarkFolderModal
            isModalOpen={isCreateBookmarkFolderModalOpen}
            handleClose={closeCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
            trackingTriggerAction={'bookmark'}
          />
        </>
      )}
    </>
  )
}
