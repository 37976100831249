import CourseDetailRelatedCourse from 'domains/CourseDetail/CourseDetailRelatedCourse'

import CarouselSection from 'components/pagination/CarouselSection/CarouselSection'

import { CourseDetailPartsFragment, RelatedCoursePartsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

interface CourseDetailRelatedCoursesProps {
  relatedCourses: RelatedCoursePartsFragment[]
  courseDetails: CourseDetailPartsFragment
  loggedOut?: boolean
}

const CourseDetailRelatedCourses = ({
  relatedCourses,
  courseDetails,
  loggedOut
}: CourseDetailRelatedCoursesProps) => {
  const isTablet = useMediaQuery('(min-width: 960px)')
  const isMedium = useMediaQuery('(min-width: 768px)')
  const isSmall = useMediaQuery('(min-width: 640px)')
  const visibleSlides = isTablet ? 2 : isMedium ? 1.9 : isSmall ? 1.6 : 1

  return (
    <div className="space-y-4">
      <CarouselSection
        slideWidth={290}
        visibleSlides={visibleSlides}
        title={
          <span className="rf-h3 !mb-0 font-polysans !text-[24px] !font-light">
            Related courses
          </span>
        }
      >
        {relatedCourses.map((relatedCourse) => (
          <CourseDetailRelatedCourse
            courseDetails={courseDetails}
            key={relatedCourse?.id}
            relatedCourse={relatedCourse}
            loggedOut={loggedOut}
          />
        ))}
      </CarouselSection>
    </div>
  )
}

export default CourseDetailRelatedCourses
