import Button from 'components/Button'
import { ProgressRing } from 'components/ProgressRing/ProgressRing'

import { useChecklistTasksQuery } from 'gql'

export const OnboardingChecklistIndicator = () => {
  const { data } = useChecklistTasksQuery({
    variables: {
      checklistName: 'onboarding'
    }
  })

  const handleClick = () => {
    console.log('clicked')
  }

  if (!data || data.checklistTasks.length < 1) {
    return null
  }

  const percentCompleted =
    (data.checklistTasks.filter((task) => task.completed).length /
      data.checklistTasks.length) *
    100

  return (
    <Button
      className="flex flex-row items-center group h-10 px-4 gap-2 border-0 bg-rb-gray-50 text-rb-gray-400 hover:bg-rb-gray-300 active:bg-rb-gray-400 text-sm"
      onClick={handleClick}
    >
      <div className="inline-block">Get Started</div>
      <ProgressRing
        size={32}
        className="text-rb-gray-300 group-hover:text-white transition duration-200 ease-in-out"
        fontSize={10}
        trackWidth={3}
        progressPercent={percentCompleted}
        strokeClassName="stroke-rb-info"
        completedClassName="bg-rb-blue-100"
      />
    </Button>
  )
}
