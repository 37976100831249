import React, { ReactNode, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useExploreTopicsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { CONTENT_TYPES, NUM_LIST_SUBTOPICS, TOPICS } from '../consts'

function ContentTypeCard({
  title,
  subtitle,
  icon,
  destination,
  listIndex
}: {
  title: string
  subtitle: string
  icon: React.ReactNode
  destination: string
  listIndex: number
}) {
  const location = useLocation()
  const { isLoggedIn } = useCurrentUser()
  return (
    <Link
      className="block border border-rb-gray-100 hover:border-rb-gray-200 p-8 rounded shadow hover:no-underline text-black hover:text-black"
      to={destination}
      onClick={() => {
        trackNavigationClicked({
          type: 'content_type_card',
          text: title.toLowerCase(),
          location: location.pathname,
          location_type: 'index',
          logged_in: isLoggedIn,
          destination: destination,
          section_index: 0,
          section_impression_index: listIndex
        })
      }}
    >
      <div className="mb-3">{icon}</div>
      <h2 className="text-xl font-semibold">{title}</h2>
      <div className="text-rb-gray-300">{subtitle}</div>
    </Link>
  )
}

function TopicCard({
  title,
  icon,
  slug,
  listIndex,
  id,
  subtopics
}: {
  title: string
  icon: React.ReactNode
  slug: string
  listIndex: number
  id: string
  subtopics: { title: string; slug: string; id: string }[]
}) {
  const location = useLocation()
  const { isLoggedIn } = useCurrentUser()

  const destination = `/explore/${slug}`

  // We're about to do a whole lot of finessing to get the top 3 subtopics formatted
  // with a correct grammatical presentation, with dot-separated elements, and with
  // the text of each element constrained to not break across lines.
  const numberMoreSubtopics = subtopics.length - NUM_LIST_SUBTOPICS
  const subtopicList = subtopics.slice(0, 3).map((subtopic) => subtopic.title)
  if (numberMoreSubtopics > 0) {
    const moreSubtopics =
      numberMoreSubtopics === 1
        ? 'and 1 more topic'
        : `and ${numberMoreSubtopics} more topics`
    subtopicList.push(moreSubtopics)
  }

  const subtopicElements: ReactNode[] = []
  subtopicList.map((subtopicText, idx) => {
    if (idx !== subtopicList.length - 1) {
      subtopicElements.push(
        <span className="inline-block pr-[0.25em]">{subtopicText} ·</span>
      )
    } else {
      subtopicElements.push(<span className="inline-block">{subtopicText}</span>)
    }
  })

  return (
    <Link
      className={cn(
        'flex justify-between flex-col md:flex-row items-center border border-rb-gray-100 hover:border-rb-gray-200 p-4 md:pl-8 rounded shadow hover:no-underline text-black hover:text-black'
      )}
      to={destination}
      onClick={() => {
        trackNavigationClicked({
          type: 'topic_card',
          text: title.toLowerCase(),
          location: location.pathname,
          location_type: 'topic index',
          logged_in: isLoggedIn,
          destination,
          related_identifiers: {
            destination_ccl_filter_tag_id: id,
            destination_kind: 'topic'
          },
          section_index: 1,
          section_impression_index: listIndex
        })
      }}
    >
      <div className="md:hidden p-7">{icon}</div>
      <div className="max-w-[320px] flex flex-col gap-4">
        <div className="text-sm md:text-xl font-semibold text-center md:text-left">
          {title}
        </div>
        <div className="hidden md:block text-rb-gray-300">{subtopicElements}</div>
      </div>
      <div className="hidden md:block p-7">{icon}</div>
    </Link>
  )
}

function SkeletonTopicCard() {
  return (
    <div className="chromatic-ignore mb-8 flex w-full max-w-[600px] animate-pulse flex-col justify-center rounded border border-rb-gray-100 bg-rb-white md:max-w-none md:flex-row md:gap-6 md:p-4">
      <div className="flex w-full p-4 md:py-2 md:px-0 gap-4 md:flex-row flex-col-reverse">
        <div className="flex flex-col w-full justify-center">
          <div className="mb-4 h-[18px] w-full rounded-md bg-rb-gray-100" />
          <div className="hidden md:block mb-2 h-3 w-full rounded-md bg-rb-gray-100" />
          <div className="hidden md:block mb-2 h-3 w-full rounded-md bg-rb-gray-100 md:w-3/4" />
        </div>
        <div className="flex items-center justify-center">
          <div className="aspect-[9/16] shrink-0 rounded-xl bg-rb-gray-100 h-[100px] w-[80px] md:h-[120px] md:w-[100px]" />
        </div>
      </div>
    </div>
  )
}

function LoadingTopics() {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 gap-4">
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
    </div>
  )
}

export default function ExplorePage() {
  useEffect(() => {
    document.getElementById('page')?.scrollTo(0, 0)
  }, [])

  const { data: exploreTopicsData, loading } = useExploreTopicsQuery()

  return (
    <div className="flex flex-col">
      <h2 className="text-xl sm:text-3xl mb-6">Find content by type</h2>
      <div className="flex gap-6 flex-col md:flex-row">
        {CONTENT_TYPES.map((contentType, idx) => (
          <ContentTypeCard
            key={`content-type-${contentType.title}`}
            title={contentType.title}
            subtitle={contentType.subtitle}
            icon={contentType.icon}
            destination={contentType.destination}
            listIndex={idx}
          />
        ))}
      </div>
      <h2 className="text-xl sm:text-3xl mb-6 mt-12">Browse top content by topic</h2>
      {loading ? (
        <LoadingTopics />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 gap-4">
          {exploreTopicsData?.exploreTopics.map((exploreTopic, idx) => {
            const topic = TOPICS.find((topic) => {
              return exploreTopic.slug === topic.slug
            })

            if (!topic) {
              return null
            }

            return (
              <TopicCard
                key={`topic-${exploreTopic.title}`}
                title={exploreTopic.title}
                subtopics={exploreTopic.subtopics}
                icon={topic.icon}
                slug={exploreTopic.slug}
                id={exploreTopic.id}
                listIndex={idx}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
