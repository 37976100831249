import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import FocusAreas from 'domains/Onboarding/FreeUserOnboarding/FocusAreas'
import { OnboardingModalContainer } from 'domains/Onboarding/OnboardingModalContainer'

import { BillingAddressCollectionModal } from 'components/BillingAddressCollectionModal'
import { useGlobalModal } from 'components/GlobalModal'
import { PageHeaderH1 } from 'components/PageHeader/PageHeader'
import { usePage } from 'components/PageHeader/usePage'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'
import AnnouncementWrapper from 'components/dopt/AnnouncementWrapper'
import { SkeletonContentCardContainer } from 'components/skeletons/cards'

import {
  Collection,
  HomepageRecentlyViewedItem,
  useAiPersonalizationQuery,
  useBookmarkFoldersQuery,
  useDedupedTopicsAndFunctionsQuery,
  useHomepageRecentlyViewedContentQuery,
  useHomepageUpcomingEventsQuery,
  usePublicEventsQuery,
  useRecommendedCollectionsQuery,
  useSavedArtifactsAndGuidesForUserQuery,
  useUserTeamQuery
} from 'gql'

import useContainerQuery from 'hooks/useContainerQuery'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { getTimeOfDay } from 'utils/date'
import { cn } from 'utils/tailwind'

import { ReactComponent as DotIcon } from 'images/dot.svg'

import TeamSection from '../TeamHome/TeamSection'
import CoursesInProgress from './CoursesInProgress'
import { Swimlanes } from './Feed'
import { PersonalizeModal } from './PersonalizeModal'
import RecentlyViewedContent from './RecentlyViewedContent'
import UpcomingEvents from './UpcomingEvents'

const LoggedInHomepage = () => {
  const { currentUser } = useCurrentUser()
  const { showDiscoverTeams } = useFeatureFlags() // The showDiscoverTeams flag has been repurposed to hide the discover teams page from the onboarding flow. Do not remove.
  const { is: userIs } = currentUser || {}
  const { setPageTitle } = usePage()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isFocusUpdate, setIsFocusUpdate] = useState(false)
  const { networkOnboarded, networkFocus } = currentUser || {}
  const [isFocusAreaVisible, setIsFocusAreaVisible] = useState(!networkOnboarded || false)
  const history = useHistory()
  const TRACKING_LOCATION = userIs?.freeUser ? 'free_user_home' : 'premium_user_home'
  const location = useLocation()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const hasFocusAreas = networkFocus && networkFocus?.length > 0

  const { data: recentlyViewedData, loading: recentlyViewedDataLoading } =
    useHomepageRecentlyViewedContentQuery({
      fetchPolicy: 'cache-and-network'
    })

  const { data: recommendedCollections, loading: recommendedCollectionsLoading } =
    useRecommendedCollectionsQuery()

  const { data: userTeamData } = useUserTeamQuery({
    fetchPolicy: 'cache-and-network',
    skip: !currentUser?.hasTeam
  })
  const userTeam = userTeamData?.currentUser?.currentTeam
  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })
  const { data: personalizationData, loading: personalizationLoading } =
    useAiPersonalizationQuery({
      skip: !currentUser
    })
  const { data: dedupedTopicsAndFunctions, loading: dedupedTopicsAndFunctionsLoading } =
    useDedupedTopicsAndFunctionsQuery({
      skip: !currentUser
    })

  const { data: savedData } = useSavedArtifactsAndGuidesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    }
  })

  const { data: upcomingEvents } = useHomepageUpcomingEventsQuery({
    fetchPolicy: 'cache-and-network'
  })
  const homepageUpcomingEvents = upcomingEvents?.homepageUpcomingEvents || []

  const { data: publicEventsData } = usePublicEventsQuery({
    variables: {
      limit: 3
    },
    skip: !currentUser?.is.freeUser,
    fetchPolicy: 'cache-and-network'
  })
  const publicEvents = publicEventsData?.publicEvents || []

  const recentlyViewedContent = (recentlyViewedData?.homepageRecentlyViewedContent ||
    []) as HomepageRecentlyViewedItem[]

  const shouldRenderFocusAreas = !networkOnboarded || isFocusAreaVisible
  const isLoading =
    (dedupedTopicsAndFunctionsLoading && isInitialLoad) ||
    (recommendedCollectionsLoading && isInitialLoad) ||
    isRefreshing
  // Remove padding from page content
  useEffect(() => {
    const pageContent = document.getElementById('page-content')

    if (pageContent) {
      pageContent.style.paddingBottom = '0'
    }

    return () => {
      if (pageContent) {
        pageContent.style.paddingBottom = ''
      }
    }
  }, [isLoading, isFocusAreaVisible])

  useEffect(() => {
    if (recommendedCollections?.recommendedCollections && isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [recommendedCollections, isInitialLoad])

  const pendingSubscriptionJoinRequest = currentUser?.subscriptionJoinRequests?.find(
    (request) => request.status === 'pending'
  )

  useEffect(() => {
    const title = currentUser?.firstName
      ? `Hi there, ${currentUser.firstName}`
      : `Good ${getTimeOfDay()}`

    const showTeam = currentUser?.hasTeam && userTeam

    const pageTitle = (
      <div className="flex gap-x-12 w-full">
        <div className="w-full flex flex-col md:flex-row justify-between">
          <PageHeaderH1 className="whitespace-nowrap flex items-center">
            {title}
          </PageHeaderH1>

          <div className="flex items-center justify-between lg:ml-4 mt-2 lg:mt-0">
            {pendingSubscriptionJoinRequest?.subscription?.name && (
              <div className="flex flex-col">
                <p className="font-semibold text-base mb-0">
                  {pendingSubscriptionJoinRequest.subscription.name}
                </p>
                <p className="flex gap-x-2 mb-0 text-sm leading-[25.6px]">
                  <DotIcon className="text-rb-yellow-200 w-2" />
                  Request pending
                </p>
              </div>
            )}
            {showTeam && !pendingSubscriptionJoinRequest && (
              <TeamSection
                isSubOwner={currentUser?.id === userTeam?.userId}
                isAssistantAdmin={currentUser?.is?.assistantAdmin || false}
                teamName={userTeam?.teamName}
                teamMembers={userTeam?.teamMembers.users || []}
              />
            )}
          </div>
        </div>
      </div>
    )

    setPageTitle(pageTitle)

    return () => {
      setPageTitle(null)
    }
  }, [currentUser, setPageTitle, userTeam, pendingSubscriptionJoinRequest])

  const refreshFeed = useCallback(async () => {
    setIsRefreshing(true)
    setIsRefreshing(false)
  }, [])

  const handlePostFocusAreaNavigation = () => {
    const searchParams = new URLSearchParams(location.search)
    const redirectParam = searchParams.get('redirect')

    const shouldShowFreeTrialScreen =
      userIs?.eligibleForTrial &&
      !isFocusUpdate &&
      !redirectParam?.startsWith('/subscribe') &&
      !redirectParam?.startsWith('/events') &&
      !redirectParam?.startsWith('/course-payment') &&
      !redirectParam?.startsWith('/extension-authenticated')

    const canJoinTeam = showDiscoverTeams && currentUser?.can.joinTeam
    const referer = redirectParam ? `?referer=${encodeURIComponent(redirectParam)}` : ''

    if (canJoinTeam) {
      history.push(`/discover-teams${referer}`)
      return
    }

    if (!canJoinTeam && shouldShowFreeTrialScreen) {
      history.push(`/try-reforge${referer}`)
      return
    }

    if (redirectParam) {
      history.push(redirectParam)
    }
  }

  const handleFocusAreasSave = () => {
    handlePostFocusAreaNavigation()
    setIsFocusAreaVisible(false)
    refreshFeed()
  }

  const handleFocusAreasSkip = () => {
    handlePostFocusAreaNavigation()
    setIsFocusAreaVisible(false)
  }

  const { openGlobalModal } = useGlobalModal()

  const openPersonalizeModal = useCallback(() => {
    openGlobalModal(
      <PersonalizeModal
        dedupedTopicsAndFunctions={
          dedupedTopicsAndFunctions?.dedupedTopicsAndFunctions || []
        }
        personalizationData={personalizationData?.aiPersonalization}
        loading={dedupedTopicsAndFunctionsLoading || personalizationLoading}
      />,
      {
        className: 'overflow-y-scroll pb-0 rounded-xl',
        scrollContent: false,
        fixedHeight: true
      }
    )
  }, [
    openGlobalModal,
    dedupedTopicsAndFunctions,
    dedupedTopicsAndFunctionsLoading,
    personalizationData,
    personalizationLoading
  ])

  const { isSidePanelChatOpenDelayed } = useGlobalChat()
  const [{ isMobileView, threeSlides, fourSlides }, loadingContainerRef] =
    useContainerQuery({
      isMobileView: {
        maxWidth: 770 // px
      },
      threeSlides: {
        minWidth: 848 // px
      },
      fourSlides: {
        minWidth: 1181 // px
      }
    })
  const visibleCardsPerRow = useMemo(() => {
    if (isMobileView || isSidePanelChatOpenDelayed) return 4 // render a full sliders worth of skeletons even though they are not all visible
    if (!threeSlides) return 2
    if (!fourSlides) return 3

    return 4
  }, [isSidePanelChatOpenDelayed, isMobileView, threeSlides, fourSlides])

  if (shouldRenderFocusAreas) {
    return (
      <div className="fixed top-0 left-0 h-dvh w-full z-[2000] flex bg-rb-white overflow-y-auto">
        <FocusAreas
          updateFocus={isFocusUpdate}
          onFocusAreasSkip={handleFocusAreasSkip}
          onFocusAreasSave={handleFocusAreasSave}
          dedupedTopicsAndFunctions={
            dedupedTopicsAndFunctions?.dedupedTopicsAndFunctions || []
          }
          dedupedTopicsAndFunctionsLoading={dedupedTopicsAndFunctionsLoading}
        />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div ref={loadingContainerRef} className="overflow-hidden">
        {Array.from({ length: 4 }).map((_, i) => (
          <SkeletonContentCardContainer
            key={i}
            cardCount={visibleCardsPerRow}
            className={cn('mb-16 !mr-0', {
              '!grid-cols-[repeat(4,minmax(289px,1fr))]':
                visibleCardsPerRow === 4 && !(isMobileView || isSidePanelChatOpenDelayed),
              '!grid-cols-[repeat(3,minmax(289px,1fr))]': visibleCardsPerRow === 3,
              '!grid-cols-[repeat(2,minmax(289px,1fr))]': visibleCardsPerRow === 2,
              '!grid-cols-[repeat(4,289px)]': isMobileView || isSidePanelChatOpenDelayed
            })}
          />
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="mt-4 flex flex-col w-full" ref={containerRef}>
        <AnnouncementWrapper />

        {(homepageUpcomingEvents.length > 0 || publicEvents.length > 0) && (
          <UpcomingEvents events={homepageUpcomingEvents} publicEvents={publicEvents} />
        )}

        {(currentUser?.shouldSeeHomepageCoursesSection ||
          recentlyViewedContent.length > 0) && (
          <div className="flex flex-col gap-12 pb-20">
            {currentUser?.shouldSeeHomepageCoursesSection && <CoursesInProgress />}

            <RecentlyViewedContent
              items={recentlyViewedContent}
              loading={recentlyViewedDataLoading}
              pageLocation={TRACKING_LOCATION}
              savedData={savedData}
              openAddToBookmarkFolderModal={
                userIs?.freeUser ? undefined : openAddToBookmarkFolderModal
              }
            />
          </div>
        )}

        <Swimlanes
          openPersonalizeModal={openPersonalizeModal}
          setIsFocusUpdate={setIsFocusUpdate}
          recommendedCollections={
            recommendedCollections?.recommendedCollections as Collection[]
          }
          hasFocusAreas={!!hasFocusAreas}
          savedData={savedData}
          openAddToBookmarkFolderModal={
            userIs?.freeUser ? undefined : openAddToBookmarkFolderModal
          }
          pageLocation={TRACKING_LOCATION}
        />
      </div>

      {!userIs?.freeUser && (
        <>
          <ShareableSnapshotModal page="Home" />
          <BillingAddressCollectionModal />
          <OnboardingModalContainer isOpen={!currentUser?.onboarded} showLoader={false} />
          <AddBookmarkToFolderModal
            isOpen={isAddToBookmarkFolderModalOpen}
            handleClose={closeAddToBookmarkFolderModal}
            bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
            openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdown}
            showCollectionsOnboardingInfo={
              !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
            }
          />
          <CreateBookmarkFolderModal
            isModalOpen={isCreateBookmarkFolderModalOpen}
            handleClose={closeCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
            trackingTriggerAction={'bookmark'}
          />
        </>
      )}
    </>
  )
}

export default LoggedInHomepage
