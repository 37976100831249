import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import { ReforgeAiButton } from 'domains/Ai/ReforgeAiButton'
import { OnboardingChecklistIndicator } from 'domains/Onboarding/Checklist/OnboardingChecklistIndicator'
import { SearchField } from 'domains/Search/SearchField'

import { LeftSidebarToggle } from 'components/LeftSidebar/LeftSidebarToggle'
import Logo from 'components/LeftSidebar/Logo'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useLocalStorage from 'hooks/useLocalStorage'

import AccountDropdown from './AccountDropdown'
import { useLeftSidebar } from './LeftSidebar/useLeftSidebar'
import Tooltip from './Tooltip/Tooltip'

const TopNavBar = () => {
  const { aiBeta, freeAi } = useFeatureFlags()
  const { isLeftSidebarOpen } = useLeftSidebar()
  const { isSidePanelChatOpenDelayed } = useGlobalChat()
  const [showAiChatTooltipByDefault, setShowAiChatTooltipByDefault] = useLocalStorage(
    'show_ai_chat_tooltip',
    true
  )

  if (isLeftSidebarOpen) return null

  return (
    <>
      <div
        id="top-nav"
        className="sticky top-0 z-[1010] flex h-14 w-full items-center justify-between bg-white px-4 md:px-6 lg:h-[72px] lg:pl-8 lg:pr-0"
      >
        <div className="flex shrink-0 items-center lg:ml-[-4px] lg:w-[calc(88px-2rem+4px)] 2xl:w-[calc(240px-2rem+4px)]">
          <LeftSidebarToggle className="mr-2" />
          <Logo />
        </div>

        <div className="mx-auto flex max-w-[1280px] flex-1 items-center justify-between gap-4 lg:px-8">
          <div className="flex flex-1 shrink-0 justify-end lg:justify-start">
            <SearchField className="w-auto min-w-[40px] lg:w-full lg:max-w-[480px]" />
          </div>

          <OnboardingChecklistIndicator />

          {(aiBeta || freeAi) && (
            <Tooltip
              place="bottom"
              delayUpdate={300}
              delayHide={0}
              delayShow={120}
              className="!opacity-100"
              showByDefault={!isSidePanelChatOpenDelayed && showAiChatTooltipByDefault}
              tooltipBody={'Open Reforge AI'}
              afterHide={() => setShowAiChatTooltipByDefault(false)}
            >
              <ReforgeAiButton />
            </Tooltip>
          )}
          <div className="flex items-center">
            {/* temporarily hiding notifications */}
            {/* {currentUser?.can?.viewActivitySidebar && <ActivityLink />} */}
            <AccountDropdown />
          </div>
        </div>
      </div>
    </>
  )
}

export default TopNavBar
