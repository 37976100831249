import React from 'react'
import { twMerge } from 'tailwind-merge'

import RfParagraphLargeSemiBold from 'components/typography/RfParagraph/RfParagraphLargeSemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { ReactComponent as MapPinIcon } from 'images/map-pin.svg'
import { ReactComponent as CancelIcon } from 'images/p-cancel.svg'
import { ReactComponent as CheckCircle } from 'images/p-check-circle.svg'
import { ReactComponent as PresentationBoardGraph } from 'images/presentation-board-graph.svg'
import { ReactComponent as ProfileBrainIcon } from 'images/profile-brain.svg'
import { ReactComponent as ReadLightIdea } from 'images/read-light-idea.svg'
import { ReactComponent as UserNetworkIcon } from 'images/user-network.svg'

const benefits = [
  {
    icon: <ReadLightIdea className="min-w-8 h-8" />,
    title: 'Study material',
    description: 'Step-by-step lessons, frameworks, tips & common mistakes, and examples',
    liveCourse: true,
    onDemandCourse: true
  },
  {
    icon: <ProfileBrainIcon className="min-w-8 h-8" />,
    title: 'Direct access to tech experts',
    description:
      'Live insights and advice from experts plus unlimited chat access with course host',
    liveCourse: true,
    onDemandCourse: false
  },
  {
    icon: <UserNetworkIcon className="min-w-8 h-8" />,
    title: 'Weekly live sessions and recordings',
    description:
      'Dive deeper into the lessons with course host insights and lively Q&A discussions',
    liveCourse: true,
    onDemandCourse: false
  },
  {
    icon: <PresentationBoardGraph className="min-w-8 h-8" />,
    title: 'Relevant case studies',
    description: 'Contextualize lessons with real-world insights from guest experts',
    liveCourse: true,
    onDemandCourse: false
  },
  {
    icon: <UserNetworkIcon className="min-w-8 h-8" />,
    title: 'Collaborative learning',
    description:
      'Dedicated Slack channel to engage with a group of peers sharing similar objective',
    liveCourse: true,
    onDemandCourse: false
  },
  {
    icon: <MapPinIcon className="min-w-8 h-8" />,
    title: 'Structure and accountability',
    description:
      'Weekly priorities to help you stay on track with your learning schedule',
    liveCourse: true,
    onDemandCourse: false
  }
]

interface CourseFeatureGridProps {
  className?: string
  tableClassName?: string
  theme?: 'green' | 'black'
}

const CourseFeatureGrid = ({
  className,
  tableClassName,
  theme = 'green'
}: CourseFeatureGridProps) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      <RfParagraphLargeSemiBold className="mb-4 mr-4 !font-polysans !font-light">
        Unique benefits of live courses
      </RfParagraphLargeSemiBold>
      <RfParagraphMedium
        color={ColorOptions.gray}
        className="mb-4 sm:mb-6 !font-polysans !font-light"
      >
        Tackle challenging projects with direct access to top tech experts who have
        hands-on experience with the exact problem you’re facing.
      </RfParagraphMedium>

      <div className="bg-white relative mt-4">
        <div className="absolute -top-6 right-2 md:right-4 flex items-center gap-2 md:gap-4">
          <div></div>
          <div
            className={twMerge(
              'text-rb-white rounded-full text-center py-2 px-2 md:px-4 text-xs md:text-base font-polysans !font-light w-[70px] md:w-[170px]',
              theme === 'green' ? 'bg-rb-success-200' : 'bg-rb-gray-500'
            )}
          >
            Live course
          </div>
          <div
            className={twMerge(
              'bg-gray-100 rounded-full py-2 px-2 text-center md:px-4 text-xs md:text-base font-polysans !font-light w-[70px] md:w-[170px]'
            )}
          >
            On demand course
          </div>
        </div>

        <table
          className={twMerge(
            'border-separate border-spacing-x-2 md:border-spacing-x-4 border-spacing-y-0 rounded-lg',
            tableClassName
          )}
        >
          <thead>
            <tr></tr>
            <tr></tr>
            <tr></tr>
          </thead>
          <tbody>
            {benefits.map((benefit, i) => (
              <tr key={i}>
                <td>
                  <div className="flex gap-8 items-center pb-6">
                    <div className="hidden md:block">{benefit.icon}</div>
                    <div>
                      <RfParagraphMediumSemiBold className="mb-2">
                        {benefit.title}
                      </RfParagraphMediumSemiBold>
                      <RfParagraphMedium>{benefit.description}</RfParagraphMedium>
                    </div>
                  </div>
                </td>
                <td
                  className={twMerge(
                    'w-[70px] md:w-[170px]',
                    theme === 'green' && 'bg-rb-success-50'
                  )}
                >
                  <div className="flex items-center justify-center">
                    {benefit.liveCourse ? (
                      <CheckCircle className="min-w-6 sm:min-w-8 h-6 sm:h-8 text-rb-success-200" />
                    ) : (
                      <CancelIcon className="min-w-6 sm:min-w-8 h-6 sm:h-8 text-[#B5B8B4]" />
                    )}
                  </div>
                </td>
                <td className="w-[70px] md:w-[170px]">
                  <div className="flex items-center justify-center">
                    {benefit.onDemandCourse ? (
                      <CheckCircle className="min-w-6 sm:min-w-8 h-6 sm:h-8 text-rb-gray-300" />
                    ) : (
                      <CancelIcon className="min-w-6 sm:min-w-8 h-6 sm:h-8 text-[#B5B8B4]" />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CourseFeatureGrid
