import { ReactNode } from 'react'

import { cn } from 'utils/tailwind'

type TagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'

interface MarketingHeadingProps {
  children: string | ReactNode
  as?: string
  className?: string
}

export const MarketingH1 = ({ children, as, className }: MarketingHeadingProps) => {
  const Tag = (as || 'h1') as TagType

  return (
    <Tag
      className={cn(
        'font-polysans text-4xl !leading-[1] tracking-[-0.72px] lg:text-[64px] lg:tracking-[-1.28px] text-center !font-light',
        className
      )}
    >
      {children}
    </Tag>
  )
}

export const MarketingH2 = ({ children, as, className }: MarketingHeadingProps) => {
  const Tag = (as || 'h2') as TagType

  return (
    <Tag
      className={cn(
        'font-polysans text-[28px] !leading-[1] tracking-[-0.56px] lg:text-[49px] lg:tracking-[-0.98px] text-center !font-light',
        className
      )}
    >
      {children}
    </Tag>
  )
}

export const MarketingH3 = ({ children, as, className }: MarketingHeadingProps) => {
  const Tag = (as || 'h3') as TagType

  return (
    <Tag
      className={cn(
        '!font-sans text-lg lg:text-2xl !leading-[1.35] text-center !font-normal',
        className
      )}
    >
      {children}
    </Tag>
  )
}
