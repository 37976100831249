export const DAYS_CHECKBOXES = [
  { name: 'Monday', boxLabel: 'Monday', checked: false },
  { name: 'Tuesday', boxLabel: 'Tuesday', checked: false },
  { name: 'Wednesday', boxLabel: 'Wednesday', checked: false },
  { name: 'Thursday', boxLabel: 'Thursday', checked: false },
  { name: 'Friday', boxLabel: 'Friday', checked: true },
  { name: 'Saturday', boxLabel: 'Saturday', checked: false },
  { name: 'Sunday', boxLabel: 'Sunday', checked: false }
]

export const FILTER_NAME = 'kind'

export const MINUTES_SELECTION = [
  { value: '30 minutes', label: '30' },
  { value: '60 minutes', label: '60' },
  { value: '90 minutes', label: '90' }
]
