import { cn } from '@/lib/utils'

const linearGradientClasses = 'bg-gradient-to-r from-[#FFA2CF] via-[#FDE250] to-[#CADD48]'

interface BetaBadgeProps {
  className?: string
  gradient?: boolean
}

export const BetaBadge = ({ className, gradient = true }: BetaBadgeProps) => (
  <div
    className={cn(
      gradient && linearGradientClasses,
      'flex h-[18px] w-[37px] items-center justify-center rounded-[3px] py-1 pl-1.5 pr-2 text-xs text-rb-gray-500',
      'font-sans text-xs font-normal',
      className
    )}
  >
    Beta
  </div>
)

export default BetaBadge
