import { useState } from 'react'

import TabBar from 'components/TabBar'
import { TabKey } from 'components/TabBar/TabBar'

import InteractiveContentSectionHero from './InteractiveContentSectionHero'
import PopularContent from './PopularContent'
import { Topic } from './PopularContent/PopularContent'

export const InteractiveContentSection = () => {
  const trackingLocation = 'marketing_home__browse_content_section'
  const [activeTab, setActiveTab] = useState<TabKey>('productManagement')
  const topics: Topic[] = [
    {
      label: 'Product Management',
      slug: 'product-development',
      key: 'productManagement'
    },
    {
      label: 'Growth',
      slug: 'growth',
      key: 'growth'
    },
    {
      label: 'Marketing',
      slug: 'marketing',
      key: 'marketing'
    },
    {
      label: 'Artificial Intelligence (AI)',
      slug: 'ai',
      key: 'artificialIntelligence'
    }
  ]

  const activeTopic = topics.find((topic) => topic.key === activeTab)!

  return (
    <div className="pb-12 sm:pb-0 2xl:pb-20">
      <InteractiveContentSectionHero />
      <div className="px-4 md:px-[4vw] mb-6 md:mb-12">
        <TabBar
          initialTab={activeTab}
          tabs={topics}
          onTabChange={(key) => {
            setActiveTab(key)
          }}
          trackingLocation={trackingLocation}
        />
      </div>
      <PopularContent topic={activeTopic} trackingLocation={trackingLocation} />
    </div>
  )
}

export default InteractiveContentSection
