import { MouseEvent } from 'react'
import { Redirect } from 'react-router-dom'

import { JoinableTeam } from 'domains/Team/TeamCard/components'

import { Loading } from 'components'
import Button from 'components/Button'

import { useCurrentUserAvailableTeamsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackCtaClicked } from 'utils/tracking/analytics'
import getRefererUrl from 'utils/url/getRefererUrl'

export const DiscoverTeamsPage = () => {
  const { currentUser, refetchCurrentUser } = useCurrentUser()
  const { showDiscoverTeams } = useFeatureFlags() // The showDiscoverTeams flag has been repurposed to hide the discover teams page from the onboarding flow. Do not remove.
  const searchParams = new URLSearchParams(location.search)
  const refererParam = searchParams.get('referer')
  const refererUrl = getRefererUrl(refererParam)

  const shouldDisplayPage = showDiscoverTeams && currentUser?.can.joinTeam
  const { data, loading, refetch } = useCurrentUserAvailableTeamsQuery({
    skip: !shouldDisplayPage
  })

  const teams = data?.currentUser?.availableTeamSubscriptions || []
  const shouldRedirectToTryReforge = !refererUrl?.startsWith('/extension-authenticated')
  const skipDestination = shouldRedirectToTryReforge
    ? `/try-reforge${refererUrl ? `?referer=${encodeURIComponent(refererUrl)}` : ''}`
    : refererUrl || '/'
  const joinDestination = refererUrl || '/'

  if (!shouldDisplayPage) {
    return <Redirect to="/" />
  }

  if (loading) {
    return <Loading className="mt-64" />
  }

  const onCtaClick = (e: MouseEvent<HTMLAnchorElement>) => {
    trackCtaClicked({
      cta_location: window.location.pathname,
      cta_type: 'button',
      destination: (e.target as HTMLAnchorElement).href || window.location.pathname,
      text: (e.target as HTMLAnchorElement).innerText.toLowerCase()
    })
  }

  const onRequestedToJoin = () => {
    refetch()
    refetchCurrentUser()
  }

  return (
    <div className="grid h-[100dvh]">
      <div className="py-8 px-4 mx-auto sm:px-16 flex items-center">
        <div className="max-w-[560px]">
          <div className="text-4xl leading-[1.2] tracking-[-1.44px] font-semibold pb-2">
            Would you like to request to join a team?
          </div>

          <div className="pb-12">
            {teams.length === 1
              ? 'Based on the email you provided, we found a team for your company. If you’d like to join the team, we will send a request on your behalf.'
              : `Based on the email you provided, we found ${teams.length} teams for your company. If you’d like to join a team, we will send a request on your behalf.`}
          </div>

          <div className="flex flex-col gap-4 mb-8">
            {teams.map((team) => (
              <JoinableTeam
                key={team.id}
                team={team}
                isSignUpFlow
                destination={joinDestination}
                onRequestedToJoin={onRequestedToJoin}
              />
            ))}
          </div>

          <Button
            variant="text-only"
            className="p-0 text-rb-teal-600 text-base font-semibold hover:bg-transparent hover:underline"
            onClick={onCtaClick}
            href={skipDestination}
          >
            Skip for now
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DiscoverTeamsPage
