import CourseCard, {
  MARKETPLACE_COURSE_TYPE_LEGACY,
  MARKETPLACE_COURSE_TYPE_MARKETPLACE
} from 'pages/CoursesPage/CourseCard'
import { ARTIFACTS_HOST } from 'pages/UnitLandingPage/UnitArtifacts'

import CourseSnapshotArtifactCard from 'domains/CourseSnapshot/CourseSnapshotArtifactCard'
import SkeletonCourseSnapshotCard from 'domains/CourseSnapshot/SkeletonCourseSnapshotCard'

import Button from 'components/Button'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'

import { COURSES_LIVE_PATH, COURSES_ON_DEMAND_PATH } from 'constants/courses'

import { useCourseSnapshotMarketingCoursesQuery, useLatestArtifactsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const CourseSnapshotExplore = () => {
  const { data, loading } = useCourseSnapshotMarketingCoursesQuery()
  const { data: latestArtifactsData, loading: latestArtifactsLoading } =
    useLatestArtifactsQuery()
  const { currentUser } = useCurrentUser()

  const courses = data?.marketingCourses?.filter(
    (course) => course.courseType === MARKETPLACE_COURSE_TYPE_MARKETPLACE
  )

  const legacyCourses = data?.marketingCourses?.filter(
    (course) => course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
  )

  const loadingList = Array.from(Array(10))

  return (
    <div>
      <div className="py-6 sm:py-8">
        <RfHeader2 className="pl-6 font-polysans !font-light sm:pl-12">
          Explore more live courses
        </RfHeader2>
        <ul className="hide-scrollbar grid list-none auto-cols-max grid-flow-col gap-8 overflow-x-auto p-4 pl-6 sm:pl-12">
          {loading
            ? loadingList.map((_, index) => <SkeletonCourseSnapshotCard key={index} />)
            : courses?.map((course) => (
                <CourseCard
                  key={course.id}
                  course={course}
                  pageLocation="course-snapshot"
                  cardType="live"
                  showStartDate
                  destinationType="courseDetails"
                  showCtaText={false}
                  user={currentUser}
                />
              ))}
        </ul>

        <Button
          variant="text-only"
          className="ml-6 text-rb-gray-300 sm:ml-12"
          href={COURSES_LIVE_PATH}
        >
          See all live courses →
        </Button>
      </div>

      <div className="py-6 sm:py-8">
        <RfHeader2 className="pl-6 font-polysans !font-light sm:pl-12">
          Explore on-demand offerings to learn at your own pace
        </RfHeader2>
        <ul className="hide-scrollbar grid list-none auto-cols-max grid-flow-col gap-8 overflow-x-auto p-4 pl-6 sm:pl-12">
          {loading
            ? loadingList.map((_, index) => <SkeletonCourseSnapshotCard key={index} />)
            : legacyCourses?.map((course) => (
                <CourseCard
                  key={course.id}
                  course={course}
                  pageLocation="course-snapshot"
                  cardType="on-demand"
                  showStartDate={false}
                  destinationType="programDetails"
                  showCtaText={false}
                  user={currentUser}
                />
              ))}
        </ul>

        <Button
          variant="text-only"
          className="ml-6 text-rb-gray-300 sm:ml-12"
          href={COURSES_ON_DEMAND_PATH}
        >
          See all on-demand courses →
        </Button>
      </div>

      <div className="py-6 sm:py-8">
        <RfHeader2 className="pl-6 font-polysans !font-light sm:pl-12">
          See real work from those who have done it before
        </RfHeader2>
        <div className="hide-scrollbar grid list-none auto-cols-max grid-flow-col gap-8 overflow-x-auto p-4 pl-6 sm:pl-12">
          {latestArtifactsLoading
            ? loadingList.map((_, index) => <SkeletonCourseSnapshotCard key={index} />)
            : latestArtifactsData?.latestArtifacts?.map((artifact) => (
                <CourseSnapshotArtifactCard key={artifact.id} artifact={artifact} />
              ))}
        </div>

        <Button
          variant="text-only"
          className="ml-6 text-rb-gray-300 sm:ml-12"
          href={ARTIFACTS_HOST}
        >
          Explore more artifacts →
        </Button>
      </div>
    </div>
  )
}

export default CourseSnapshotExplore
