import { useState } from 'react'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import ExploreSubtopicPage from '../ExploreSubtopicPage/ExploreSubtopicPage'
import ExploreTopicPage from '../ExploreTopicPage/ExploreTopicPage'

interface LoggedOutExploreWrapperProps {
  PageContent: typeof ExploreTopicPage | typeof ExploreSubtopicPage
}

const LoggedOutExploreWrapper = ({ PageContent }: LoggedOutExploreWrapperProps) => {
  const [page, setPage] = useState<GenericPageType>()

  return (
    <MarketingLayout page={page}>
      <div className="max-w-[1280px] mx-auto px-4 md:px-[4vw] pt-8 pb-16">
        <PageContent setPage={setPage} />
      </div>
    </MarketingLayout>
  )
}

export default LoggedOutExploreWrapper
