import { useEffect, useState } from 'react'
import { validate } from 'uuid'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import HardcodedSearchResults from 'domains/Search/HardcodedSearchResults'
import SimilaritySearchResultsList from 'domains/Search/SimilaritySearchResultsList'
import SimilaritySearchTopResultsList from 'domains/Search/SimilaritySearchTopResultsList'
import useSearchResultsFilters, {
  SearchLinkType
} from 'domains/Search/useSearchResultsFilters'

import { ErrorMessage } from 'components'
import { useFilter as usePillFilter } from 'components/Pill'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'
import Tabs from 'components/Tabs'
import NewProgramBanner from 'components/banners/TopBanner/NewProgramBanner'

import {
  SimilaritySearchContent,
  useSearchDocumentsQuery,
  useSimilaritySearchQuery
} from 'gql'

import useURLParams from 'hooks/useURLParams'

import { trackContentClicked } from 'utils/tracking/analytics'

export interface LoggedOutSearchResultsPageProps {
  searchQuery: string
  searchType: string
  programSlug: string
  topicSlug: string
  page: number
  path: string
}

interface SearchTab {
  label: string
  href: string
  isActive: boolean
}

const LoggedOutSearchResultsPageContainer = () => {
  const { getParam } = useURLParams()
  const searchQuery = getParam('q')
  const searchType = getParam('type')
  const programSlug = getParam('program')
  const topicSlug = getParam('topic')
  const page = +getParam('page', '1')
  const path = getParam('path')

  useEffect(() => {
    // reset scroll position when search query changes
    document.getElementById('page')?.scrollTo(0, 0)
  }, [searchQuery])

  return (
    <MarketingLayout page={{ title: 'Search' } as GenericPageType}>
      <div className="px-4 md:px-[4vw]">
        <LoggedOutSearchResultsPage
          searchQuery={searchQuery}
          searchType={searchType}
          programSlug={programSlug}
          topicSlug={topicSlug}
          page={page}
          path={path}
        />
      </div>
    </MarketingLayout>
  )
}

export const LoggedOutSearchResultsPage = ({
  searchQuery,
  searchType,
  programSlug,
  topicSlug,
  page,
  path
}: LoggedOutSearchResultsPageProps) => {
  const [tabs, setTabs] = useState([] as SearchTab[])

  const { error, data } = useSearchDocumentsQuery({
    variables: {
      query: searchQuery,
      type: searchType,
      page,
      program: programSlug,
      topic: topicSlug,
      path
    },
    skip: searchType !== SearchLinkType.USER
  })

  const {
    loading: similarityLoading,
    data: similarityData,
    error: similarityError
  } = useSimilaritySearchQuery({
    variables: {
      query: searchQuery,
      type: searchType,
      initiator: path
    },
    skip: !searchQuery || searchType === SearchLinkType.USER
  })

  // Removed in REF-16768: FF: Hide "More questions" section from search results page for logged out users
  // const searchSuggestions = similarityData?.similaritySearch?.suggestions || []
  const searchSuggestions = undefined

  const searchResultsTotalCount = data?.searchDocuments?.totalCount || 0
  const similarResultsTotalCount = similarityData?.similaritySearch
    ? similarityData.similaritySearch.results.length
    : 0
  const userCan = {
    linkToSearchResults: false,
    viewMemberProfile: false,
    viewPost: false,
    viewSearchTabs: false
  }

  const filters = useSearchResultsFilters({ searchType, userCan, searchQuery })

  const { title: activePill = 'All' } = filters.find(({ id }) => id === searchType) || {}

  const { setActivePill } = usePillFilter(activePill)

  useEffect(() => {
    setActivePill(activePill)

    const searchTabs = filters.map(({ title, href }) => ({
      label: title,
      href,
      isActive: title === activePill
    }))

    setTabs(searchTabs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, searchQuery])

  const calculateResultPosition = (page: number, searchPosition: number) => {
    const pagesAfterFirst = page - 1
    const offset = pagesAfterFirst * 10
    return offset + searchPosition
  }

  const handleBaseResultClick = (
    topResult: boolean,
    url: string,
    path: string,
    searchPosition: number,
    resultComponent: string,
    contentType?: string,
    subType?: string | null,
    doc?: SimilaritySearchContent | null
  ) => {
    const resultPosition = calculateResultPosition(page, searchPosition)

    const documentId = doc?.id || ''
    const eventObj = {
      content_sanity_id: validate(documentId) ? doc?.id : null,
      content_title: doc?.title,
      content_type: contentType,
      display_type: 'horizontal_card',
      location: 'search',
      logged_in: false,
      related_identifiers: {
        search_query: searchQuery,
        search_result_component: resultComponent,
        search_number_of_results: searchResultsTotalCount + similarResultsTotalCount,
        search_result_position: `${resultPosition}`,
        search_is_top_result: topResult,
        content_mode: 'async'
      }
    }

    if (error || similarityError) {
      return <ErrorMessage error={similarityError || error} />
    }

    try {
      trackContentClicked(eventObj)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSimilarityResultClick = handleBaseResultClick.bind(null, false)

  const handleSimilarityResultClickTop = (
    topResult: boolean,
    url: string,
    path: string,
    searchPosition: number,
    resultComponent: string,
    contentType: string,
    subType: string | null,
    doc: SimilaritySearchContent | null
  ) => {
    // Provide default values for string parameters that could be undefined
    const safeResultComponent = resultComponent || ''
    const safeContentType = contentType || ''
    const safeSubType = subType || ''

    // // Call handleBaseResultClick with the safe string values
    handleBaseResultClick(
      topResult,
      url,
      path,
      searchPosition,
      safeResultComponent,
      safeContentType,
      safeSubType,
      doc
    )
  }

  return (
    <>
      <NewProgramBanner hasBorder className="mb-6" />

      <ShareableSnapshotModal page="Search" />

      <div className="flex justify-center my-10">
        <div id="list" className="flex w-full max-w-5xl flex-col gap-8">
          <HardcodedSearchResults searchQuery={searchQuery} />

          <Tabs tabs={tabs} />
          {/* ALL TAB */}
          {!searchType && (
            <>
              <SimilaritySearchTopResultsList
                path={path}
                key={`top-1-${searchQuery}`}
                linkType={SearchLinkType.CMS_SECTION}
                searchQuery={searchQuery}
                handleResultClick={handleSimilarityResultClickTop}
                userCan={userCan}
                itemsPerSection={3}
                loading={similarityLoading}
                searchResults={similarityData?.similaritySearch?.results}
                suggestions={searchSuggestions}
                currentUser={null}
              />
            </>
          )}

          {/* PROGRAM CONTENT TAB */}
          {!!searchType && searchType === SearchLinkType.CMS_SECTION && (
            <SimilaritySearchResultsList
              key={`sim-lessons-1-${searchQuery}`}
              searchQuery={searchQuery}
              handleResultClick={handleSimilarityResultClick}
              userCan={userCan}
              sectionHeader="Program Content"
              linkType={SearchLinkType.CMS_SECTION}
              hideHeader={true}
              loading={similarityLoading}
              searchResults={similarityData?.similaritySearch?.results}
              currentUser={null}
            />
          )}

          {/* COURSES TAB */}
          {!!searchType && searchType === SearchLinkType.COURSE && (
            <SimilaritySearchResultsList
              key={`sim-courses-1-${searchQuery}`}
              searchQuery={searchQuery}
              handleResultClick={handleSimilarityResultClick}
              userCan={userCan}
              sectionHeader="Courses"
              linkType={SearchLinkType.COURSE}
              hideHeader={true}
              loading={similarityLoading}
              searchResults={similarityData?.similaritySearch?.results}
              currentUser={null}
            />
          )}

          {/* ARTIFACTS TAB */}
          {!!searchType && searchType === SearchLinkType.ARTIFACT && (
            <SimilaritySearchResultsList
              key={`sim-artifacts-1-${searchQuery}`}
              searchQuery={searchQuery}
              handleResultClick={handleSimilarityResultClick}
              userCan={userCan}
              sectionHeader="Artifacts"
              linkType={SearchLinkType.ARTIFACT}
              hideHeader={true}
              loading={similarityLoading}
              searchResults={similarityData?.similaritySearch?.results}
              currentUser={null}
            />
          )}

          {/* GUIDES TAB */}
          {!!searchType && searchType === SearchLinkType.GUIDE && (
            <SimilaritySearchResultsList
              key={`sim-guides-1-${searchQuery}`}
              searchQuery={searchQuery}
              handleResultClick={handleSimilarityResultClick}
              userCan={userCan}
              sectionHeader="Guides"
              linkType={SearchLinkType.GUIDE}
              hideHeader={true}
              loading={similarityLoading}
              searchResults={similarityData?.similaritySearch?.results}
              currentUser={null}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default LoggedOutSearchResultsPageContainer
