import { cn } from '@/lib/utils'
import { Combobox, Transition } from '@headlessui/react'
import {
  type ChangeEvent,
  InputHTMLAttributes,
  type ReactElement,
  forwardRef,
  useState
} from 'react'
import { twMerge } from 'tailwind-merge'

import { MIN_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { ReactComponent as ClearIcon } from 'images/icon--clear.svg'
import { ReactComponent as SearchIcon } from 'images/p-search.svg'

type SearchComboboxProps = {
  className?: string
  context?: ReactElement | ReactElement[] | null
  initialQuery?: string
  query: string
  placeholder?: string
  onSearch: (queryOption: SearchOption) => void
  onChange?: (query: string) => void
  onFocus?: () => void
  /**
   * Pulse animation around the desktop search input
   * */
  pulse?: boolean
  shouldAlwaysShowFull?: boolean
  dropdownClassName?: string
  inputClassName?: string
}

export function SearchCombobox({
  className,
  context,
  initialQuery = '',
  query,
  placeholder,
  onSearch,
  onChange,
  onFocus,
  pulse = false,
  shouldAlwaysShowFull = false,
  dropdownClassName,
  inputClassName
}: SearchComboboxProps) {
  const [selectedOption, setSelectedOption] = useState<SearchOption>({
    value: initialQuery
  })
  const isScreenNav = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)

  const handleComboboxOnChange = (option: SearchOption) => {
    onChange?.(option?.path ? '' : option.value)
    setSelectedOption(option)
    onSearch(option)
  }

  const handleInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value)
  }

  const handleClear = () => {
    onChange?.('')
    setSelectedOption({ value: '' })
  }

  let transitionClassName = 'absolute h-full w-full lg:h-auto lg:max-w-[480px]'
  let optionsClassName =
    'flex h-full w-full flex-col gap-4 rounded-2xl p-4 lg:mt-4 lg:h-auto lg:border lg:border-rb-gray-100 lg:bg-white lg:p-6'

  if (shouldAlwaysShowFull) {
    transitionClassName = 'h-auto max-w-[480px]'
    optionsClassName = 'mt-4 h-auto border border-rb-gray-100 bg-white p-6'
  }

  if (dropdownClassName) {
    optionsClassName = cn(optionsClassName, dropdownClassName)
  }

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={handleComboboxOnChange}
      className={({ open }) =>
        twMerge(
          // open & mobile
          open ? 'fixed inset-0 z-1 h-screen w-screen bg-white' : null,
          // open & lg+
          open ? 'lg:relative lg:h-auto lg:w-auto lg:bg-transparent' : null,
          shouldAlwaysShowFull ? 'relative h-auto w-auto bg-transparent' : null,
          className
        )
      }
    >
      {({ open }) => (
        <>
          {/* MOBILE */}
          {!isScreenNav && !shouldAlwaysShowFull && (
            <>
              {!open && (
                <Combobox.Button
                  data-testid="mobile-search-open"
                  className="h-10 w-10 p-2 lg:hidden"
                >
                  <SearchIcon className={twMerge('h-6 w-6 text-rb-gray-500')} />
                </Combobox.Button>
              )}

              {open && (
                <>
                  <div className="flex items-center gap-4 py-3 px-4 lg:hidden">
                    <div
                      className={cn(
                        'relative flex h-[40px] w-full flex-1 items-center rounded-full border border-rb-gray-100 bg-rb-gray-50 px-4 outline-1 outline-rb-gray-200 ring-0 focus-within:outline',
                        inputClassName
                      )}
                    >
                      <SearchIcon
                        className={twMerge(
                          'pointer-events-none absolute left-4 h-[18px] w-[18px] text-rb-gray-300'
                        )}
                      />
                      <Combobox.Input
                        as={SearchInput}
                        name="search-mobile"
                        value={query}
                        onChange={handleInputOnChange}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        autoComplete="off"
                        className="w-full bg-transparent pl-8 text-base text-rb-black/85 outline-none focus:outline-none"
                      />
                      {query.length > 0 && (
                        <button
                          onClick={handleClear}
                          onMouseDown={handleClear}
                          onPointerDown={handleClear}
                          onTouchEnd={handleClear}
                          className="shink-0 -mr-2 h-[40px] px-2"
                        >
                          <ClearIcon className="h-3 w-3 fill-rb-gray-300" />
                        </button>
                      )}
                    </div>
                    <Combobox.Button
                      onClick={handleClear}
                      className="h-[40px] shrink-0 text-sm text-rb-gray-300"
                    >
                      Cancel
                    </Combobox.Button>
                  </div>
                </>
              )}
            </>
          )}

          {/* END MOBILE */}

          {/* DESKTOP */}
          {(isScreenNav || shouldAlwaysShowFull) && (
            <Combobox.Button
              as="div"
              onClick={(e) => {
                if (open) {
                  // allow the user to double click to select the text
                  e.preventDefault()
                }
              }}
              className={twMerge(
                'relative flex h-[40px] w-full items-center rounded-full border border-rb-gray-100 bg-rb-gray-50 px-4 outline-1 outline-rb-gray-200 ring-0 focus-within:outline',
                pulse ? 'animate-pulseSlow' : null,
                inputClassName
              )}
            >
              <SearchIcon className="pointer-events-none absolute left-4 h-[18px] w-[18px] text-rb-gray-300" />
              <Combobox.Input
                name="search"
                value={query}
                onChange={handleInputOnChange}
                onFocus={onFocus}
                placeholder={placeholder}
                autoComplete="off"
                className="w-full bg-transparent pl-8 text-base text-rb-black/85 outline-none focus:outline-none"
              />
              {query.length > 0 && (
                <button onClick={handleClear} className="-mr-2 h-[40px] shrink-0 px-2">
                  <ClearIcon className="h-3 w-3 fill-rb-gray-300" />
                </button>
              )}
            </Combobox.Button>
          )}
          {/* END DESKTOP */}

          {/* We need to register the query term as a combobox item */}
          <Combobox.Option
            as="div"
            className="hidden"
            value={{ value: query, origin: 'user-input' as const }}
          />

          {context && (
            <Transition
              show={open}
              className={transitionClassName}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Combobox.Options static as="div" className={optionsClassName}>
                {context}
              </Combobox.Options>
            </Transition>
          )}
        </>
      )}
    </Combobox>
  )
}

const SearchInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  function (props, ref) {
    return (
      // the form and action are needed for iOS to render the Search button on the keyboard
      <form className="w-full" action="">
        <input ref={ref} {...props} type="search" />
      </form>
    )
  }
)
SearchInput.displayName = 'SearchInput'

export type SearchOption = {
  value: string
  path?: string
  origin?: 'user-input' | 'search-suggestion' | 'popular-search' | 'recently-viewed'
}

type SearchComboboxOptionsProps = {
  className?: string
  title?: string | ReactElement
  options: SearchOption[]
}

export function SearchComboboxOptions({
  className,
  title,
  options
}: SearchComboboxOptionsProps): ReactElement {
  return (
    <div className={twMerge(className)}>
      {title && <p className="text-xl font-semibold text-rb-black">{title}</p>}
      <ul className="list-none p-0">
        {options.map((option) => (
          <Combobox.Option
            className={({ active }) =>
              twMerge(
                'cursor-pointer py-2.5 text-base text-rb-teal-300',
                active ? 'underline underline-offset-2' : null
              )
            }
            key={option.value}
            value={option}
          >
            {option.value}
          </Combobox.Option>
        ))}
      </ul>
    </div>
  )
}
