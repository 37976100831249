import { HomepageContent } from 'gql'

import TestimonialCard from './TestimonialCard'

const TestimonialSection = ({ content }: { content: HomepageContent }) => {
  return (
    <section className="px-4 md:px-[4vw] w-full relative overflow-hidden bg-rb-orange-25">
      <div className="relative flex flex-col lg:flex-row gap-4 md:gap-6 items-center justify-center pt-[100px] pb-[50px] lg:pt-[150px] lg:pb-[100px]">
        <div className="absolute top-[50px] lg:top-[75px] w-full text-center uppercase text-sm lg:text-lg !leading-[1.35] tracking-[1.4px] lg:tracking-[1.8px]">
          What members are saying
        </div>
        {content?.testimonials?.map((testimonial) => (
          <TestimonialCard key={testimonial.name} testimonial={testimonial} />
        ))}
      </div>
    </section>
  )
}

export default TestimonialSection
