import { FC, useEffect } from 'react'
import joinSlackImage from 'slack-banner-image.png'

import SlackIcon from 'components/icons/SlackIcon'

import { track } from 'utils/tracking/segment'

import { AnnouncementBanner } from '../AnnouncementBanner/AnnouncementBanner'
import { AnnouncementBannerProps } from '../AnnouncementBanner/AnnouncementBannerCarousel'

export const SlackBanner: FC<AnnouncementBannerProps> = ({ isVisible }) => {
  useEffect(() => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
    track('Slack - Action', {
      action: 'join_slack_cta_displayed',
      location: 'top_banner_cta'
    })
  }, [])

  const handleClick = () => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
    track('Slack - Action', {
      action: 'join_slack_cta_clicked',
      location: 'top_banner_cta'
    })

    window.open(
      'https://join.slack.com/t/reforge-members/shared_invite/zt-1hcoz4s76-vvIfW2QIvHDa5R~wV64kFA'
    )
  }

  return (
    <AnnouncementBanner
      firstCta={{
        text: 'Join Slack',
        icon: <SlackIcon />,
        onClick: handleClick
      }}
      color={'#653866'}
      textColor={'#ffffff'}
      image={joinSlackImage}
      header={'From new connections to career companions'}
      subHeader={
        'Meet people who can shape your career journey by joining Reforge members on Slack'
      }
      isVisible={isVisible}
    />
  )
}
