import { useState } from 'react'

import { ProductTourKey } from 'gql'

import { cn } from 'utils/tailwind'

import { availableBannersMap } from '../AnnouncementWrapper'
import { CarouselDot } from './CarouselDot'

export interface AnnouncementBannerProps {
  isVisible: boolean
  setActiveComponents: (prevState: string[]) => void
  completeProductTour: () => void
}

interface AnnouncementBannerCarouselProps {
  activeComponents: string[]
  setActiveComponents: (prevState: string[]) => void
  completeProductTour: (identifier: ProductTourKey | string) => void
}

const AnnouncementBannerCarousel = ({
  activeComponents,
  setActiveComponents,
  completeProductTour
}: AnnouncementBannerCarouselProps) => {
  const [indexVisible, setIndexVisible] = useState(0)

  if (!activeComponents || !activeComponents.length) return null

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-4 mb-12 self-stretch text-rb-gray-400'
      )}
    >
      <div className="flex w-full font-polysans">
        {activeComponents.map((componentKey, i) => {
          const Banner = availableBannersMap[componentKey]

          return Banner ? (
            <Banner
              key={i}
              isVisible={i === indexVisible}
              setActiveComponents={setActiveComponents}
              completeProductTour={() => completeProductTour(componentKey)}
            />
          ) : null
        })}
      </div>
      {activeComponents.length > 1 && (
        <div className="flex w-full justify-center gap-2">
          {activeComponents.map((_, i) => (
            <CarouselDot
              key={i}
              active={indexVisible === i}
              onClick={() => setIndexVisible(i)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AnnouncementBannerCarousel
