interface ILessonSummary {
  summaryHTML: string
}

const LessonSummary = ({ summaryHTML = '' }: ILessonSummary) => (
  <div className="uk-grid uk-grid-collapse mt-4 sm:mt-5">
    <div className="uk-width-expand mt-[16.5px] mb-5 max-w-[660px] bg-rb-gray-50 p-2.5 !font-sans xs:p-4 md:p-8">
      <div className="mb-2.5 !font-sans !text-[21px] !font-bold !leading-[26px] !text-rb-gray-500">
        Lesson Summary
      </div>
      <div
        className="cms-content-summary__content !font-sans text-base !font-normal leading-6 !text-rb-gray-500"
        dangerouslySetInnerHTML={{ __html: summaryHTML }}
      />
    </div>
  </div>
)

export default LessonSummary
