import GlobalProviders from 'GlobalProviders'
import { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useGlobalState } from 'state'

import PrivateRoutes from 'routes/PrivateRoutes'
import PublicRoutes from 'routes/PublicRoutes'

import RealTimeEventReminderContainer from 'domains/Event/RealTimeEventReminder/RealTimeEventReminderContainer'

import { TailwindIndicator } from 'components/TailwindIndicator'
import { Toast } from 'components/Toast'

import notifyError from 'utils/errorNotifier'
import { fetchData } from 'utils/fetcher'

export interface AppEnvVariables {
  froalaHash: string
  froalaKey: string
  iAmATimeTraveler: boolean
  isAuthenticated: boolean
  joinSlackHref: string
  railsEnv: string
}
interface AppProps {
  isLoggedIn?: boolean
}

export default function AppWrapper() {
  const [error, setError] = useState(null)
  const [envVariables, setEnvVariables] = useGlobalState('envVariables')
  const [ready, setReady] = useState(false)

  // TODO: This will go away once the FE is split out and has its own env management. (Stu)
  useEffect(() => {
    fetchData('/api/v1/rails_variables', {}, (err: any, response: any) => {
      if (err) {
        setError(err)
      } else {
        setEnvVariables(response)
      }

      // Ensure we do not try to load the App until we have env variables from rails https://reforge.atlassian.net/browse/REF-13492
      setReady(true)
    })
  }, [setEnvVariables, ready])

  if (error) {
    notifyError(error)
    return <div>Sorry there was an error. Please refresh the page.</div>
  }

  return envVariables && ready && <App isLoggedIn={envVariables?.isAuthenticated} />
}

export function App({ isLoggedIn = false }: AppProps) {
  return (
    <GlobalProviders isLoggedIn={isLoggedIn}>
      <PublicRoutes isLoggedIn={isLoggedIn} />
      <PrivateRoutes />
      {isLoggedIn && <RealTimeEventReminderContainer />}
      <Toast />
      {/* From legacy Artifacts codebase, we'll eventually merge toasters */}
      <Toaster
        position="bottom-center"
        toastOptions={{
          // Define default options
          className: 'text-xs bg-rb-black',
          style: {
            background: '#080A0A',
            color: '#fff',
            fontSize: '12px',
            padding: '14px'
          }
        }}
      />
      <TailwindIndicator />
      <div id="rf-modal-container-portal" />
      <div id="fullscreen-chat-container" />
      <div id="tooltip-container" />
      <div
        id="rf-fixed-bottom-portal"
        style={{ position: 'fixed', bottom: 0, width: '100%' }}
      />
    </GlobalProviders>
  )
}
