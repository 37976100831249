import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'

import { MAX_WIDTH_TAILWIND_TL, MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { useExploreTopicsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import ButtonMarketingCTA from '../ButtonMarketingCTA'
import HamburgerButton from './HamburgerButton'
import ItemWithDropdown from './ItemWithDropdown'
import LogInButton from './LogInButton'
import Logo from './Logo'
import { NavItemType } from './NavItem'
import SearchBar from './SearchBar'
import SignUpButton from './SignUpButton'

const NAV_ITEMS: NavItemType[] = [
  {
    title: 'Explore',
    type: 'button',
    align: 'left',
    dropdown: [
      {
        title: 'Courses',
        url: '/courses'
      },
      {
        title: 'Artifacts',
        url: '/artifacts'
      },
      {
        title: 'Guides',
        url: '/guides'
      },
      // {
      //   type: 'separator' // left this commented here so future devs know it exists
      // },
      {
        title: 'Events',
        url: '/events'
      },
      {
        title: 'Experts',
        url: '/experts'
      }
    ]
  },
  {
    title: 'Pricing',
    url: '/pricing'
  },
  {
    title: 'For Teams',
    url: '/teams'
  },
  {
    title: 'More',
    dropdown: [
      {
        title: 'Podcast',
        url: '/podcast/unsolicited-feedback'
      },
      {
        title: 'Blog',
        url: '/blog'
      },
      {
        title: 'Extension',
        url: '/extension'
      }
    ]
  }
]

interface MarketingHeaderProps {
  transparentNav?: boolean
}

const MarketingHeader = ({ transparentNav }: MarketingHeaderProps) => {
  const { showBrowseByTopics } = useFeatureFlags()
  const { data: exploreTopicsData, loading } = useExploreTopicsQuery()
  const topics = exploreTopicsData?.exploreTopics || []

  if (showBrowseByTopics) {
    const navItem = NAV_ITEMS[0]

    if (navItem.dropdown) {
      navItem.dropdown = {
        columns: [
          {
            title: 'By type',
            items: [
              {
                title: 'Courses',
                url: '/courses'
              },
              {
                title: 'Artifacts',
                url: '/artifacts'
              },
              {
                title: 'Guides',
                url: '/guides'
              },
              {
                title: 'Events',
                url: '/events'
              },
              {
                title: 'Experts',
                url: '/experts'
              }
            ]
          },
          {
            title: 'By topic',
            items:
              topics.length && !loading
                ? topics
                    .map((topic) => ({
                      title: topic.title,
                      url: `/explore/${topic.slug}`
                    }))
                    .filter((item) => item.title)
                    .sort((a, b) => a.title!.localeCompare(b.title!))
                : Array(10).fill({
                    title: (
                      <div className="h-[22.4px] w-[154px] animate-pulse rounded-full bg-rb-gray-100" />
                    )
                  })
          }
        ]
      }
    }
  }

  const { currentUser, isLoggedIn } = useCurrentUser()
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [shouldTransition, setShouldTransition] = useState(false)
  const [activeDropdownUrl, setActiveDropdownUrl] = useState<string | null | undefined>(
    null
  )
  const [dropdown, setDropdown] = useState<NavItemType | null>(null)
  const [showNavBg, setShowNavBg] = useState(false)
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)
  const isXSView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const navItems = NAV_ITEMS
  const scrollThreshold = 50 // px

  useEffect(() => {
    if (mobileNavOpen) {
      document.documentElement.classList.add('overflow-hidden')
    } else {
      // reset the dropdown when the mobile nav is closed, with a delay to allow the animation to complete
      setTimeout(() => {
        setDropdown(null)
      }, 300)
      document.documentElement.classList.remove('overflow-hidden')
    }
  }, [mobileNavOpen])

  useEffect(() => {
    // reset mobile view when the window is resized to desktop
    if (!isMobileView) {
      setMobileNavOpen(false)
      setShouldTransition(false)
      setDropdown(null)
      setActiveDropdownUrl(null)
    }
  }, [isMobileView])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setShowNavBg(true)
      } else {
        setShowNavBg(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const onHamburgerClick = () => {
    setMobileNavOpen(!mobileNavOpen)
    setShouldTransition(true)
  }

  return (
    <header
      className={cn(
        'fixed top-0 left-0 right-0 z-1010 flex items-center justify-between px-[16px] h-14 lg:h-[76px] transition-all md:px-[4vw]',
        transparentNav && !mobileNavOpen && !showNavBg ? 'bg-transparent' : 'bg-rb-white'
      )}
    >
      <ButtonMarketingCTA
        href="#content"
        className="fixed top-[20px] left-[16px] -translate-x-[200%] focus:translate-x-0"
      >
        Skip to Content
      </ButtonMarketingCTA>

      <Logo mobileNavOpen={mobileNavOpen} />

      <div className="flex items-center">
        {isXSView && !isLoggedIn && (
          <div className="mr-4 flex items-center gap-4">
            {mobileNavOpen ? <LogInButton /> : <SearchBar />}
            <SignUpButton />
          </div>
        )}

        {!isXSView && isMobileView && !isLoggedIn && (
          <div className="mr-4 flex items-center gap-4">
            {!mobileNavOpen && <SearchBar />}
            <LogInButton />
            <SignUpButton />
          </div>
        )}

        <HamburgerButton onClick={onHamburgerClick} mobileNavOpen={mobileNavOpen} />
      </div>

      <div
        className={cn(
          'invisible fixed bottom-0 left-0 right-0 -z-1 top-14 flex translate-y-20 flex-col overflow-y-auto overflow-x-hidden bg-rb-white opacity-0 transition-none duration-300 ease-in-out lg:visible lg:static lg:!top-0 lg:z-0 lg:translate-y-0 lg:flex-row lg:overflow-visible lg:opacity-100 lg:transition-none lg:grow',
          transparentNav && !mobileNavOpen && 'bg-transparent',
          mobileNavOpen && 'visible translate-y-0 opacity-100',
          shouldTransition && 'transition-all lg:transition-none'
        )}
      >
        <nav
          className={cn(
            'grid h-full grid-cols-1 grid-rows-[1fr_auto] items-start p-4 duration-300 md:px-[4vw] lg:flex lg:flex-row lg:px-0 lg:grow',
            dropdown && '-translate-x-[calc(100%)]'
          )}
        >
          <div className="relative w-full">
            <ul
              className={cn(
                'flex h-full list-none flex-col items-start p-0 lg:flex-row lg:items-center'
              )}
            >
              {/* Items to the left of the search bar */}
              {navItems
                .filter((item) => item.align === 'left')
                .map((item, i) => (
                  <ItemWithDropdown
                    key={i}
                    item={item}
                    isMobileView={isMobileView}
                    mobileNavOpen={mobileNavOpen}
                    activeDropdownUrl={activeDropdownUrl}
                    dropdown={dropdown}
                    setDropdown={setDropdown}
                    setActiveDropdownUrl={setActiveDropdownUrl}
                  />
                ))}

              {!isMobileView && <SearchBar whiteBg={transparentNav && !showNavBg} />}

              {/* Items to the right of the search bar */}
              {navItems
                .filter((item) => item.align !== 'left')
                .map((item, i) => (
                  <ItemWithDropdown
                    key={i}
                    item={item}
                    isMobileView={isMobileView}
                    mobileNavOpen={mobileNavOpen}
                    activeDropdownUrl={activeDropdownUrl}
                    dropdown={dropdown}
                    setDropdown={setDropdown}
                    setActiveDropdownUrl={setActiveDropdownUrl}
                  />
                ))}

              {!isLoggedIn && !isMobileView && <LogInButton />}
            </ul>
          </div>
        </nav>
        <div
          className={cn(
            'flex items-center px-4 md:px-[4vw] lg:w-auto lg:border-t-0 lg:px-0 z-1',
            isLoggedIn && 'border-t border-t-rb-gray-200'
          )}
        >
          {isLoggedIn ? (
            <Link
              to="/account"
              className="flex items-center py-6 lg:ml-4 lg:w-[30px] lg:py-0"
              onClick={() =>
                trackNavigationClicked({
                  location: 'marketing_site__top_nav',
                  type: 'top navigation',
                  destination: '/account',
                  text: currentUser?.fullName || '-user full name missing-'
                })
              }
            >
              <Image
                width={30}
                height={30}
                src={currentUser?.profile.avatarUrl}
                className="h-full max-h-[30px] w-full max-w-[30px] rounded-full object-cover"
              />

              <span className="ml-4 text-base text-rb-black underline hover:text-rb-teal-200 lg:hidden">
                {currentUser?.fullName}
              </span>
            </Link>
          ) : !isMobileView ? (
            <SignUpButton />
          ) : null}
        </div>
      </div>
    </header>
  )
}

export default MarketingHeader
