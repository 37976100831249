import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'

import { PlanOptionFields } from 'constants/plans'

import { PlanName } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { LegacySubPlanOptionCard } from './LegacySubPlanOptionCard'
import { PlanOptionCard } from './PlanOptionCard'
import PricingPlanCard from './PricingPlanCard'
import {
  PlanOptionCardNamespace,
  isPlanDowngrade,
  useBuildPlanOptionsData
} from './helpers'

const getCtaLabel = (
  planName: string,
  currentPlanName: string,
  isDowngrade: boolean,
  removeTeamsSelfServe: boolean
) => {
  if (planName === currentPlanName) {
    return `You are on ${planName}`
  } else if (
    planName === PlanName.ENTERPRISE ||
    (planName !== PlanName.INDIVIDUAL && removeTeamsSelfServe) ||
    isDowngrade
  ) {
    return 'Get in touch'
  } else {
    return `Change to ${planName}`
  }
}

export interface PlanOptionsProps {
  currentPlanName: PlanName
  currentNumSeats: number
  currentPlanPrice?: number
  isSubscriptionOwner: boolean
  plansForSale: PlanOptionCardNamespace.PlanForSale[]
  upcomingPlanName?: PlanName
  upcomingNumCohortPasses: number
  isCanceled?: boolean | null
  isTrialing?: boolean | null
}

const PlanOptions = ({
  currentPlanName,
  currentNumSeats,
  currentPlanPrice,
  isSubscriptionOwner,
  plansForSale,
  upcomingPlanName,
  upcomingNumCohortPasses,
  isCanceled,
  isTrialing
}: PlanOptionsProps) => {
  const { premiumUpdatePaymentFlow, removeTeamsSelfServe } = useFeatureFlags()
  const { openCheckoutModal } = useCheckoutModal()

  const childrenClasses = '[&>*]:mx-2 [&>*]:my-2'
  const planOptionsData = useBuildPlanOptionsData({
    plansForSale,
    currentPlanName,
    currentPlanPrice: isSubscriptionOwner ? currentPlanPrice : undefined,
    currentPlanSeats: currentNumSeats
  })

  const handleCtaClick = (option: PlanOptionFields) => {
    const isDowngrade = isPlanDowngrade(option.name, currentPlanName, currentNumSeats)
    const planName = option.name || currentPlanName
    if (
      planName === PlanName.ENTERPRISE ||
      (planName !== PlanName.INDIVIDUAL && removeTeamsSelfServe)
    ) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_plan_page',
        text: getCtaLabel(
          option.name,
          currentPlanName,
          isDowngrade,
          removeTeamsSelfServe
        ),
        destination: 'enterprise_renewal_form'
      })
      return window.open('/enterprise_renewal', '_blank')
    }

    if (isDowngrade) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'manage_plan_page',
        text: getCtaLabel(
          option.name,
          currentPlanName,
          isDowngrade,
          removeTeamsSelfServe
        ),
        destination: 'email_support'
      })
      return window.open('mailto:hello@reforge.com', '_blank')
    }

    trackCtaClicked({
      cta_type: 'button',
      cta_location: 'manage_plan_page',
      text: getCtaLabel(option.name, currentPlanName, isDowngrade, removeTeamsSelfServe)
    })

    openCheckoutModal({
      planName: option.name,
      isIndividual: upcomingPlanName === PlanName.INDIVIDUAL,
      numCohortPasses: 0
    })
  }

  let cards: ReactNode[] = []
  if (currentPlanName === 'unlimited' && isSubscriptionOwner) {
    cards = planOptionsData.map((option) => {
      return (
        <LegacySubPlanOptionCard
          key={option.name}
          cardPlanName={option.name}
          currentPlanName={currentPlanName}
          currentNumSeats={currentNumSeats}
          isSubscriptionOwner={isSubscriptionOwner}
          title={option.title}
          price={option.price}
          subtitle={option.subtitle}
          savingsTagContent={option.savingsTagContent}
          content={option.content}
          headerBarBgClass={option.headerBarBgClass}
          upcomingPlanName={upcomingPlanName}
          maxSeatCount={option.maxSeatCount}
          upcomingNumCohortPasses={upcomingNumCohortPasses}
          isCanceled={isCanceled}
        />
      )
    })
  } else if (premiumUpdatePaymentFlow) {
    cards = planOptionsData.map((option, i) => (
      <PricingPlanCard
        key={option.name}
        planName={option.name}
        features={option.features}
        isCurrentPlan={option.name === currentPlanName}
        ctaLabel={getCtaLabel(
          option.name,
          currentPlanName,
          isPlanDowngrade(option.name, currentPlanName, currentNumSeats),
          removeTeamsSelfServe
        )}
        price={option.price}
        firstSubheader={option.subtitle}
        secondSubheader={isTrialing ? 'Plus one $995 course pass' : option.secondSubtitle}
        prevPlanName={i === 0 ? null : planOptionsData[i - 1].name}
        onCtaClick={() => handleCtaClick(option)}
        showCta={true}
        ctaDisabled={option.name === currentPlanName}
      />
    ))
  } else {
    cards = planOptionsData.map((option) => {
      const cardPlanName = option.name
      return (
        <PlanOptionCard
          key={option.name}
          cardPlanName={cardPlanName}
          currentPlanName={currentPlanName}
          currentNumSeats={currentNumSeats}
          isSubscriptionOwner={isSubscriptionOwner}
          title={option.title}
          price={option.price}
          subtitle={option.subtitle}
          savingsTagContent={option.savingsTagContent}
          content={option.content}
          maxSeatCount={option.maxSeatCount}
          headerBarBgClass={option.headerBarBgClass}
          onPlanSelected={() => {
            openCheckoutModal({
              planName: cardPlanName,
              isIndividual: upcomingPlanName === PlanName.INDIVIDUAL
            })
          }}
          upcomingNumCohortPasses={upcomingNumCohortPasses}
        />
      )
    })
  }

  return (
    <div className="lg:overflow-x-auto lg:overflow-y-hidden">
      <div
        className={twMerge(
          '-mx-2 mt-4 flex w-full flex-wrap xl:flex-nowrap',
          childrenClasses
        )}
      >
        {cards}
      </div>
    </div>
  )
}

export default PlanOptions
