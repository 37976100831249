import { format } from 'date-fns'
import { KeyboardEvent, MouseEvent, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  EventPartsFragment,
  HomepageUpcomingEvent,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { getCurrentTimezone, getFormattedTimeWithTimeZone } from 'utils/date'
import { trackNavigationClicked } from 'utils/tracking/analytics'

interface UpcomingEventsProps {
  events: HomepageUpcomingEvent[]
  publicEvents: EventPartsFragment[]
}

const UpcomingEvents = ({ events, publicEvents }: UpcomingEventsProps) => {
  const { currentUser } = useCurrentUser()
  const userTimezone: string = getCurrentTimezone(currentUser?.timezone)

  const filteredPublicEvents = publicEvents.filter(
    (publicEvent) => !events.some((event) => event.id === publicEvent.id)
  )

  const combinedEvents: HomepageUpcomingEvent[] = [...events]
  const remainingPublicEvents = filteredPublicEvents.slice(0, 3 - combinedEvents.length)

  combinedEvents.push(
    ...remainingPublicEvents.map(
      (publicEvent) =>
        ({
          id: publicEvent.id,
          name: publicEvent.name,
          startsAt: publicEvent.startsAtUtc,
          endsAt: publicEvent.endsAtUtc,
          type: 'public_event',
          pathname: `/events/${publicEvent.id}-${publicEvent.slug}`
        }) as HomepageUpcomingEvent
    )
  )

  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  events = isMobileView ? events.slice(0, 2) : events

  return (
    <div className="flex flex-col gap-4 mb-12">
      <div className="flex flex-row items-center w-full align text-xl font-medium">
        Upcoming events
      </div>

      <div className="flex flex-col gap-4">
        {combinedEvents.map((event, i) => (
          <UpcomingEventCard
            key={event.id}
            event={event}
            userTimezone={userTimezone}
            index={i}
          />
        ))}

        {/* {events.length === 0 &&
          publicEvents.length > 0 &&
          publicEvents.map((event, i) => (
            <UpcomingEventCard
              key={event.id}
              event={
                {
                  id: event.id,
                  name: event.name,
                  startsAt: event.startsAtUtc,
                  endsAt: event.endsAtUtc,
                  type: 'public_event',
                  pathname: `/events/${event.id}-${event.slug}?rsvp=homepage_upcoming_event_card`
                } as HomepageUpcomingEvent
              }
              userTimezone={userTimezone}
              index={i}
            />
          ))} */}
      </div>
    </div>
  )
}

const UpcomingEventCard = ({
  event,
  userTimezone,
  index
}: {
  event: HomepageUpcomingEvent
  userTimezone: string
  index: number
}) => {
  const { currentUser } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()

  const onCtaClick = (e: MouseEvent | KeyboardEvent) => {
    trackNavigationClicked({
      location: currentUser?.is.freeUser ? 'free_user_home' : 'premium_user_home',
      destination: e.currentTarget.getAttribute('href') || '',
      type: 'upcoming_event_card',
      text:
        event.type === 'course_event'
          ? 'view upcoming course event'
          : event.type === 'public_event'
            ? 'attend event'
            : 'view upcoming event',
      related_identifiers:
        event.type === 'course_event'
          ? {
              ccl_local_id: event.id,
              ccl_course_session_id: event.cclCourseSessionId,
              event_title: event.name
            }
          : {
              event_id: event.id,
              event_title: event.name,
              event_kind: 'event'
            }
    })
  }

  useEffect(() => {
    const eventType = event.type === 'course_event' ? 'course_event' : 'event'

    trackServerEvent({
      variables: {
        input: {
          anonymousId: '',
          event: 'impressionViewed',
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            location: currentUser?.is.freeUser ? 'free_user_home' : 'premium_user_home',
            section: 'upcoming_events',
            section_impression_index: index,
            section_index: 0, // This is the first section on the homepage currently
            type: eventType,
            content_type: eventType,
            object_id: eventType === 'event' ? event.id : null,
            ccl_local_id: eventType === 'course_event' ? event.id : null,
            title: event.name,
            is_recommended: event.type === 'public_event'
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Link
      to={event.pathname}
      className="flex flex-row sm:items-center gap-6 hover:no-underline text-rb-black hover:text-rb-black"
    >
      <div className="h-[52px] w-[60px] py-1 bg-rb-blue-50 rounded flex flex-col items-center shrink-0">
        <span className="text-xs leading-[1.5] text-rb-gray-400 uppercase">
          {format(new Date(event.startsAt), 'MMM')}
        </span>
        <span className="text-xl leading-[1.4] font-medium text-rb-gray-400">
          {format(new Date(event.startsAt), 'd')}
        </span>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4 justify-between w-full">
        <div className="flex flex-col gap-1">
          <span className="text-base leading-[1.6] font-medium line-clamp-1 md:line-clamp-2">
            {event.name}
            {event.type === 'public_event' && (
              <span className="font-medium text-rb-blue-100 text-base italic leading-[1.6] sm:ml-4">
                Free
              </span>
            )}
          </span>
          <span className="text-base leading-[1.6] text-rb-gray-300">
            {getFormattedTimeWithTimeZone(event.startsAt, event.endsAt, userTimezone)}
          </span>
        </div>

        <div className="flex gap-8">
          <Button
            variant="text-only"
            className="text-rb-teal-600 p-0 hover:bg-transparent shrink-0 text-base font-medium"
            href={`${event.pathname}${event.type === 'public_event' ? '?rsvp=homepage_upcoming_event_card' : ''}`}
            onClick={onCtaClick}
          >
            {event.type === 'course_event'
              ? 'View course'
              : event.type === 'public_event'
                ? 'Attend'
                : 'View event'}
          </Button>
        </div>
      </div>
    </Link>
  )
}

export default UpcomingEvents
