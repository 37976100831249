import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'

import { cn } from 'utils/tailwind'

interface BannerCta {
  text: string
  url?: string
  icon?: React.ReactNode
  onClick?: () => void
}

interface AnnouncementBannerProps {
  firstCta?: BannerCta
  secondCta?: BannerCta
  ctaIcon?: React.ReactNode
  color: string
  textColor: string
  image?: string
  header: string
  subHeader: string
  isVisible: boolean
}

export const AnnouncementBanner = ({
  firstCta,
  secondCta,
  color,
  textColor,
  image,
  header,
  subHeader,
  isVisible
}: AnnouncementBannerProps) => {
  const history = useHistory()

  const handleClick = (cta: BannerCta) => {
    cta.onClick?.()
    if (cta.url) {
      history.push(cta.url)
    }
  }

  if (!isVisible) return null

  return (
    <div className="@container/AnouncementBanner w-full self-stretch">
      <div
        className={cn(
          'p-6 gap-6 flex flex-col justify-center overflow-hidden rounded-xl',
          '@2xl/AnouncementBanner:px-10 @2xl/AnouncementBanner:min-h-[206px]',
          '@3xl/AnouncementBanner:flex-row @3xl/AnouncementBanner:items-center @3xl/AnouncementBanner:justify-between',
          '@5xl/AnouncementBanner:gap-8 @5xl/AnouncementBanner:px-12'
        )}
        style={{ backgroundColor: color }}
      >
        <div className="hidden @5xl:flex">
          <img alt="" className="h-[145px] w-auto max-w-[145px]" src={image} />
        </div>
        <div className="flex w-full flex-col gap-8 @3xl:flex-1 @3xl:flex-row">
          <div className="flex-1" style={{ color: textColor }}>
            <div className="flex flex-col gap-2">
              <div className="font-polysans text-[28px] leading-[32px] tracking-[-0.56px] !font-light">
                {header}
              </div>
              <div>{subHeader}</div>
            </div>
          </div>
        </div>
        {(firstCta || secondCta) && (
          <div className="flex items-center gap-4">
            {firstCta && (
              <Button
                size="small"
                variant="outline"
                iconBefore={firstCta.icon}
                onClick={() => handleClick(firstCta)}
              >
                <span className="font-semibold">{firstCta.text}</span>
              </Button>
            )}
            {secondCta && (
              <Button
                size="small"
                iconBefore={secondCta.icon}
                onClick={() => handleClick(secondCta)}
              >
                <span className="font-semibold">{secondCta.text}</span>
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
