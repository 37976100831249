import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { CTA_LINK_STYLES } from 'pages/GuidesPage/SwimlaneIndex'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'

import { Loading } from 'components'
import { ReactComponent as IconChevronRight } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-right.svg'
import PageHeader from 'components/PageHeader'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard from 'components/cards/Content/CourseCard'
import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'
import RfCardTitle from 'components/typography/RfCard/RfCardTitle'

import { MIN_WIDTH_TAILWIND_LG, MIN_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import {
  ExploreSubtopic,
  useBookmarkFoldersQuery,
  useExploreMixedSubtopicContentQuery,
  useExploreTopicPageQuery,
  useSavedArtifactsAndGuidesForUserQuery,
  useSavedCoursesForUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import ExploreSwimlane from '../components/ExploreSwimlane'
import MoreTopicsToExplore from '../components/MoreTopicsToExplore'
import { TOPICS } from '../consts'

const PAGE_LOCATION = 'topic index'
const NUM_INIT_SUBTOPICS_TO_SHOW = 4

// TODO: Update the breadcrumb component to get rid of needing the top level "Reforge"
// and to allow more than just one-level breadcrumbs to pass in custom tracking specs.
// Then get rid of all this custom code.

function BreadcrumbLink({
  to,
  onClick,
  children
}: {
  to: string
  onClick: () => void
  children: React.ReactNode
}) {
  return (
    <Link to={to} className="text-black hover:text-black underline" onClick={onClick}>
      {children}
    </Link>
  )
}

function Breadcrumbs({ topic }: { topic: { title?: string | undefined } }) {
  return (
    <div className="flex gap-2">
      <BreadcrumbLink
        to="/explore"
        onClick={() => {
          trackNavigationClicked({
            type: 'hyperlink text',
            text: 'back to explore',
            location: location.pathname,
            destination: '/explore'
          })
        }}
      >
        Explore
      </BreadcrumbLink>
      <IconChevronRight fill={'#080A0A'} width={14.54} height={16} />
      {topic.title}
    </div>
  )
}

function TopicPageHeader({
  topicTitle,
  topicSlug,
  topicId,
  subtopics,
  topicImage,
  isLoggedIn,
  backgroundColor
}: {
  topicTitle: string
  topicSlug: string
  topicId: string
  subtopics: any[]
  topicImage: React.ReactNode
  isLoggedIn: boolean
  backgroundColor: string
}) {
  const [viewAll, setViewAll] = useState(false)
  const isAboveMd = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_MD})`)

  let renderedSubtopics = []

  if (isAboveMd) {
    renderedSubtopics = viewAll
      ? subtopics
      : subtopics.slice(0, NUM_INIT_SUBTOPICS_TO_SHOW)
  } else {
    renderedSubtopics = viewAll ? subtopics : []
  }

  return (
    <div>
      <div
        className="flex p-5 justify-between items-center md:items-start rounded-lg bg-rb-gray-50"
        style={{ backgroundColor: backgroundColor }}
      >
        <div>
          <div className="mt-8">
            <PageHeader title={topicTitle} customClassName={'mb-4 md:mb-4'} />
          </div>
          <div className="flex">
            <div className="flex flex-row gap-2 flex-wrap hidden md:flex">
              {renderedSubtopics.map((subtopic, idx) => {
                const subtopicPath = `/explore/${topicSlug}/${subtopic.slug}`

                return (
                  <div key={`subtopic-${idx}`}>
                    <Link
                      to={subtopicPath}
                      className="flex inline-flex items-center border bg-rb-white border-rb-gray-200 px-2 py-1 justify-center items-center gap-1.5 rounded-md text-rb-gray-400 hover:text-rb-gray-400 hover:no-underline hover:bg-rb-gray text-xs"
                      onClick={() =>
                        trackNavigationClicked({
                          type: 'clickable tag',
                          text: subtopic.title.toLowerCase(),
                          location: location.pathname,
                          location_type: PAGE_LOCATION,
                          location_id: topicId,
                          logged_in: isLoggedIn,
                          destination: subtopicPath,
                          related_identifiers: {
                            destination_ccl_filter_tag_id: subtopic.id,
                            destination_kind: 'subtopic'
                          },
                          section_index: 0,
                          section_impression_index: idx
                        })
                      }
                    >
                      {subtopic.title}
                    </Link>
                  </div>
                )
              })}
              {!viewAll && subtopics.length > NUM_INIT_SUBTOPICS_TO_SHOW && (
                <button
                  className="flex text-rb-gray-300 text-sm items-center hover:text-rb-teal-600 hidden md:flex"
                  onClick={() => setViewAll(true)}
                >
                  view all
                </button>
              )}
              {viewAll && (
                <button
                  className="flex text-rb-gray-300 text-sm items-center hover:text-rb-teal-600"
                  onClick={() => setViewAll(false)}
                >
                  show less
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="flex shrink-0 h-auto pl-8">{topicImage}</div>
      </div>
    </div>
  )
}

function SubtopicSwimlaneContainer({
  pageTopicSlug,
  idx,
  subtopic,
  savedData,
  savedCourseData,
  openAddToBookmarkFolderModal,
  tracking,
  topicId,
  isLoggedIn,
  sectionIndex
}: {
  pageTopicSlug: string
  idx: number
  subtopic: { title: string; slug: string; id: string }
  savedData: any
  savedCourseData: any
  openAddToBookmarkFolderModal: any
  tracking: any
  topicId: string
  isLoggedIn: boolean
  sectionIndex: number
}) {
  const location = useLocation()
  const { data, loading } = useExploreMixedSubtopicContentQuery({
    variables: {
      subtopicSlug: subtopic.slug
    }
  })

  const items = data?.exploreMixedSubtopicContent || []

  const subtopicPath = `/explore/${pageTopicSlug}/${subtopic.slug}`

  return (
    <ExploreSwimlane
      idx={idx}
      loading={loading}
      topicId={topicId}
      subtopicId={subtopic.id}
      subtopicTitle={subtopic.title}
      title={
        <div className="flex">
          <h2 className="!font-sans !font-medium mb-0 max-w-[275px] sm:max-w-full">
            {subtopic.title}
          </h2>
          <Link
            to={subtopicPath}
            className={CTA_LINK_STYLES}
            onClick={() =>
              trackNavigationClicked({
                type: 'hyperlink text',
                text: subtopic.title.toLowerCase(),
                location: location.pathname,
                location_type: PAGE_LOCATION,
                location_id: topicId,
                logged_in: isLoggedIn,
                destination: subtopicPath,
                related_identifiers: {
                  destination_ccl_filter_tag_id: subtopic.id,
                  destination_kind: 'subtopic'
                },
                section_index: sectionIndex
                // intentionally not sending section_impression_index for "view all"
              })
            }
          >
            view all
            <ChevronRightThinIcon className="h-3 fill-current pl-2" />
          </Link>
        </div>
      }
      items={items}
      savedData={savedData}
      savedCourseData={savedCourseData}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      tracking={tracking}
    />
  )
}

function CoursesSwimlaneSection({
  courses,
  topicId,
  topicSlug,
  tracking,
  savedCourseData,
  openAddToBookmarkFolderModal
}: {
  courses: any[]
  topicId: string
  topicSlug: string
  tracking: {
    location: string
    locationId: string
    locationType: string
    section?: string
    sectionIndex?: string
    sectionImpressionIndex?: number
    contentType: string
    relatedIdentifiers: { locationName: string }
  }
  savedCourseData: any
  openAddToBookmarkFolderModal: any
}) {
  const { currentUser, isLoggedIn } = useCurrentUser()

  const isAboveMd = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_MD})`)
  const isAboveLg = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)

  const titleText = 'Courses on this topic'
  const title = <RfCardTitle className="!font-sans !font-medium">{titleText}</RfCardTitle>

  function createSharedCourseCardProps(course: any, idx: number) {
    return {
      bookmarkDropdownPosition: 'top' as const,
      bookmark: savedCourseData?.savedCoursesForUser?.find(
        (bookmark: any) => bookmark.sanityId === course.sourceId
      ),
      pageLocation: tracking.location,
      locationId: topicId,
      locationType: tracking.locationType,
      additionalRelatedIdentifiers: {
        destination_ccl_course_id: course.id,
        destination_name: course.title
      },
      sectionIndex: 0,
      sectionImpressionIndex: idx,
      impressionTrackingProperties: {
        accessPolicyKind: currentUser?.accessPolicyKind,
        locationId: topicId,
        location: PAGE_LOCATION,
        section: titleText,
        sectionIndex: 1,
        sectionImpressionIndex: idx,
        type: tracking.contentType,
        cclEntityId: course.id
      }
    }
  }

  const sharedExploreSwimlaneProps = {
    items: courses,
    idx: 0,
    savedCourseData,
    openAddToBookmarkFolderModal,
    topicId,
    tracking: {
      location: tracking.location,
      locationId: topicId,
      locationType: tracking.locationType,
      section: titleText,
      sectionIndex: 0,
      contentType: tracking.contentType,
      type: tracking.contentType,
      relatedIdentifiers: {
        locationName: tracking.relatedIdentifiers.locationName
      }
    }
  }

  if (courses.length === 1) {
    return (
      <div>
        <div className="mb-0 md:mb-6">{title}</div>

        <div>
          {isAboveMd ? (
            <CourseCard
              variant={CardVariants.Horizontal}
              course={courses[0]}
              {...createSharedCourseCardProps(courses[0], 0)}
            />
          ) : (
            <ExploreSwimlane {...sharedExploreSwimlaneProps} />
          )}
        </div>
      </div>
    )
  }

  if (courses.length === 2) {
    return (
      <div>
        <div className="mb-0 lg:mb-6">{title}</div>

        <div className="flex flex-col lg:flex-row gap-6 ">
          {isAboveLg ? (
            <>
              <CourseCard
                variant={CardVariants.Horizontal}
                course={courses[0]}
                {...createSharedCourseCardProps(courses[0], 0)}
              />
              <CourseCard
                variant={CardVariants.Horizontal}
                course={courses[1]}
                {...createSharedCourseCardProps(courses[1], 1)}
              />
            </>
          ) : (
            <ExploreSwimlane {...sharedExploreSwimlaneProps} />
          )}
        </div>
      </div>
    )
  }

  if (courses.length === 3) {
    return (
      <div>
        <div className="hidden lg:block mb-0 lg:mb-6">{title}</div>

        <div className="flex flex-col lg:flex-row gap-6 ">
          {isAboveLg ? (
            <>
              <CourseCard
                variant={CardVariants.Vertical}
                course={courses[0]}
                {...createSharedCourseCardProps(courses[0], 0)}
              />
              <CourseCard
                variant={CardVariants.Vertical}
                course={courses[1]}
                {...createSharedCourseCardProps(courses[1], 1)}
              />
              <CourseCard
                variant={CardVariants.Vertical}
                course={courses[2]}
                {...createSharedCourseCardProps(courses[2], 2)}
              />
            </>
          ) : (
            <ExploreSwimlane title={title} {...sharedExploreSwimlaneProps} />
          )}
        </div>
      </div>
    )
  }

  if (courses.length === 4) {
    return (
      <div>
        <div className="flex flex-col lg:flex-row gap-6 ">
          <ExploreSwimlane title={title} {...sharedExploreSwimlaneProps} />
        </div>
      </div>
    )
  }

  const viewAllDestination = `/courses?topics=${topicSlug}`

  return (
    <div>
      <ExploreSwimlane
        title={
          <div className="flex">
            {title}
            <Link
              to={viewAllDestination}
              className={CTA_LINK_STYLES}
              onClick={() => {
                trackNavigationClicked({
                  type: 'hyperlink text',
                  text: titleText.toLowerCase(),
                  location: location.pathname,
                  location_type: PAGE_LOCATION,
                  location_id: topicId,
                  logged_in: isLoggedIn,
                  destination: viewAllDestination,
                  section_index: 1
                  // intentionally not sending section_impression_index for "view all"
                })
              }}
            >
              view all
              <ChevronRightThinIcon className="h-3 fill-current pl-2" />
            </Link>
          </div>
        }
        {...sharedExploreSwimlaneProps}
      />
    </div>
  )
}

interface TopicPageProps {
  setPage?: (page: GenericPageType) => void
}

export default function TopicPage({ setPage }: TopicPageProps) {
  useEffect(() => {
    document.getElementById('page')?.scrollTo(0, 0)
  }, [])

  const { topic } = useParams<{ topic: string }>()
  const { currentUser, isLoggedIn } = useCurrentUser()

  const { data, loading } = useExploreTopicPageQuery({
    variables: {
      slug: topic
    }
  })

  const { data: savedData } = useSavedArtifactsAndGuidesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    },
    skip: !isLoggedIn
  })

  const { data: savedCourseData } = useSavedCoursesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    },
    skip: !isLoggedIn
  })

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()

  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })

  const pageTopicSlug = topic
  const pageTopicTitle = data?.exploreSubtopicsOfTopic?.title || ''
  const pageTopicId = data?.exploreSubtopicsOfTopic?.id || ''
  const subtopics = useMemo(() => data?.exploreSubtopicsOfTopic?.subtopics || [], [data])
  const topicConstantData = TOPICS.find((t: any) => t.slug === pageTopicSlug)
  const topicImage = topicConstantData?.icon
  const backgroundColor = topicConstantData?.backgroundColor || '#FCFBFA'

  useEffect(() => {
    const title = `${pageTopicTitle} Courses, Guides, and Templates`
    const desc = `Learn ${subtopics
      .slice(0, 4)
      .map((s: ExploreSubtopic) => s.title)
      .join(', ')}, and more from the top minds in product, growth, and marketing.`

    setPage?.({
      title,
      seo: {
        metaTitle: title,
        shareTitle: title,
        metaDesc: desc,
        shareDesc: desc,
        shareGraphic: {
          asset: topicImage?.props.src
        },
        shareGraphicAlt: `${pageTopicTitle} Courses, Guides, and Templates at Reforge`
      }
    } as GenericPageType)
  }, [pageTopicTitle, setPage, subtopics, topicImage])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex flex-col">
        <div>
          {isLoggedIn && (
            <div className="mb-6">
              <Breadcrumbs
                topic={{
                  title: pageTopicTitle
                }}
              />
            </div>
          )}

          <TopicPageHeader
            isLoggedIn={isLoggedIn}
            subtopics={subtopics}
            topicId={pageTopicId}
            topicImage={topicImage}
            topicTitle={pageTopicTitle}
            topicSlug={pageTopicSlug}
            backgroundColor={backgroundColor}
          />

          <div className="pt-12 flex flex-col gap-8">
            {data?.exploreTopicCourses && data.exploreTopicCourses.length > 0 && (
              <CoursesSwimlaneSection
                topicId={pageTopicId}
                topicSlug={pageTopicSlug}
                courses={data?.exploreTopicCourses ?? []}
                savedCourseData={savedCourseData}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                tracking={{
                  contentType: 'course',
                  locationId: pageTopicId,
                  location: location.pathname,
                  locationType: PAGE_LOCATION,
                  relatedIdentifiers: {
                    locationName: pageTopicTitle
                  }
                }}
              />
            )}

            {subtopics.map((subtopic, idx) => {
              return (
                <SubtopicSwimlaneContainer
                  key={`subtopic-${idx}`}
                  idx={idx}
                  isLoggedIn={isLoggedIn}
                  topicId={pageTopicId}
                  pageTopicSlug={pageTopicSlug}
                  subtopic={subtopic}
                  savedData={savedData}
                  savedCourseData={savedCourseData}
                  sectionIndex={idx + 2} // plus 2 (header + courses)
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  tracking={{
                    contentType: 'subtopic',
                    location: PAGE_LOCATION,
                    locationId: pageTopicId,
                    locationType: PAGE_LOCATION,
                    sectionId: subtopic.id,
                    section: subtopic.title,
                    sectionIndex: idx + 2, // plus 2 (header + courses)
                    relatedIdentifiers: {
                      locationName: pageTopicTitle
                    }
                  }}
                />
              )
            })}
          </div>
        </div>
        <MoreTopicsToExplore
          tracking={{
            locationType: PAGE_LOCATION,
            sectionIndex: subtopics?.length + 1,
            locationId: pageTopicId
          }}
        />
      </div>
      {currentUser && !currentUser?.is?.freeUser && (
        <>
          <AddBookmarkToFolderModal
            isOpen={isAddToBookmarkFolderModalOpen}
            handleClose={closeAddToBookmarkFolderModal}
            bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
            openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdown}
            showCollectionsOnboardingInfo={
              !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
            }
          />
          <CreateBookmarkFolderModal
            isModalOpen={isCreateBookmarkFolderModalOpen}
            handleClose={closeCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
            trackingTriggerAction={'bookmark'}
          />
        </>
      )}
    </>
  )
}
