import { ReactNode, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { ReactComponent as ChevronLeft } from 'images/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'images/chevron-right.svg'

const CAROUSEL_ID = 'carousel'
const SCROLL_LEFT_BUTTON_ID = 'scroll_left_button'
const SCROLL_RIGHT_BUTTON_ID = 'scroll_right_button'

const getCarouselElement = () => document.getElementById(CAROUSEL_ID)

const scrollLeftButtonVisibility = () => {
  const scrollLeftButton = document.getElementById(SCROLL_LEFT_BUTTON_ID)

  getCarouselElement()?.scrollLeft === 0
    ? scrollLeftButton?.classList.add('hidden')
    : scrollLeftButton?.classList.remove('hidden')
}

const scrollRightButtonVisibility = () => {
  const scrollRightButton = document.getElementById(SCROLL_RIGHT_BUTTON_ID)
  const carousel = getCarouselElement()

  if (!carousel || !scrollRightButton) {
    return
  }

  carousel.scrollLeft + carousel.offsetWidth >= carousel.scrollWidth
    ? scrollRightButton.classList.add('hidden')
    : scrollRightButton.classList.remove('hidden')
}

const setScrollButtonVisibility = () => {
  scrollLeftButtonVisibility()
  scrollRightButtonVisibility()
}

const useScrolling = () => {
  useEffect(() => {
    const carousel = document.getElementById('carousel')

    if (!carousel) {
      return
    }
    setScrollButtonVisibility()

    carousel.addEventListener('scroll', setScrollButtonVisibility)
    return () => {
      carousel.removeEventListener('scroll', setScrollButtonVisibility)
    }
  }, [])
}

interface CarouselProps {
  children: ReactNode
  height: number
  itemWidth: number
}

const Carousel = ({ children, height, itemWidth }: CarouselProps) => {
  useScrolling()

  const scrollLeft = () =>
    getCarouselElement()?.scrollBy({
      left: -itemWidth,
      behavior: 'smooth'
    })

  const scrollRight = () =>
    getCarouselElement()?.scrollBy({
      left: itemWidth,
      behavior: 'smooth'
    })

  const scrollButtonStyle = `z-1 h-[${height}px] absolute rounded bg-rb-white py-2 px-4 font-bold opacity-90 hidden`

  return (
    <div className="relative">
      <button
        id={SCROLL_LEFT_BUTTON_ID}
        onClick={scrollLeft}
        className={twMerge(scrollButtonStyle, 'left-0')}
      >
        <ChevronLeft fill="black" width={12} />
      </button>
      <button
        id={SCROLL_RIGHT_BUTTON_ID}
        onClick={scrollRight}
        className={twMerge(scrollButtonStyle, 'right-0')}
      >
        <ChevronRight fill="black" width={12} />
      </button>
      <div className="relative flex overflow-x-auto h-[94px]" id={CAROUSEL_ID}>
        {children}
      </div>
    </div>
  )
}

export default Carousel
