import { cn } from '@/lib/utils'
import React, { useEffect, useRef, useState } from 'react'

import { GoalSessionData } from 'pages/coaching/type'

import Button from 'components/Button'
import { Tag } from 'components/Tag'

import { useGenerateAiCoachingGoalsMutation } from 'gql'

import { useQueryParams } from 'hooks/useQueryParams'

import { onEnterKeyPress } from 'utils/keyboard'
import { displayErrorToast } from 'utils/toastService'
import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'

import { GeneratingIcon } from '../../../../components/icons/GeneratingIcon'
import { SparkleIcon } from '../../../../components/icons/SparkleIcon'
import { TealDocumentIcon } from '../../../../components/icons/TealDocumentIcon'
import PlaceholderImg from './placeholder.png'

type PromptArgs = {
  args: {
    setActiveSession: React.Dispatch<React.SetStateAction<any>>
    allSessions: GoalSessionData[]
  }
}
export const Prompt = ({ args: { setActiveSession, allSessions } }: PromptArgs) => {
  const textAreaRef = useRef(null)
  const [prompt, setPrompt] = useState('')
  const [generatingGoals, setGeneratingGoals] = useState(false)
  const [showSessions, setShowSessions] = useState(false)

  const { setNewParams } = useQueryParams()

  const [generateAiCoachingGoals] = useGenerateAiCoachingGoalsMutation()

  const submitPrompt = async () => {
    setGeneratingGoals(true)
    const { data } = await generateAiCoachingGoals({
      variables: {
        input: {
          prompt
        }
      }
    })

    const results: any = data?.generateAiCoachingGoals

    if (results?.success) {
      setActiveSession({
        id: results.coachingSession?.id,
        data: { ...(results.coachingSession?.data ?? {}) }
      })

      const externalId = results.coachingSession.externalId

      trackCtaClicked({
        cta_type: 'button',
        cta_location: '/coaching',
        text: 'create a plan',
        related_identifiers: {
          coaching_session_external_id: externalId,
          goal_name: prompt
        }
      })

      setNewParams({ previewId: externalId })
    } else {
      displayErrorToast({
        message:
          'Something went wrong while trying to generate the goals please try again.'
      })
    }
    setGeneratingGoals(false)
  }

  const PlaceholderPreview = () => {
    const titles = [
      'Improve user retention',
      'Expand into an adjacent market',
      'Land a new use case'
    ]
    return titles.map((title, index) => {
      return (
        <div className="font-sans" key={index}>
          <div className="font-semibold mb-4 ml-3 text-base">{title}</div>
          <img src={PlaceholderImg} />
        </div>
      )
    })
  }
  const handleToggleSessions = (value: boolean) => {
    setShowSessions(value)
    trackCtaClicked({
      cta_location: '/coaching',
      cta_type: 'hyperlink text',
      text: value === true ? 'show previous session' : 'Hide previous sessions'
    })
  }

  const handleSessionClick = (session: GoalSessionData) => {
    trackNavigationClicked({
      destination: `/coaching?sessionId=${session.externalId}`,
      location: '/coaching',
      location_type: 'coaching_sessions',
      type: 'card',
      text: session.data.goalName
    })

    setNewParams({ sessionId: session.externalId })
  }

  useEffect(() => {
    const textArea: any = textAreaRef.current
    if (textArea) {
      textArea.style.height = 'auto'
      textArea.style.height = `${textArea.scrollHeight}px`
    }
  }, [prompt])

  return (
    <div className="w-full h-full rounded-xl flex flex-col items-center font-sans p-8">
      <div className="max-w-[800px] mb-[100px] flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <Tag text="Beta" className="mb-3 bg-rb-teal-600" />
          <div className="text-4xl font-medium mb-2">
            Smarter strategic planning in seconds
          </div>
          <div className="font-normal text-rb-gray-300">
            Generate step-by-step plans with core deliverables and expert guidance.
          </div>
        </div>

        <div className="px-6 py-3 bg-white border flex items-center border-rb-gray-300 justify-between w-full max-w-[848px] rounded-xl mt-12">
          <div className="mr-1 flex-1 flex items-center">
            {generatingGoals && (
              <div className="flex">
                <GeneratingIcon className="animate-spin mr-1.5" />
                <span className="font-sans font-normal text-rb-black">
                  Gathering strategic advice...
                </span>
              </div>
            )}

            {!generatingGoals && (
              <textarea
                ref={textAreaRef}
                name="prompt"
                onChange={(e) => setPrompt(e.target.value)}
                rows={1}
                className={cn(
                  'border-0 min-h-6 rounded-lg block text-rb-gray-400 text-sm font-medium px-2 py-1 w-full resize-none',
                  'focus:bg-transparent active:bg-transparent focus:outline focus:outline-none active:outline active:outline-none'
                )}
                onKeyUp={onEnterKeyPress(submitPrompt)}
                placeholder="Describe your top priority this month"
              />
            )}
          </div>

          <Button size="small" disabled={!prompt?.length} onClick={submitPrompt}>
            {generatingGoals ? 'Cancel' : 'Create a plan'}
          </Button>
        </div>
        {!!allSessions.length && (
          <Button
            onClick={() => handleToggleSessions(!showSessions)}
            disabled={generatingGoals}
            className="text-left hover:bg-transparent hover:underline hover:decoration-rb-teal-300 hover:decoration-2 hover:underline-offset-4"
            variant="text-only"
          >
            {showSessions ? 'Hide previous sessions' : 'Show previous sessions'}
          </Button>
        )}
      </div>

      {showSessions ? (
        <div className="grid grid-flow-row tl:grid-cols-2 xl:grid-cols-3 gap-6 w-full">
          {allSessions.map((session, index) => {
            if (!session.data?.steps?.length) {
              return null
            }
            return (
              <div
                key={index}
                onClick={() => {
                  handleSessionClick(session)
                }}
                className="w-full p-6 mb-3 flex justify-between items-center bg-white border-2 border-rb-gray-100 rounded-xl cursor-pointer"
              >
                <div className="flex flex-col gap-3.5">
                  <div className="text-rb-black font-semibold mb-1 truncate pb-1 tl:w-[270px] xl:w-[290px] ">
                    {session.data.goalName}
                  </div>
                  <div className="text-rb-teal-600 font-normal text-sm flex items-center">
                    <SparkleIcon className="mr-2" />
                    {session.data.steps.length} recommended steps
                  </div>

                  <div className="text-rb-teal-600 font-normal text-sm flex items-center">
                    <TealDocumentIcon className="mr-2" />
                    {session.data.steps.length} deliverables to draft
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="flex items-center justify-evenly gap-8 max-w-[1000px]">
          {PlaceholderPreview()}
        </div>
      )}
    </div>
  )
}
