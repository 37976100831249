import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import CourseOnDemandContentBlock from 'domains/CourseOnDemand/CourseOnDemandContentBlock'

import { Loading } from 'components'

import {
  AiDocument,
  useAiDocumentsQuery,
  useCourseOnDemandDetailQuery,
  useCreateUserCmsActivityMutation,
  useTrackServerEventMutation
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import {
  CONTENT_MODE_ON_DEMAND,
  CourseRelatedIdentifierProperties,
  useContentMode
} from 'utils/contentTrackingUtils'
import { getAnonymousId } from 'utils/tracking/segment'

export interface CourseOnDemandDashboardPageProps {
  slug: string
}

const CourseOnDemandPageDashboard = ({ slug }: CourseOnDemandDashboardPageProps) => {
  const [createUserCmsActivity] = useCreateUserCmsActivityMutation()
  const [trackServerEvent] = useTrackServerEventMutation()
  const currentUser = useAssertCurrentUser()

  const contentMode = useContentMode()
  const { loading, data } = useCourseOnDemandDetailQuery({
    variables: { slug }
  })

  const { data: aiDocumentsData } = useAiDocumentsQuery({
    variables: { userId: currentUser?.id },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (!data?.course) {
      return
    }

    trackServerEvent({
      variables: {
        input: {
          event: 'Course Dashboard Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            user_id: currentUser?.id,
            path: window.location.pathname,
            access_policy_kind: currentUser?.accessPolicyKind,
            content_type: 'course',
            ccl_course_id: data?.cclCourse?.id,
            ccl_course_static_id: data?.cclCourse?.staticId,
            course_id: data.course.id,
            is_legacy_version_available: !!data.course.cmsProgram,
            course_title: data.course.title,
            sanity_course_id: data.course.sanityId,
            is_on_demand: true,
            related_identifiers: {
              cms_program_id: data.course.cmsProgram?.id,
              content_mode: CONTENT_MODE_ON_DEMAND
            },
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.course?.id])

  useEffect(() => {
    if (data?.cclCourse?.staticId) {
      createUserCmsActivity({
        variables: {
          input: {
            cclStaticCourseId: data.cclCourse.staticId,
            action: 'viewed',
            contentType: 'Course'
          }
        }
      })
    }
  }, [data?.cclCourse?.staticId, createUserCmsActivity])

  if (loading) return <Loading />

  if (!data?.course) {
    return <Redirect to="/" />
  }

  const filterCourseBlocks = () => {
    if (!data?.course?.courseBlocks || !data?.cclCourse?.blocks) {
      return []
    }
    return data?.course.courseBlocks.filter((courseBlock, index) => {
      const cclBlock = data?.cclCourse?.blocks?.[index]
      return (
        courseBlock.title !== 'Kickoff' &&
        (courseBlock.artifacts?.length ||
          courseBlock.units?.length ||
          cclBlock?.contentBlocks?.length)
      )
    })
  }

  const filterCclCourseBlocks = () => {
    if (!data?.cclCourse?.blocks) {
      return []
    }

    return data.cclCourse.blocks.filter((block) => {
      return (
        block.title !== 'Kickoff' &&
        (block.artifacts?.length || block.guides?.length || block.contentBlocks?.length)
      )
    })
  }

  const filteredCourseBlocks = filterCourseBlocks()
  const filteredCclCourseBlocks = filterCclCourseBlocks()

  const { course, cclCourse } = data

  const trackingRelatedIdentifiers: CourseRelatedIdentifierProperties = {
    course_id: course?.id,
    ccl_course_id: cclCourse?.id,
    content_mode: contentMode,
    course_title: cclCourse?.title
  }

  return (
    <div className="flex flex-col items-center px-4 pb-32 sm:px-7">
      <div className="flex w-full max-w-[820px] flex-col items-center lg:items-center lg:gap-4">
        <div className="flex flex-1 flex-col lg:flex-row">
          <div className="flex w-full max-w-[600px] flex-1 flex-col items-center justify-center gap-9 lg:py-9 lg:pb-56">
            {filteredCourseBlocks?.map((courseBlock, index) => {
              if (filteredCclCourseBlocks) {
                return (
                  <div key={courseBlock.id} className="w-screen sm:w-full">
                    <CourseOnDemandContentBlock
                      index={index}
                      aiDocuments={(aiDocumentsData?.aiDocuments || []) as AiDocument[]}
                      courseBlock={courseBlock}
                      cclCourseBlock={filteredCclCourseBlocks[index]}
                      course={course}
                      courseRelatedIdentifiers={trackingRelatedIdentifiers}
                    />
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseOnDemandPageDashboard
