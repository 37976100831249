import { cn } from '@/lib/utils'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Button from 'components/Button'
import { FacePile } from 'components/FacePile'

import { CoachingSession, useGenerateAiCoachingGoalsMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useQueryParams } from 'hooks/useQueryParams'

import {
  trackAiChatRetryClicked,
  trackCtaClicked,
  trackNavigationClicked
} from 'utils/tracking/analytics'

import { ArrowRightIcon } from '../../../components/icons/ArrowRightIcon'
import { GeneratingIcon } from '../../../components/icons/GeneratingIcon'
import { SparkleIcon } from '../../../components/icons/SparkleIcon'
import { SyncronizeIcon } from '../../../components/icons/Syncronize'
import { TealDocumentIcon } from '../../../components/icons/TealDocumentIcon'
import { GoalSessionData } from '../type'

type SuggestedGoalsProp = {
  args: {
    activeSession?: GoalSessionData
    allSessions: GoalSessionData[]
    refetchSessions: () => Promise<any>
    setActiveSession?: React.Dispatch<React.SetStateAction<CoachingSession>>
  }
}

export const SuggestedGoals = ({
  args: { activeSession, refetchSessions }
}: SuggestedGoalsProp) => {
  const steps = activeSession?.data?.steps?.length || 0
  const authors = activeSession?.data?.authors
  const suggestedGoal = activeSession?.data?.goalName
  const { setNewParams } = useQueryParams()
  const [retrying, setRetrying] = useState(false)
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()

  const [generateAiCoachingGoals] = useGenerateAiCoachingGoalsMutation()

  const handleCtaClick = (text: string) => {
    trackNavigationClicked({
      destination: '/coaching',
      location: 'coaching__preview',
      location_id: activeSession!.externalId,
      location_type: 'coaching_sessions',
      type: 'button',
      text
    })
    setNewParams({ sessionId: activeSession!.externalId })
  }

  const handleButtonClick = () => {
    trackCtaClicked({
      cta_location: 'coaching__preview',
      cta_type: 'button',
      text: 'continue'
    })
    setNewParams({ sessionId: activeSession!.externalId })
  }

  const handleRetry = async () => {
    setRetrying(true)
    await generateAiCoachingGoals({
      variables: {
        input: {
          sessionId: activeSession?.id
        }
      }
    })
    await refetchSessions()
    setRetrying(false)
    trackAiChatRetryClicked({
      mode: 'coaching',
      path: pathname,
      chat_session_id: '',
      access_policy_kind: currentUser?.accessPolicyKind
    })
  }

  useEffect(() => {
    refetchSessions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="font-sans rounded-xl h-full p-6 pt-12 w-full max-w-[800px]">
      <div className="h-full w-full flex flex-col items-center justify-start">
        <div className="flex flex-col items-start justify-start w-full">
          <div className="pb-2 font-medium text-rb-gray-300 text-xl">Suggested goal</div>
          <div className="font-medium text-rb-black text-[28px] ">{suggestedGoal}</div>

          <Button
            size="small"
            className="bg-white font-semibold border-rb-gray-100 rounded-full h-8 mt-6"
            variant="outline"
            iconBefore={
              <SyncronizeIcon className={cn(retrying && 'animate-spin-mirror')} />
            }
            onClick={handleRetry}
            disabled={retrying}
          >
            {retrying ? 'Adjusting plan...' : 'Retry'}
          </Button>
        </div>

        {retrying && (
          <div className="flex h-full items-center justify-center">
            <GeneratingIcon className="animate-spin mr-1.5" />
            <span className="font-sans font-normal text-rb-black">
              Gathering strategic advice...
            </span>
          </div>
        )}

        {!retrying && (
          <>
            <div className="flex flex-col items-center justify-between w-full py-6 my-4">
              <div
                className="p-6 mb-3 flex justify-between items-center w-full bg-white border-2 border-rb-gray-100 rounded-xl cursor-pointer"
                onClick={() => handleCtaClick('view plan')}
              >
                <div className="flex flex-col ">
                  <div className="text-rb-black font-semibold mb-1">
                    See a step-by-step plan for executing your strategic work
                  </div>
                  <div className="text-rb-teal-600 font-normal text-sm flex items-center">
                    <SparkleIcon className="mr-2" />
                    {steps} recommended steps
                  </div>
                </div>
                <div className="flex flex-col">
                  <ArrowRightIcon />
                </div>
              </div>

              <div
                className="p-6 mb-3 flex justify-between items-center w-full bg-white border-2 border-rb-gray-100 rounded-xl cursor-pointer"
                onClick={() => handleCtaClick('draft deliverables')}
              >
                <div className="flex flex-col ">
                  <div className="text-rb-black font-semibold mb-1">
                    Quickly draft the core deliverables you need
                  </div>
                  <div className="text-rb-teal-600 font-normal text-sm flex items-center">
                    <TealDocumentIcon className="mr-2" />
                    {steps} deliverables to draft
                  </div>
                </div>
                <div className="flex flex-col">
                  <ArrowRightIcon />
                </div>
              </div>

              {!!authors?.length && (
                <div
                  className="p-6 mb-3 flex justify-between items-center w-full bg-white border-2 border-rb-gray-100 rounded-xl cursor-pointer"
                  onClick={() => handleCtaClick('view expert sources')}
                >
                  <div className="flex flex-col ">
                    <div className="text-rb-black font-semibold mb-1">
                      Let Reforge coach you through every step and deliverable
                    </div>
                    <div className="text-rb-teal-600 font-normal text-sm flex items-center">
                      <FacePile
                        imageSize="medium"
                        showExtraCount={false}
                        limit={3}
                        users={authors as any}
                      />
                      <span className="ml-2">{authors?.length} experts sourced</span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <ArrowRightIcon />
                  </div>
                </div>
              )}
            </div>

            <Button
              onClick={handleButtonClick}
              size="large"
              color="teal"
              className="w-[201px] text-white font-semibold"
            >
              Continue
            </Button>
          </>
        )}
      </div>
      <div className="h-36"></div>
    </div>
  )
}
