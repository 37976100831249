import { PropsWithChildren, useCallback, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useRouteMatch } from 'react-router-dom'

import { GlobalChat } from 'domains/Ai/Chat'
import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

import LeftSidebar, { LeftSidebarProvider } from 'components/LeftSidebar'

import { MIN_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeft } from 'images/chevron-left.svg'

import { OptInMonsterGlobalCampaign } from './OptInMonsterGlobalCampaign'
import { PageProvider } from './PageHeader/usePage'
import { SIDE_PANEL_WIDTH, SLIDE_TRANSITION_TIME, SidePanel } from './SidePanel'
import TopNavBar from './TopNavBar'
import TopBanner from './banners/TopBanner/TopBanner'

const Layout = ({
  pageTitle,
  children,
  showHeader = true,
  showSideBar = true,
  headerDataTest
}: PropsWithChildren<{
  pageTitle?: string
  showHeader?: boolean
  showSideBar?: boolean
  headerDataTest?: string
}>) => {
  const lg = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG}px)`)
  const bannerRef = useRef<HTMLElement>(null)
  const isProfilePage = useRouteMatch('/profiles/:slug')
  const { isSidePanelChatOpen, isSidePanelChatOpenDelayed } = useGlobalChat()

  const getTabTitle = (pageTitle?: string) =>
    pageTitle ? `Reforge - ${pageTitle}` : 'Reforge'
  const getPageTitle = (pageTitle?: string) => {
    if (pageTitle === 'Manage Plan') {
      return (
        <Link
          to="/billing"
          className="text-xl text-rb-black hover:text-rb-black hover:no-underline"
        >
          <ChevronLeft className="mb-1 mr-4 inline-block w-2.5 fill-current" />
          Back to Billing
        </Link>
      )
    }
    return pageTitle
  }

  const pageContainerHeight = useCallback(() => {
    let amount = 0 // px

    if (showHeader) {
      amount += 56
      amount += bannerRef.current?.offsetHeight ?? 0
      if (lg) {
        amount += 16
      } else {
        amount += 24
      }
    }

    return `calc(100vh - ${amount}px)`
  }, [lg, showHeader])

  return (
    <>
      <Helmet>
        <title>{getTabTitle(pageTitle)}</title>
      </Helmet>
      <div className="h-screen w-screen">
        <LeftSidebarProvider>
          <span ref={bannerRef}>{showHeader && <TopBanner />}</span>

          <div className="relative">
            {showHeader && <TopNavBar />}
            <div
              className={cn('flex w-full')}
              style={{
                height: pageContainerHeight()
              }}
            >
              {showSideBar && (
                <LeftSidebar dataTest="left-sidebar" height={pageContainerHeight()} />
              )}

              <div className={cn('w-full', showSideBar && 'lg:pl-[88px] 2xl:pl-0')}>
                <main
                  id="page"
                  className={cn(
                    'flex-1 overflow-y-auto overflow-x-clip scroll-smooth',
                    isProfilePage && 'md:overflow-y-hidden'
                  )}
                  style={{
                    height: pageContainerHeight()
                  }}
                >
                  <div
                    id="page-content"
                    style={{
                      transitionDuration: `${SLIDE_TRANSITION_TIME}ms`,
                      gridTemplateColumns: isSidePanelChatOpen
                        ? `auto ${SIDE_PANEL_WIDTH + 32}px`
                        : 'auto 0px'
                    }}
                    className={cn(
                      'grid transition-all',
                      showSideBar &&
                        'mx-auto w-full max-w-7xl p-4 pt-0 overflow-x-clip md:p-6 md:pt-0 lg:p-8 lg:pt-0'
                    )}
                  >
                    <div className="min-w-0 pt-4 md:pt-6 lg:pt-1">
                      <PageProvider
                        showHeader={showHeader}
                        pageTitle={getPageTitle(pageTitle)}
                        dataTest={headerDataTest}
                      >
                        {children}
                      </PageProvider>
                    </div>
                    {isSidePanelChatOpenDelayed && (
                      <div
                        className={cn('sticky top-0 overflow-[hidden_visible]')}
                        style={{
                          height: pageContainerHeight()
                        }}
                      >
                        <SidePanel>
                          <GlobalChat renderStyle="side_panel" />
                        </SidePanel>
                      </div>
                    )}
                  </div>
                </main>
              </div>
            </div>
          </div>
        </LeftSidebarProvider>
      </div>
      <OptInMonsterGlobalCampaign />
    </>
  )
}

export default Layout
