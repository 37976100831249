import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react'

import { AudioPlayerTrackingMetadata } from 'components/AudioPlayer/useTrackAudioPlayer'

export interface ListeningModeContextProviderInitialState {
  isOpen?: boolean
}

interface ListeningModeContextTrackingMetadata extends AudioPlayerTrackingMetadata {
  ctaLocation: string
}

interface ListeningModeContextProviderProps {
  children: ReactNode
  initialState?: ListeningModeContextProviderInitialState
  initialTrackingMetadata: ListeningModeContextTrackingMetadata
}

export interface ListeningModeContextType {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  currentTime: number
  setCurrentTime: Dispatch<SetStateAction<number>>
  trackEnded: boolean
  setTrackEnded: Dispatch<SetStateAction<boolean>>
  autoAdvancing: boolean
  setAutoAdvancing: Dispatch<SetStateAction<boolean>>
  close: () => void
  open: () => void
  toggleOpen: () => void
  trackingMetadata: ListeningModeContextTrackingMetadata
}

export const ListeningModeContext = createContext<ListeningModeContextType>({
  isOpen: false,
  setIsOpen: () => {},
  currentTime: 0,
  setCurrentTime: () => {},
  trackEnded: false,
  setTrackEnded: () => {},
  autoAdvancing: false,
  setAutoAdvancing: () => {},
  close: () => {},
  open: () => {},
  toggleOpen: () => {},
  trackingMetadata: {} as ListeningModeContextTrackingMetadata
})

export function useListeningModeContext() {
  return useContext(ListeningModeContext)
}

export const ListeningModeContextProvider = ({
  children,
  initialState,
  initialTrackingMetadata
}: ListeningModeContextProviderProps) => {
  const [isOpen, setIsOpen] = useState(initialState?.isOpen || false)
  // currentTime and setCurrentTime is used to keep track of the AudioPlayer track progress externally
  // In practice this is needed for Listening Mode because we want to continue from where we left off
  // if we close the player and open it again.  It will eventually be used to control or use the currentTime
  // outside of the AudioPlayer
  const [currentTime, setCurrentTime] = useState(0)
  const [trackEnded, setTrackEnded] = useState(false)
  const [autoAdvancing, setAutoAdvancing] = useState(false)

  const close = () => {
    setIsOpen(false)
  }

  const open = () => {
    setIsOpen(true)
  }

  const toggleOpen = () => {
    isOpen ? close() : open()
  }

  return (
    <ListeningModeContext.Provider
      value={{
        isOpen,
        setIsOpen,
        currentTime,
        setCurrentTime,
        trackEnded,
        setTrackEnded,
        autoAdvancing,
        setAutoAdvancing,
        close,
        open,
        toggleOpen,
        trackingMetadata: initialTrackingMetadata
      }}
    >
      {children}
    </ListeningModeContext.Provider>
  )
}

export default ListeningModeContextProvider
