import cx from 'classnames'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'
import { slugify } from 'domains/Sanity/lib/helpers'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import TableOfContents from 'domains/Sanity/marketingSite/TableOfContents'
import RelatedBlogPostsCarouselSection from 'domains/Seo/RelatedBlogPostsCarouselSection'

import { Loading } from 'components'

import { BlogPost, useBlogDetailsQuery, useRelatedBlogPostsQuery } from 'gql'

import Logo from 'images/reforge-logo-black.svg'

const BlogPostPage = () => {
  const { slug } = useParams<{ slug: string }>()

  const { data, loading } = useBlogDetailsQuery({
    variables: {
      slug: slug[slug.length - 1] === '/' ? slug.slice(0, -1) : slug
    }
  })
  const page = data?.blogPost

  const subtopicIds = page?.blogPost.subtopicIds
  const { data: relatedBlogPostData } = useRelatedBlogPostsQuery({
    variables: {
      subtopicIds: subtopicIds || []
    },
    skip: !subtopicIds
  })

  return (
    <MarketingLayout page={page || {}}>
      {loading ? (
        <Loading />
      ) : page ? (
        <BlogPostContent
          blogPost={page.blogPost}
          relatedBlogPosts={relatedBlogPostData?.relatedBlogPosts || []}
        />
      ) : (
        <Content404 />
      )}
    </MarketingLayout>
  )
}

export interface BlogPostContentProps {
  blogPost: BlogPost
  relatedBlogPosts: BlogPost[]
}

export const BlogPostContent = ({ blogPost, relatedBlogPosts }: BlogPostContentProps) => {
  const ldJson: Record<string, unknown> = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    'mainEntityOfPage': {
      '@type': 'WebPage',
      '@id': `${window.location.origin}${window.location.pathname}`
    },
    'headline': blogPost.title,
    'image': blogPost.summaryImage?.imageUrl,
    'publisher': {
      '@type': 'Organization',
      'name': 'Reforge',
      'logo': {
        '@type': 'ImageObject',
        'url': Logo
      }
    }
  }

  if ((blogPost.authors || [])?.length > 0) {
    ldJson.author = blogPost.authors?.map((author) => ({
      '@type': 'Person',
      'name': author.name,
      'url': `https://www.reforge.com/experts/${author.slug}`
    }))
  } else {
    ldJson.author = {
      '@type': 'Organization',
      'name': 'Reforge',
      'sameAs': 'https://www.reforge.com/'
    }
  }

  const authorsLength = (blogPost.authors || []).length

  return (
    <>
      <Helmet>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        ></script>
      </Helmet>

      <article className="relative flex flex-col items-center px-[16px] py-[6vw] md:px-[4vw] md:py-[4vw]">
        <div className="w-full sm:max-w-[1024px]">
          <header className="mb-[50px] border-b border-rb-black pb-[40px] md:mb-[110px]">
            <nav className="mb-[20px] flex items-center md:mb-[32px]">
              {blogPost.categories?.map((category, i) => (
                <Fragment key={category.id}>
                  <Link
                    to={`/blog/category/${slugify(category.value)}`}
                    className="text-base text-rb-black no-underline hover:no-underline"
                  >
                    {category.name}{' '}
                  </Link>
                  {i < (blogPost.categories || []).length - 1 && (
                    <span className="mx-[8px] pb-[2px] text-sm">&bull;</span>
                  )}
                </Fragment>
              ))}
            </nav>

            <h1 className="font-normal tracking-tighter fluid-text-7xl">
              {blogPost.title}
            </h1>
          </header>

          <div className="flex flex-col-reverse md:flex-row-reverse">
            <section
              className={twMerge(
                'w-full md:w-3/4 md:pl-[16px]',
                blogPost.tableOfContents?.length === 0 && 'md:w-full md:pl-0'
              )}
            >
              {blogPost.overview?.length > 0 && (
                <section className="block-content mb-[20px] [&>h3:last-child]:mb-0 [&>h2:last-child]:mb-0 [&_h2]:!font-light [&_h3_.font-semibold]:!font-light">
                  <ComplexPortableText content={blogPost.overview} />
                </section>
              )}

              {authorsLength > 0 && (
                <section>
                  <div className="-ml-[16px] mb-[80px] flex w-[calc(100%+2rem)] flex-col items-stretch lg:flex-row lg:flex-wrap">
                    {blogPost.authors?.map((author) => (
                      <div
                        className={cx(
                          'flex grow-0 flex-col p-[16px] sm:mb-[16px] sm:flex-row lg:mb-0',
                          {
                            'lg:w-1/3 lg:flex-col': authorsLength >= 3,
                            'max-w-[480px] lg:w-1/2 lg:flex-col': authorsLength === 2,
                            'lg:w-full': authorsLength === 1
                          }
                        )}
                        key={author.id}
                      >
                        <figure
                          className={cx(
                            'relative mb-[12px] aspect-1 w-full max-w-[300px] shrink-0 grow-0 self-start sm:mr-[24px] sm:w-1/2',
                            {
                              'max-w-none lg:mr-0 lg:w-full': authorsLength > 1
                            }
                          )}
                        >
                          <Image
                            src={author.avatarPhoto?.imageUrl || ''}
                            alt={author.avatarPhoto?.imageAlt || author.name || ''}
                            fill
                            className="object-cover object-center"
                          />
                        </figure>
                        <figcaption>
                          <h4 className="mb-[16px] font-normal tracking-tight fluid-text-xl [word-break:break-word]">
                            {author.name}
                          </h4>
                          <p className="mb-[16px] text-base leading-[1.4] [word-break:break-word]">
                            {author.shortBio}
                          </p>
                          <ButtonMarketingCTA
                            rel="author"
                            size="small"
                            href={`/profiles/${author.slug}`}
                          >
                            Learn More
                          </ButtonMarketingCTA>
                        </figcaption>
                      </div>
                    ))}
                  </div>
                </section>
              )}

              <section className="block-content [&_h2]:!font-light [&_h3_.font-semibold]:!font-light">
                <ComplexPortableText content={blogPost.contentBlock} />
              </section>
            </section>

            {blogPost.tableOfContents?.length > 0 && (
              <aside className="w-full md:w-1/4 md:pr-[16px]">
                <TableOfContents data={blogPost.tableOfContents} />
              </aside>
            )}
          </div>
        </div>

        <section className="my-[30px] mx-auto w-full md:max-w-[1024px]">
          {blogPost.authors?.map((author, i) => (
            <Fragment key={author.id}>
              <Link
                to={`/profiles/${author.slug}`}
                className="text-base text-rb-black no-underline hover:no-underline"
              >
                {author.name}
              </Link>
              {i < authorsLength - 1 && (
                <span className="mx-[8px] pb-[2px] text-sm">&bull;</span>
              )}
            </Fragment>
          ))}
        </section>

        {relatedBlogPosts.length > 0 && (
          <section className="py-8 mx-auto w-full md:max-w-[1024px]">
            <RelatedBlogPostsCarouselSection
              blogPosts={relatedBlogPosts}
              providerClassName="mb-0"
            />
          </section>
        )}
      </article>
    </>
  )
}

export default BlogPostPage
