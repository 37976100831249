import { useRef, useState } from 'react'

import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useQueryParams } from 'hooks/useQueryParams'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

import { PremadeGoals } from '../prompt/PremadeGoal'

export const AddOrChooseLearningGoal = () => {
  const textAreaRef = useRef(null)
  const [prompt, setPrompt] = useState('')
  const { currentUser } = useCurrentUser()
  const { aiLearningGoalsDefaultRecommendations } = useFeatureFlags()
  const { setNewParams } = useQueryParams()

  const submitPrompt = async () => {
    localStorage.setItem('goal-prompt', prompt)
    setNewParams({ step: 'tell-us-more' })
  }
  const handlePremadeGoalSelection = async ({ prompt }: any) => {
    localStorage.setItem('goal-prompt', prompt)
    setNewParams({ step: 'tell-us-more' })
  }
  return (
    <div className="flex justify-center w-full">
      <div className="max-w-2xl">
        <div className="flex flex-col items-left bg-white p-8 pl-0 rounded-lg">
          <div className="text-[28px] font-sans font-medium mb-2">
            Who do you want to set a goal for?
          </div>
          <div className="font-normal font-sans  text-rb-gray-300 mb-4">
            {"Select people or groups to assign learning. Next, you'll add your goal"}
          </div>
        </div>

        <div className="w-full rounded-xl flex flex-col">
          <div className="px-6 py-3 border bg-white flex items-center border-rb-gray-100 justify-between w-full rounded-xl">
            <div className="mr-1 flex-1 flex items-center">
              <textarea
                ref={textAreaRef}
                name="prompt"
                onChange={(e) => setPrompt(e.target.value)}
                rows={1}
                value={prompt}
                className={cn(
                  'border-0 min-h-6 rounded-lg block text-rb-gray-400 text-sm font-medium px-2 py-1 w-full resize-none',
                  'focus:bg-transparent active:bg-transparent focus:outline focus:outline-none active:outline active:outline-none'
                )}
                onKeyUp={onEnterKeyPress(submitPrompt)}
                placeholder="Add development goal"
              />
            </div>

            <Button size="small" disabled={!prompt?.length} onClick={submitPrompt}>
              Add goal
            </Button>
          </div>
        </div>
        <div className="font-sans font-semibold text-rb-black my-6">
          Recommended for you
        </div>

        <PremadeGoals
          args={{
            columns: true,
            aiLearningGoalsDefaultRecommendations,
            handlePremadeGoalSelection,
            currentUser
          }}
        />
      </div>
    </div>
  )
}
