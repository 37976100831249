import React from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { DISCOUNTED_COHORT_PASS_PRICE } from 'pages/PurchaseFlow/contexts/PurchaseFlowContext'

import { PlanOptionFields } from 'constants/plans'

import { CohortPassPriceTier, PlanName } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { formatMoney } from 'utils/moneyUtils'
import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { NewUserPlanOptionCard } from './NewUserPlanOptionCard'
import PricingPlanCard from './PricingPlanCard'
import { PlanOptionCardNamespace, getPrice, useBuildPlanOptionsData } from './helpers'

export interface NewUserPlanOptionsProps {
  isSubscriptionOwner: boolean
  plansForSale: PlanOptionCardNamespace.PlanForSale[]
  cohortPassPriceTiers: CohortPassPriceTier[]
  onPlanSelected: (plan?: PlanName) => void
}

export const NewUserPlanOptions = ({
  isSubscriptionOwner,
  plansForSale,
  cohortPassPriceTiers,
  onPlanSelected
}: NewUserPlanOptionsProps) => {
  const { premiumUpdatePaymentFlow, removeTeamsSelfServe } = useFeatureFlags()
  const history = useHistory()
  const planOptionsData = useBuildPlanOptionsData({ plansForSale })

  const handleCtaClick = (plan: PlanOptionFields) => {
    const ctaLocation = 'select_plans_page'
    const ctaLabel =
      plan.name === PlanName.ENTERPRISE ||
      (plan.name !== PlanName.INDIVIDUAL && removeTeamsSelfServe)
        ? 'Get in Touch'
        : `Select ${titleize(plan.name)}`
    if (
      plan.name === PlanName.ENTERPRISE ||
      (plan.name !== PlanName.INDIVIDUAL && removeTeamsSelfServe)
    ) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: ctaLocation,
        text: ctaLabel,
        destination: 'new_team_interest_form'
      })
      return history.push('/teams/get-in-touch')
    }

    trackCtaClicked({
      cta_type: 'button',
      cta_location: ctaLocation,
      text: ctaLabel
    })

    onPlanSelected(plan.name)
  }

  return (
    <div className="lg:overflow-x-auto lg:overflow-y-hidden">
      <div className={twMerge('mt-4 flex w-full flex-wrap gap-2 xl:flex-nowrap')}>
        {planOptionsData.map((option, i) => {
          return (
            <React.Fragment key={i}>
              {premiumUpdatePaymentFlow ? (
                <PricingPlanCard
                  features={option.features}
                  planName={option.name}
                  prevPlanName={i === 0 ? null : planOptionsData[i - 1].name}
                  showCta={isSubscriptionOwner}
                  price={getPrice(option, cohortPassPriceTiers[i], removeTeamsSelfServe)}
                  firstSubheader={option.subtitle}
                  secondSubheader={option.secondSubtitle}
                  ctaLabel={
                    option.name === PlanName.INDIVIDUAL
                      ? `Select ${titleize(option.name)}`
                      : 'Get in Touch'
                  }
                  onCtaClick={() => handleCtaClick(option)}
                  footnote={option.footnote}
                />
              ) : (
                <NewUserPlanOptionCard
                  cardPlanName={option.name}
                  isSubscriptionOwner={isSubscriptionOwner}
                  title={option.title}
                  price={option.price}
                  subtitle={getSubtitle({ option, plansForSale })}
                  content={option.content}
                  headerBarBgClass={option.headerBarBgClass}
                  onPlanSelected={() => onPlanSelected(option.name)}
                />
              )}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

// helpers

export const getSubtitle = ({
  option,
  plansForSale
}: {
  option: PlanOptionFields
  plansForSale: PlanOptionCardNamespace.PlanForSale[]
}) => {
  const maxFlatTierSeatCount = Math.max(...plansForSale.map((plan) => plan.maxSeatCount))

  if (option.name === PlanName.ENTERPRISE) {
    return `${maxFlatTierSeatCount}+ seats`
  }

  if (option.name === PlanName.INDIVIDUAL) {
    return (
      <>
        <span className="text-base">+ {formatMoney(DISCOUNTED_COHORT_PASS_PRICE)}</span>
        <span className="text-xs font-medium text-rb-gray-300">
          {' '}
          for 1 Live Course Pass
        </span>
        <span className="mt-1 block text-sm font-normal text-rb-gray-300">1 seat</span>
      </>
    )
  }

  const optionMaxSeatCount = plansForSale.find(
    (plan) => plan.name === option.name
  )?.maxSeatCount

  if (!optionMaxSeatCount) {
    console.error(
      'Error: Missing a plan for sale or plan option. Make sure that plan names all match.'
    )
    return ''
  }

  return `${optionMaxSeatCount} seats`
}
