import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import ContentGate from 'components/ContentGate'

import { CurrentUserQuery, Maybe, useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'
import { getAnonymousId } from 'utils/tracking/segment'

import { ReactComponent as DiamondIcon } from 'images/icon--diamond-premium.svg'

import CmsSectionDescription from './CmsSectionDescription'

const BlurredContent = () => (
  <div className="select-none blur-[10px]">
    <h1>
      Are you really looking at the source code right now? Shame on you. I&apos;ve copied
      the content from https://www.reforge.com/growth-series here. :P
    </h1>
    <h2>What the Growth Series Experience Looks Like</h2>
    <h4>
      We focus on delivering the highest amount of impact as efficiently as possible. We
      know your time is valuable, so we&apos;ve streamlined a program that cuts straight
      to the point with no &bdquo;filler content.&bdquo;
    </h4>

    <p>When you join the Reforge program you get access to:</p>

    <h3>1. In Depth Material</h3>
    <p>
      Each week there is 2 to 3 hours of video and written material. All material is
      taught by Brian Balfour and will teach you actionable frameworks to apply to your
      own products and career.
    </p>

    <p>
      The coursework is comprised of original material exclusive to the Reforge Program.
      The videos will be hosted on the Reforge website and available for you to watch at
      any time during the week and as reference material after the program for one year.
    </p>
    <img src="https://static1.squarespace.com/static/56f152a22fe131e76648aba3/t/5c82c92f652deaf4990e8b3e/1533652276058/marketing.002.jpeg?format=1000w" />

    <h3>2. Dozens of Relevant Examples</h3>
    <p>
      To complement coursework, we weave in examples and case studies applying the
      frameworks from the material to recent companies.
    </p>

    <p>
      If you were head of Growth at LinkedIn, how would strategically place your
      acquisition bets? What if you were at Pinterest? Blue Bottle Coffee? Slack? Zoom?
      Airbnb?
    </p>

    <p>
      How would you use User Psychology to move users through onboarding flows for
      products like Netflix, StitchFix, MailChimp, Atlassian, or OfferUp? We walk you
      through examples from companies like the above, and many more.
    </p>
    <img src="https://static1.squarespace.com/static/56f152a22fe131e76648aba3/t/5c82c92f652deaf4990e8b40/1533652276060/marketing.003.jpeg?format=1000w" />

    <h3>3. Application Sections at Each Step </h3>
    <p>
      At Reforge, we believe in application over memorization. That means integrating your
      learning into your work at every opportunity. Each week is accompanied by
      application sections designed to operationalize the material on your product or
      company. You&apos;ll also hear from Reforge leaders and experienced practitioners in
      the community for additional ideas and learning.
    </p>
    <img src="https://static1.squarespace.com/static/56f152a22fe131e76648aba3/t/5c82c92f652deaf4990e8b44/1533652276064/marketing.005.jpeg?format=1000w" />

    <h3>
      4. Weekly Events with Top Experts and Peer Practitioners (Both Online + Offline)
    </h3>
    <p>
      You&apos;ll connect with other top growth practitioners via weekly events, both
      online or in person (SF only). The program can be done from any location. Events
      start with an interview with a top growth leader from great companies like Google,
      Evernote, Stripe, Pinterest, Slack, SurveyMonkey, Segment, and many more. Then, we
      break off into small groups to discuss how you&apos;ll apply that week&apos;s
      learnings to your company, and hear perspectives from practitioners in similar roles
      and companies. All events require your active participation.
    </p>
    <img src="https://static1.squarespace.com/static/56f152a22fe131e76648aba3/t/5c82c92f652deaf4990e8b46/1533652276066/140_ReforgeKickOff_M3A0152.jpg?format=1500w" />

    <h3>5. Community Support</h3>
    <p>
      We designed the Reforge content to walk you through each framework step-by-step.
      However, we know each business is different, so naturally you&apos;ll have specific
      questions. That&apos;s why we created a community to help you get unstuck. If you
      have questions about modeling customer acquisition costs, how to segment your
      existing customers, tying NPS to core business metrics, or anything else, you have
      access to people from a variety of companies, all at different stages, at any time.
    </p>
  </div>
)

interface ICmsLessonPartialContentProps {
  currentUser: CurrentUserQuery['currentUser']
  cmsSection: {
    id: string
    name: string
    truncatedDescriptionHtml?: Maybe<string> | undefined
  }
}

export const CmsLessonPartialContent = ({
  cmsSection
}: ICmsLessonPartialContentProps) => {
  const { currentUser } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    trackServerEvent({
      variables: {
        input: {
          event: 'Conversion Page Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            page_type: 'lesson',
            path: window.location.pathname,
            referrer: document.referrer,
            url: window.location.href,
            is_conversion_eligible: true,
            logged_in: !!currentUser,
            related_identifiers: {
              content_id: cmsSection?.id,
              content_title: cmsSection?.name
            },
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
  }, [cmsSection, currentUser, trackServerEvent])

  return (
    <div className="uk-width-expand inline-posts__content-block">
      <CmsSectionDescription descriptionHTML={cmsSection?.truncatedDescriptionHtml} />

      <ContentGate
        title={
          <>
            Access this full lesson with a membership
            <DiamondIcon className="w-6 h-6 inline-block -mt-3 ml-0.5" />
          </>
        }
        description="Get unlimited access to guides, on-demand courses and more with one of our membership options."
        ctaText="Become a member"
        ctaHref={
          currentUser
            ? `/subscribe/choose-plan?referer=${window.location.pathname}`
            : `/login?reforgeLogin=true&referer=${encodeURIComponent(
                `/subscribe/choose-plan?referer=${window.location.pathname}`
              )}`
        }
        onClick={() => {
          trackCtaClicked({
            cta_location: 'lesson_paywall',
            cta_type: 'button',
            text: 'become a member',
            related_identifiers: {
              content_id: cmsSection?.id,
              content_title: cmsSection?.name
            }
          })
        }}
        divClassName="bg-rb-blue-50 rounded-b-2xl mt-6"
        buttonClassName="mt-8"
        buttonColor="premium"
      />
    </div>
  )
}

interface ICmsContentPartialContentProps {
  name: string
  truncatedDescriptionHtml: Maybe<string> | undefined
}

export const CmsContentPartialContent = ({
  name,
  truncatedDescriptionHtml
}: ICmsContentPartialContentProps) => {
  const history = useHistory()

  return (
    <div>
      <div className="inline-posts__content-block min-w-[1px] flex-1">
        <CmsSectionDescription descriptionHTML={truncatedDescriptionHtml} />
        <hr />

        <div className="bg-rb-gray-500 p-[30px]">
          <div className="text-lg font-medium uppercase tracking-[1.8px] text-white opacity-70">{`Your access doesn't include '${name}'.`}</div>
          <Button
            className="mt-5 text-lg"
            fullWidth
            variant="outline"
            onClick={() => history.push('/account')}
          >
            Sign up for the subscription
          </Button>
        </div>
        <hr />
        <br />
        <BlurredContent />
      </div>
    </div>
  )
}
