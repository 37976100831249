import { twMerge } from 'tailwind-merge'

import { CheckIcon } from 'components/icons'

export interface ProgressRingProps {
  progressPercent: number
  size?: number
  trackWidth?: number
  className?: string
  fontSize?: number
  strokeClassName?: string
  completedClassName?: string
}

export const ProgressRing = ({
  progressPercent,
  size = 48,
  trackWidth = 5,
  className,
  fontSize,
  strokeClassName = 'stroke-rb-green-100',
  completedClassName = ''
}: ProgressRingProps) => {
  const center = size / 2
  const radius = center - trackWidth
  const dashArray = 2 * Math.PI * radius
  const dashOffset = dashArray * ((100 - progressPercent) / 100)

  if (progressPercent === 100) {
    // This makes the circle slightly smaller to be similar in size to the ring
    size = Math.floor(size * 0.9)
    return (
      <div
        className={twMerge(
          'relative flex items-center justify-center rounded-full bg-rb-green-100',
          completedClassName
        )}
        style={{ width: size, height: size }}
      >
        <CheckIcon className="w-2/3 fill-rb-white" />
      </div>
    )
  }

  return (
    <div className={twMerge('relative', className)} style={{ width: size, height: size }}>
      <svg className="-rotate-90" style={{ width: size, height: size }}>
        <circle
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          className="stroke-gray-200"
          strokeWidth={trackWidth}
        />
        <circle
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          className={strokeClassName}
          strokeWidth={trackWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap="round"
        />
      </svg>
      <div
        className="absolute top-0 left-0 flex items-center justify-center"
        style={{ width: size, height: size }}
      >
        <span className={twMerge('text-xs', fontSize ? `text-[${fontSize}px]` : '')}>
          {progressPercent}%
        </span>
      </div>
    </div>
  )
}
