import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'

import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackMenuClicked } from 'utils/tracking/analytics'

import { artifactState, useSnapshot } from 'stores'

import DropdownMenuItem from './DropdownMenuItem'
import { getArtifactLinks, openOpenInLink } from './utils'

interface OpenInDropdownProps {
  artifact: any // @todo: define type
}

const OpenInDropdown = ({ artifact }: OpenInDropdownProps) => {
  const { currentUser: user } = useCurrentUser()
  const { welcomeMode } = useSnapshot(artifactState)

  const [openInData, setOpenInData] = useState<
    { key: string; type: string; src: string }[]
  >([])

  useEffect(() => {
    setOpenInData(getArtifactLinks(artifact) || [])
  }, [artifact])

  const Badges = () => {
    return (
      <div className="flex items-center justify-center ">
        <div className="mr-2 font-semibold">Use in</div>
        <div className="flex items-center space-x-1">
          {openInData.map(({ src, type }) => (
            <div className="h-4 w-4" key={type}>
              <img alt={type} src={src} className="h-4 w-4" />
            </div>
          ))}
        </div>
      </div>
    )
  }

  const OpenInLinks = () => {
    return openInData.map(({ key, src, type }) => {
      const href = artifact[key]
      return (
        <DropdownMenuItem
          key={key}
          onClick={() => {
            trackMenuClicked({
              content_id: artifact.id,
              content_title: artifact.title,
              menu_name: 'use in tool',
              trigger: 'selection made',
              selection: type
            })

            openOpenInLink({ href, external: true })
          }}
        >
          <div className="h-4 w-4">
            <img alt={type} src={src} className="h-4 w-4" />{' '}
          </div>
          <span className="ml-2">Open in {type}</span>
        </DropdownMenuItem>
      )
    })
  }

  return (
    <Menu as="div" className="relative inline-block text-center">
      {welcomeMode.step === 'OpenInSave' && (
        <>
          <Menu.Button
            size="x-small"
            as={Button}
            variant="outline"
            className="h-10 cursor-pointer hover:border-rb-teal-200 hover:text-rb-teal-200"
          >
            <Badges />
          </Menu.Button>

          <Transition
            show={true}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-[1004] mt-3 w-48 origin-top-right rounded-md bg-rb-white px-1 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {OpenInLinks()}
            </Menu.Items>
          </Transition>
        </>
      )}

      {welcomeMode.step !== 'OpenInSave' && (
        <>
          <Menu.Button
            as={Button}
            size="x-small"
            variant="outline"
            className="h-10 cursor-pointer hover:border-rb-teal-200 hover:text-rb-teal-200"
            onClick={() => {
              trackMenuClicked({
                content_id: artifact.id,
                content_title: artifact.title,
                menu_name: 'use in tool',
                trigger: 'menu opened'
              })
            }}
          >
            <Badges />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-3 mt-3 w-48 origin-top-right rounded-md bg-rb-white px-1 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {!user && (
                <DropdownMenuItem
                  onClick={() => {
                    trackMenuClicked({
                      content_id: artifact.id,
                      content_title: artifact.title,
                      menu_name: 'use in tool',
                      trigger: 'selection made',
                      selection: 'sign up'
                    })

                    openOpenInLink({
                      href: `${window.origin}/login?reforgeLogin=true?referrer=${window.origin}/artifacts/${artifact.slug}`,
                      external: false
                    })
                  }}
                >
                  <span>Sign up to get access</span>
                </DropdownMenuItem>
              )}

              {user && <>{OpenInLinks()}</>}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default OpenInDropdown
