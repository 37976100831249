import React from 'react'
import { Link } from 'react-router-dom'

import { BreadcrumbHistoryEntry } from 'components/Breadcrumbs'
import { ReactComponent as IconChevronRightMini } from 'components/Breadcrumbs/chevron-right-mini.svg'
import { ReactComponent as IconChevronRight } from 'components/Breadcrumbs/chevron-right.svg'
import RfMetaMini from 'components/typography/RfMeta/RfMetaMini'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface BreadcrumbLinkProps {
  historyEntry: BreadcrumbHistoryEntry
  onClick?: () => void
}

const BreadcrumbLink = ({ historyEntry, onClick = () => {} }: BreadcrumbLinkProps) => (
  <div className="mr-1.5 flex items-center sm:mr-3">
    <div className="mr-1.5 sm:mr-3">
      <Link to={historyEntry.path} onClick={onClick}>
        <RfMetaMini color={ColorOptions.gray}>
          <span className="underline">{historyEntry.name}</span>
        </RfMetaMini>
      </Link>
    </div>
    <div className="sm:hidden">
      <IconChevronRightMini fill={'#888888'} width={12} height={12} />
    </div>
    <div className="hidden sm:block">
      <IconChevronRight fill={'#888888'} width={16} height={16} />
    </div>
  </div>
)

export default BreadcrumbLink
