import Image from 'domains/Sanity/Image'

import ancestryLogo from 'images/courses/logo--Ancestry.png'
import chimeLogo from 'images/courses/logo--Chime.png'
import dropboxLogo from 'images/courses/logo--Dropbox.png'
import mailchimpLogo from 'images/courses/logo--Mailchimp.png'
import metaLogo from 'images/courses/logo--Meta.png'
import theNewYorkTimesLogo from 'images/courses/logo--The-New-York-Times.png'
import webflowLogo from 'images/courses/logo--Webflow.png'
import zillowLogo from 'images/courses/logo--Zillow.png'

const LOGOS = [
  metaLogo,
  mailchimpLogo,
  webflowLogo,
  chimeLogo,
  dropboxLogo,
  zillowLogo,
  theNewYorkTimesLogo,
  ancestryLogo
]

export const LogoSection = () => {
  return (
    <section className="px-4 py-10 md:pb-12 md:pt-24">
      <div className="mx-auto max-w-[1024px]">
        <h3 className="text-center text-2xl font-normal leading-[1.2] tracking-[-1px] md:text-4xl md:tracking-[-1.44px]">
          Courses include case studies & examples from companies like
        </h3>

        <div className="flex flex-row flex-wrap items-center justify-between">
          {LOGOS.map((logo) => (
            <div
              key={logo}
              className="flex w-1/2 grow-0 items-center justify-center md:w-1/4"
            >
              <Image
                src={logo}
                alt={`${logo
                  .split('--')[1]
                  .split('.')[0]
                  .split('-')
                  .slice(0, -1)
                  .join(' ')} logo`}
                className="my-6 h-[4vw] min-h-[20px] w-auto md:h-[3vw] md:max-h-[36px]"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default LogoSection
