import { Link } from 'react-router-dom'

import SimilaritySearchResultItem from 'domains/Search/SimilaritySearchResultItem'
import { SearchLinkType } from 'domains/Search/useSearchResultsFilters'

import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { CurrentUserPartsFragment, SimilarityContentFragment } from 'gql'

import useURLParams from 'hooks/useURLParams'

import EmptySearchItem from './EmptySearchItem'

export interface UserCanProps {
  linkToSearchResults?: boolean | null
  viewMemberProfile?: boolean | null
  viewPost?: boolean | null
  viewSearchTabs?: boolean | null
}

interface SimilaritySearchResultSectionProps {
  currentUser: CurrentUserPartsFragment | null
  sectionHeader: string
  sectionResults: SimilarityContentFragment[]
  sectionCount: string
  userCan: UserCanProps
  searchQuery: string
  itemsDisplayed: number
  linkType: SearchLinkType
  handleResultClick: (
    urL: string,
    path: string,
    searchPosition: number,
    resultComponent?: string,
    contentType?: string,
    subType?: string | null,
    doc?: object | null
  ) => void
  hideHeader?: boolean
  showViewMoreLink?: boolean
  searchPositionOffset?: number
  impressionTrackingProperties: { [key: string]: any }
}

const SimilaritySearchResultSection = ({
  currentUser,
  sectionHeader,
  sectionResults,
  userCan,
  handleResultClick,
  linkType,
  searchQuery,
  itemsDisplayed,
  hideHeader = false,
  showViewMoreLink = true,
  searchPositionOffset = 0,
  impressionTrackingProperties
}: SimilaritySearchResultSectionProps) => {
  const { deleteParam, setParam, getQueryString } = useURLParams()

  const formatLinkUrl = () => {
    linkType === SearchLinkType.TOP_RESULTS
      ? deleteParam('type')
      : setParam('type', linkType)
    return `/search?${getQueryString()}`
  }

  const scrollToTop = () => {
    document.getElementById('page')?.scrollTo(0, 0)
  }

  const displayedResults =
    itemsDisplayed === -1
      ? sectionResults || []
      : (sectionResults || []).slice(0, itemsDisplayed)

  return (
    <div>
      {!hideHeader && (
        <div className="flex items-center gap-4 pb-6">
          <div className="mb-0 font-semibold text-lg md:text-xl">{sectionHeader}</div>
          {showViewMoreLink && !!sectionResults?.length && (
            <Link
              to={formatLinkUrl()}
              onClick={scrollToTop}
              className="text-rb-black hover:text-rb-teal-300 hover:no-underline"
            >
              <RfParagraphMini className="flex items-center space-x-2 hover:text-rb-teal-300">
                <span>view all</span>
                <ChevronRightThinIcon className="h-3 fill-current" />
              </RfParagraphMini>
            </Link>
          )}
        </div>
      )}
      {displayedResults?.length ? (
        <ul className="m-0 grid grid-cols-1 gap-lg p-0">
          {displayedResults.map((doc: SimilarityContentFragment, index) => (
            <SimilaritySearchResultItem
              currentUser={currentUser}
              key={doc.id}
              searchDocument={doc}
              searchQuery={searchQuery}
              searchPosition={searchPositionOffset + index + 1}
              userCan={userCan}
              handleResultClick={(...args) => {
                handleResultClick(...args)
              }}
              impressionTrackingProperties={{
                ...impressionTrackingProperties,
                sectionImpressionIndex: index
              }}
            />
          ))}
        </ul>
      ) : (
        <EmptySearchItem
          message={`No ${sectionHeader} matching`}
          searchQuery={searchQuery}
        />
      )}
    </div>
  )
}

export default SimilaritySearchResultSection
