import { useEffect } from 'react'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { Loading } from 'components'

import {
  CoursesPageActiveCourseSessionPartsFragment,
  CoursesPageCurrentUserPartsFragment,
  MarketingCourse,
  ProgramBookmarkPartsFragment,
  useBookmarkFoldersQuery,
  useCoursesPageMarketingCoursesQuery
} from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import CourseCard, {
  MARKETPLACE_COURSE_TYPE_LEGACY,
  MARKETPLACE_COURSE_TYPE_MARKETPLACE,
  courseFilterToCourseCardDestinationType
} from './CourseCard'
import {
  COURSE_FILTER_LIVE,
  useActiveCourseFilter,
  useGetFilterFromPath
} from './CoursesFilters'

interface CoursesSectionProps {
  coursesData: CourseWithSessionData[] // isActive: boolean?
  className?: string
  title: string
  user: CoursesPageCurrentUserPartsFragment
  activeCourseFilter: 'live' | 'on-demand'
  showStartDate?: boolean
  dataTest?: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  hideBookmarkButton?: boolean
}

interface CourseWithSessionData {
  course: MarketingCourse
  courseSession?: CoursesPageActiveCourseSessionPartsFragment
  isEnrollable?: boolean
}

const CoursesSection = ({
  coursesData,
  className,
  title,
  user,
  activeCourseFilter,
  showStartDate,
  dataTest,
  openAddToBookmarkFolderModal,
  hideBookmarkButton
}: CoursesSectionProps) => {
  const { showOnDemandMarketplaceCourses } = useFeatureFlags()

  const renderCourseCard = (courseData: CourseWithSessionData) => {
    const destinationType = courseFilterToCourseCardDestinationType(
      showOnDemandMarketplaceCourses && !!courseData.course.course?.isAvailableOnDemand,
      activeCourseFilter
    )
    return (
      <CourseCard
        pageLocation="courses"
        cardType={activeCourseFilter}
        course={courseData.course}
        courseSession={courseData.courseSession}
        key={courseData.course.id}
        user={user}
        showStartDate={showStartDate}
        destinationType={destinationType}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        hideBookmarkButton={hideBookmarkButton}
      />
    )
  }

  return (
    <div className={className} data-test={dataTest}>
      {title && <h3 className="mb-8 font-polysans !font-light">{title}</h3>}
      <ul className="flex w-full list-none flex-wrap gap-10 p-0">
        {coursesData.map(renderCourseCard)}
      </ul>
    </div>
  )
}

interface LiveMarketPlaceCoursesProps {
  marketingCourses: MarketingCourse[]
  activeCourseSessions: CoursesPageActiveCourseSessionPartsFragment[]
  user: CoursesPageCurrentUserPartsFragment
}

const LiveMarketplaceCourses = ({
  marketingCourses,
  activeCourseSessions,
  user
}: LiveMarketPlaceCoursesProps) => {
  const marketplaceTypeCourses = marketingCourses.filter(
    (marketingCourse) =>
      marketingCourse.courseType === MARKETPLACE_COURSE_TYPE_MARKETPLACE
  )
  const coursesData = mergeCoursesWithActiveCourseSessions(
    marketplaceTypeCourses,
    activeCourseSessions
  )
  const nonEnrollableCoursesData = coursesData.filter(({ isEnrollable }) => !isEnrollable)
  const enrollableCoursesData = coursesData.filter(({ isEnrollable }) => isEnrollable)

  return (
    <>
      {enrollableCoursesData.length > 0 && (
        <CoursesSection
          dataTest="enrollable-courses-section"
          coursesData={enrollableCoursesData}
          className={'rounded-xl pr-8'}
          title="Enroll Today"
          user={user}
          activeCourseFilter="live"
          showStartDate
          hideBookmarkButton={true}
        />
      )}
      {nonEnrollableCoursesData.length > 0 && (
        <CoursesSection
          dataTest="non-enrollable-courses-section"
          coursesData={nonEnrollableCoursesData}
          className={'rounded-xl pr-8'}
          title="Coming Soon"
          user={user}
          activeCourseFilter="live"
          hideBookmarkButton={true}
        />
      )}
    </>
  )
}

export interface OnDemandMarketplaceCoursesProps {
  marketingCourses: MarketingCourse[]
  user: CoursesPageCurrentUserPartsFragment
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

export const OnDemandMarketplaceCourses = ({
  marketingCourses,
  user,
  openAddToBookmarkFolderModal
}: OnDemandMarketplaceCoursesProps) => {
  const { showOnDemandMarketplaceCourses } = useFeatureFlags()

  const onDemandMarketplaceTypeCourses = showOnDemandMarketplaceCourses
    ? marketingCourses.filter(
        (marketingCourse) =>
          marketingCourse.courseType === MARKETPLACE_COURSE_TYPE_MARKETPLACE &&
          marketingCourse.course?.isAvailableOnDemand
      )
    : []

  const legacyTypeCourses = marketingCourses.filter(
    (marketingCourse) => marketingCourse.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
  )
  const courses = [...onDemandMarketplaceTypeCourses, ...legacyTypeCourses].sort(
    (a, b) => ((a?.title || '') > (b?.title || '') ? 1 : -1)
  )
  const coursesData = courses.map((course) => ({ course }))

  return (
    <CoursesSection
      coursesData={coursesData}
      title=""
      className="mr-16"
      user={user}
      activeCourseFilter="on-demand"
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
    />
  )
}

const mergeCoursesWithActiveCourseSessions = (
  courses: MarketingCourse[],
  activeCourseSessions: CoursesPageActiveCourseSessionPartsFragment[]
): CourseWithSessionData[] => {
  const mergeCourseWithActiveCourseSession = (course: MarketingCourse) => {
    let courseSession: CoursesPageActiveCourseSessionPartsFragment | null
    let isEnrollable = false
    for (let i = 0; i < activeCourseSessions.length; i++) {
      courseSession = activeCourseSessions[i]
      if (courseSession.courseSlug === course.course?.slug) {
        if (!courseSession.hasStarted) {
          isEnrollable = true
        }
        return {
          course,
          courseSession,
          isEnrollable
        }
      }
    }

    return { course }
  }

  return courses.map(mergeCourseWithActiveCourseSession)
}

interface MarketplaceCoursesSectionProps {
  user: CoursesPageCurrentUserPartsFragment
}

const MarketplaceCoursesSection = ({ user }: MarketplaceCoursesSectionProps) => {
  const filterFromPath = useGetFilterFromPath()
  const { setActiveCourseFilter, activeCourseFilter } = useActiveCourseFilter()
  const { data, loading } = useCoursesPageMarketingCoursesQuery()

  useEffect(() => {
    setActiveCourseFilter(filterFromPath || COURSE_FILTER_LIVE)
  }, [filterFromPath, setActiveCourseFilter])

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery()

  if (!data || loading) {
    return <Loading />
  }

  if (!data?.marketingCourses?.length) {
    return null
  }

  return (
    <div className="grid gap-10">
      <>
        {activeCourseFilter === COURSE_FILTER_LIVE ? (
          <LiveMarketplaceCourses
            marketingCourses={data.marketingCourses}
            activeCourseSessions={data.activeCourseSessions}
            user={user}
          />
        ) : (
          <OnDemandMarketplaceCourses
            marketingCourses={data.marketingCourses}
            user={user}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          />
        )}
        <CreateBookmarkFolderModal
          isModalOpen={isCreateBookmarkFolderModalOpen}
          handleClose={closeCreateBookmarkFolderModal}
          currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
          trackingTriggerAction={'homepage'}
        />
        <AddBookmarkToFolderModal
          isOpen={isAddToBookmarkFolderModalOpen}
          handleClose={closeAddToBookmarkFolderModal}
          bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
          openCreateBookmarkFolderModal={() => {
            openCreateBookmarkFolderModal(currentBookmarkForDropdown)
            closeAddToBookmarkFolderModal()
          }}
          currentBookmarkForDropdown={currentBookmarkForDropdown}
          showCollectionsOnboardingInfo={
            !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
          }
        />
      </>
    </div>
  )
}

export default MarketplaceCoursesSection
