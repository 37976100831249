import LiveHost from 'live-hosts.png'
import { FC, useCallback, useEffect, useState } from 'react'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { track, trackNavigationClicked } from 'utils/tracking/analytics'

import { AnnouncementBanner } from '../AnnouncementBanner/AnnouncementBanner'
import { AnnouncementBannerProps } from '../AnnouncementBanner/AnnouncementBannerCarousel'

export const CourseLiveBanner: FC<AnnouncementBannerProps> = ({
  setActiveComponents,
  isVisible
}: any) => {
  const [ctaText, setCtaText] = useState('')
  const [bannerText, setBannerText] = useState({
    header: '',
    subheader: ''
  })

  const { currentUser } = useCurrentUser()

  useEffect(() => {
    const numberOfCohortCredits = currentUser?.numberOfUseableCohortCredits
    const hasUseableCohortCredits = currentUser?.hasUseableCohortCredits

    if (currentUser?.is.planManager && hasUseableCohortCredits) {
      setCtaText('View live courses')
      setBannerText({
        header: 'Live Courses now open for enrollment!',
        subheader: `Your team has ${numberOfCohortCredits} of unassigned live course passes. Check out the suite of courses your team can enroll in with their passes.`
      })
    } else if (currentUser?.is.member && hasUseableCohortCredits) {
      setCtaText('Enroll for free')
      setBannerText({
        header: 'Enroll in a live course for free',
        subheader:
          'Learn from and connect with top AI, Growth, Product, and Strategy experts in live courses available this fall. Use your live course pass to join for free.'
      })
    } else {
      setCtaText('View live courses')
      setBannerText({
        header: 'Live Courses now open for enrollment!',
        subheader:
          'Learn from and connect with top AI, Growth, Product, and Strategy experts in live courses available this fall.'
      })
    }
  }, [currentUser])

  useEffect(() => {
    track('Banner Displayed', {
      banner_name: 'live_courses_available',
      category: 'app'
    })
  }, [])

  const handleBannerDismissed = () => {
    track('Banner Dismissed', {
      banner_name: 'live_courses_available',
      category: 'app'
    })
    window.localStorage.setItem('dismiss-live-course-banner', '1')

    setActiveComponents((previousVal: string[]) =>
      previousVal.filter((v: string) => v !== 'course-live-banner')
    )
  }

  const handleClick = useCallback(() => {
    trackNavigationClicked({
      location: 'free_user_home | premium_user_home'
    })
  }, [])

  return (
    <AnnouncementBanner
      firstCta={{
        text: 'Dismiss',
        onClick: handleBannerDismissed
      }}
      secondCta={{
        url: '/courses?tab=live',
        text: ctaText,
        onClick: handleClick
      }}
      color={'#FEF9F2'}
      textColor={'#000000'}
      image={LiveHost}
      header={bannerText.header}
      subHeader={bannerText.subheader}
      isVisible={isVisible}
    />
  )
}
