import CollectiveBadge from 'domains/Member/CollectiveBadge'
import CollectiveBadgeSmall from 'domains/Member/CollectiveBadgeSmall'
import { formatPositionAtCompany } from 'domains/Profile/utils'

import BadgeableAvatar from 'components/BadgeableAvatar'
import { SquarishAvatarImage } from 'components/SquarishAvatarImage'

import { ArtifactAuthor, ExpertUserProfileFragmentFragment } from 'gql'

import TopCreator from '../components/TopCreator'
import UpcomingEventCourses from '../components/UpcomingEventCourse'
import SocialsAndCTA from './SocialsAndCTA'

function SquarishDefaultAvatarImage({ fullName }: { fullName: string }) {
  return (
    <div className="rounded-[20px] h-[120px] w-[120px] sm:h-[240px] sm:w-[240px] lg:h-[340px] lg:w-[340px] bg-rb-gray-50">
      <div className="w-full h-full flex items-center justify-center">
        <BadgeableAvatar
          avatarUrl=""
          className="text-3xl"
          fullName={fullName}
          width={100}
          height={100}
        />
      </div>
    </div>
  )
}

type ProfileSideBarProps = {
  authorProfile: ArtifactAuthor
  userProfile: ExpertUserProfileFragmentFragment
}

const AuthorProfileSection = ({ userProfile, authorProfile }: ProfileSideBarProps) => {
  const isDefaultAvatar = /default_avatar/.test(userProfile?.avatarUrl || '')

  return (
    <div className="w-full gap-8 md:gap-0 flex flex-row md:flex-col items-start text-rb-gray-400 shrink-0">
      <div className="relative">
        {isDefaultAvatar && userProfile?.fullName ? (
          <SquarishDefaultAvatarImage fullName={userProfile.fullName} />
        ) : (
          <SquarishAvatarImage
            className="h-[120px] w-[120px] sm:h-[240px] sm:w-[240px] lg:h-[340px] lg:w-[340px]"
            avatarUrl={userProfile?.avatarUrl || ''}
            alt={userProfile?.fullName || ''}
          />
        )}

        {userProfile?.is?.collective && (
          <div className="absolute top-2 left-2">
            <CollectiveBadgeSmall className="ml-2.5 sm:hidden" />
            <CollectiveBadge className="mt-1 hidden sm:block" />
          </div>
        )}
      </div>

      <div className="flex flex-col h-[120px] sm:h-[240px] md:h-fit  justify-center md:my-6">
        <div className=" font-polysans text-2xl sm:text-[2.5rem] !font-light mb-2">
          {userProfile?.fullName}
        </div>

        <h2 className="font-sans text-xs sm:text-base font-normal">
          {formatPositionAtCompany(userProfile?.role, userProfile?.companyName, 'at')}
        </h2>

        {Boolean(userProfile?.previousCompanies?.length) && (
          <div className="font-sans text-xs sm:text-base font-normal text-rb-gray-300">
            <span>Previously </span>
            {userProfile.previousCompanies.map((obj, index, array) => (
              <span key={obj.id}>
                <span>{obj.company.name}</span>
                {index < array.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        )}

        <div className="hidden sm:block mt-6 lg:hidden">
          <SocialsAndCTA userProfile={userProfile} authorProfile={authorProfile} />
        </div>
      </div>

      <div className="hidden lg:block">
        <SocialsAndCTA userProfile={userProfile} authorProfile={authorProfile} />
      </div>

      <div className="hidden md:block">
        <TopCreator authorProfile={authorProfile} userProfile={userProfile} />
      </div>

      {userProfile && (
        <div className="hidden md:block">
          <UpcomingEventCourses userProfile={userProfile} />
        </div>
      )}
    </div>
  )
}

export default AuthorProfileSection
