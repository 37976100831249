import { addMinutes, format, set } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { LearningGoalSessionData } from 'pages/goals/type'

import AddToCalendar from 'components/AddToCalendar'
import Button from 'components/Button'

import { ReactComponent as CalendarIcon } from 'images/icon--calendar-white.svg'

type AddToCalendarContainerProps = {
  isPlanManager: boolean
  timezone: string | null | undefined
  session: LearningGoalSessionData
  setIsEditing: any
  calendarData: {
    days: string[]
    time: number
    prompt: string
    why: string
    completionDate: string
  }
}

export const AddToCalendarContainer = ({
  setIsEditing,
  isPlanManager,
  calendarData,
  timezone,
  session
}: AddToCalendarContainerProps) => {
  const [showATC, setShowATC] = useState(false)

  const calendarIcon = (
    <CalendarIcon className="mx-0.1 w-4 self-center text-rb-gray-250" />
  )

  const handleAddCalendar = () => {
    setShowATC(!showATC)
  }
  const handleOnClickCallback = () => {
    console.log('Add to calendar clicked')
    // Add tracking here
  }

  const dayAbbreviations: { [key: string]: string } = {
    Monday: 'MO',
    Tuesday: 'TU',
    Wednesday: 'WE',
    Thursday: 'TH',
    Friday: 'FR',
    Saturday: 'SA',
    Sunday: 'SU'
  }

  const findNextStudyDay = (currentDate: Date, days: string[]): Date => {
    const daysMap: any = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6
    }

    for (let i = 1; i <= 7; i++) {
      const nextDay = new Date(currentDate)
      nextDay.setDate(currentDate.getDate() + i)
      if (
        days.includes(
          Object.keys(daysMap).find((key) => daysMap[key] === nextDay.getDay())!
        )
      ) {
        return nextDay
      }
    }
    return currentDate // Fallback to current date if no days match
  }

  const getAddToCalendarProps = () => {
    const finalTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

    const now = new Date()
    const periodEnd = moment(calendarData.completionDate).toDate()

    const defaultDailyStart = findNextStudyDay(now, calendarData.days)
    const defaultDailyStartSetHour = set(defaultDailyStart, {
      hours: 12,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    })
    const zonedDefaultDailyStartSetHour = utcToZonedTime(
      defaultDailyStartSetHour,
      finalTimezone
    )
    const zonedEndBySelectedDuration = addMinutes(
      zonedDefaultDailyStartSetHour,
      parseInt(calendarData.time.toString())
    )
    const zonedPeriodEnd = utcToZonedTime(periodEnd, finalTimezone)

    const until = format(zonedPeriodEnd, "yyyyMMdd'T'HHmmss")
    const byDayString = calendarData.days
      .map((day) => dayAbbreviations[day as keyof typeof dayAbbreviations])
      .join(',')
    const recurring = `FREQ=WEEKLY;UNTIL=${until};BYDAY=${byDayString}`

    const startsAtUtc = format(
      zonedDefaultDailyStartSetHour,
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    )
    const endsAtUtc = format(zonedEndBySelectedDuration, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

    const domain = window.location.origin
    const sessionLink = `${domain}/goals?step=recommendations&sessionId=${session.externalId}`

    return {
      timezone: finalTimezone,
      startsAtUtc: startsAtUtc,
      endsAtUtc: endsAtUtc,
      eventTitle: `Reforge Learning Time: ${calendarData.prompt}`,
      eventDescription: `Click <a href="${sessionLink}">here</a> to see your learning material`,
      recurring: recurring,
      className: '!shadow-none',
      onClickCallback: handleOnClickCallback
    }
  }
  const handleEdit = () => {
    setIsEditing(true)
  }

  useEffect(() => {
    if (showATC) {
      // Refreshing AddEvent api as it is synchronous
      // @ts-ignore
      window.addeventatc?.refresh()
    }
  }, [showATC])

  if (isPlanManager) {
    return (
      <>
        <Button
          className="h-10"
          color="teal"
          size="small"
          onClick={handleEdit}
          iconBefore={calendarIcon}
        >
          Set learning time
        </Button>
      </>
    )
  }

  return (
    <Button
      className="h-10 w-[184px] relative"
      color="teal"
      size="small"
      onClick={handleAddCalendar}
      iconBefore={calendarIcon}
    >
      {showATC ? 'Hide calendar' : 'Add to calendar'}
      {showATC && (
        <div className="absolute bottom-[42px] -left-[12%]">
          <AddToCalendar {...getAddToCalendarProps()} className="rounded-lg shadow" />
        </div>
      )}
    </Button>
  )
}
