import { DISCOUNTED_COHORT_PASS_PRICE } from 'pages/PurchaseFlow/contexts/PurchaseFlowContext'

import { PLAN_ITEMS, PLAN_OPTIONS, type PlanOptionFields } from 'constants/plans'

import { CohortPassPriceTier, PlanForSale as PlanForSaleGql, PlanName } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

export const isPlanDowngrade = (
  nextPlanName: string,
  currentPlanName: string,
  currentNumSeats: number
) => {
  if (!nextPlanName || !currentPlanName || nextPlanName === currentPlanName) {
    return false
  }

  if (currentPlanName === PlanName.UNLIMITED) {
    if (
      (currentNumSeats > 30 && nextPlanName === PlanName.SCALE) ||
      (currentNumSeats > 10 && nextPlanName === PlanName.STARTER) ||
      (currentNumSeats > 1 && nextPlanName === PlanName.INDIVIDUAL)
    ) {
      return true
    }
    return false
  }

  if (nextPlanName === PlanName.STARTER && currentPlanName === PlanName.INDIVIDUAL) {
    return false
  }
  if (
    nextPlanName === PlanName.SCALE &&
    (currentPlanName === PlanName.INDIVIDUAL || currentPlanName === PlanName.STARTER)
  ) {
    return false
  }
  if (nextPlanName === PlanName.ENTERPRISE) {
    return false
  }

  return true
}

type PlanMapType = {
  [PlanName.INDIVIDUAL]: string
  [PlanName.STARTER]: string
  [PlanName.SCALE]: string
  [PlanName.ENTERPRISE]: null
  [PlanName.EXPIRED]: null
  [PlanName.UNKNOWN]: null
  [PlanName.UNLIMITED]: null
}

const SUGGESTED_SEAT_MAP: PlanMapType = {
  [PlanName.INDIVIDUAL]: 'We suggest 1 live course pass',
  [PlanName.STARTER]: 'We suggest at least 2 live course passes',
  [PlanName.SCALE]: 'We suggest at least 6 live course passes',
  [PlanName.ENTERPRISE]: null,
  [PlanName.EXPIRED]: null,
  [PlanName.UNKNOWN]: null,
  [PlanName.UNLIMITED]: null
}

const INCLUDES_MAP: PlanMapType = {
  [PlanName.INDIVIDUAL]: 'Includes $500 off 1 live course pass',
  [PlanName.STARTER]: 'Includes 2 live course passes',
  [PlanName.SCALE]: 'Includes 6 live course passes',
  [PlanName.ENTERPRISE]: null,
  [PlanName.EXPIRED]: null,
  [PlanName.UNLIMITED]: null,
  [PlanName.UNKNOWN]: null
}

export namespace PlanOptionCardNamespace {
  export type PlanForSale = Omit<PlanForSaleGql, 'pricePerPerson' | 'stripeId'>
}

interface useBuildPlanOptionsDataProps {
  plansForSale: PlanOptionCardNamespace.PlanForSale[]
  currentPlanName?: PlanName
  currentPlanPrice?: number
  currentPlanSeats?: number
  onMarketingSite?: boolean
}

export const useBuildPlanOptionsData = ({
  plansForSale,
  currentPlanName,
  currentPlanPrice,
  currentPlanSeats,
  onMarketingSite = false
}: useBuildPlanOptionsDataProps): PlanOptionFields[] => {
  const { currentUser } = useCurrentUser()
  const { removeTeamsSelfServe } = useFeatureFlags()
  const planOptionsData: PlanOptionFields[] = []
  if (!plansForSale || plansForSale.length === 0) {
    return planOptionsData
  }

  const shouldShowFreeTrialFootnote =
    !onMarketingSite && (currentUser?.is.eligibleForTrial ?? true) // if there's no currentUser, ie. user is not logged in, we want to show the free trial

  const getIncludesMap = (planName: PlanName) => {
    if (planName === PlanName.INDIVIDUAL && shouldShowFreeTrialFootnote) {
      return 'Price includes 1 live course pass*'
    }

    return removeTeamsSelfServe ? null : INCLUDES_MAP[planName]
  }

  PLAN_OPTIONS.map((option) => {
    plansForSale.map((plan) => {
      if (option.name === PlanName.INDIVIDUAL && shouldShowFreeTrialFootnote) {
        option.features = option.features.map((f) =>
          f.toLowerCase() === 'slack community' ? 'Slack community*' : f
        )
        option.footnote = '* Unavailable during free trial'
      }

      if (option.name === plan.name) {
        planOptionsData.push({
          ...option,
          subtitle: planOptionSubtitle({
            option,
            plansForSale,
            currentPlanName,
            currentPlanSeats
          }),
          secondSubtitle: currentPlanName
            ? SUGGESTED_SEAT_MAP[plan.name]
            : getIncludesMap(plan.name),
          maxSeatCount: plan.maxSeatCount,
          price: plan.pricePerYear
        })
      }
    })

    if (option.name === PlanName.ENTERPRISE) {
      const enterpriseOption = {
        ...option,
        subtitle: planOptionSubtitle({
          option,
          plansForSale,
          currentPlanName,
          currentPlanSeats
        }),
        secondSubtitle: removeTeamsSelfServe
          ? null
          : 'Get a plan tailored to your business.'
      }

      if (currentPlanName === PlanName.ENTERPRISE) {
        if (currentPlanPrice) {
          enterpriseOption.price = currentPlanPrice / 100
        }

        enterpriseOption.content = {
          included: PLAN_ITEMS
        }
      }

      planOptionsData.push(enterpriseOption)
    }
  })

  if (plansForSale.length + 1 > planOptionsData.length) {
    console.error('Error: Missing a plan for sale. Make sure that plan names all match.')
  }

  return planOptionsData
}

const planOptionSubtitle = ({
  option,
  plansForSale,
  currentPlanName,
  currentPlanSeats
}: {
  option: PlanOptionFields
  plansForSale: PlanOptionCardNamespace.PlanForSale[]
  currentPlanName?: PlanName
  currentPlanSeats?: number
}): string => {
  const maxFlatTierSeatCount = Math.max(...plansForSale.map((plan) => plan.maxSeatCount))

  if (option.name === PlanName.ENTERPRISE) {
    return currentPlanName === PlanName.ENTERPRISE
      ? `${currentPlanSeats} seats`
      : `For ${maxFlatTierSeatCount}+ team members`
  }

  const optionMaxSeatCount = plansForSale.find(
    (plan) => plan.name === option.name
  )?.maxSeatCount

  if (!optionMaxSeatCount) {
    console.error(
      'Error: Missing a plan for sale or plan option. Make sure that plan names all match.'
    )
    return ''
  }

  return optionMaxSeatCount === 1
    ? 'For 1 person'
    : `For up to ${optionMaxSeatCount} team members`
}

export const getPrice = (
  plan: PlanOptionFields,
  cohortPassPriceTier: CohortPassPriceTier,
  removeTeamsSelfServe: boolean
) => {
  if (!plan.price || !cohortPassPriceTier) return null

  return plan.name === PlanName.INDIVIDUAL
    ? plan.price + DISCOUNTED_COHORT_PASS_PRICE
    : plan.price +
        (removeTeamsSelfServe
          ? 0
          : cohortPassPriceTier.minCount * cohortPassPriceTier.pricePerPass)
}
