import React from 'react'

interface CustomPageTitleProps {
  title: string
  children?: React.ReactNode
}

const CustomPageTitle: React.FC<CustomPageTitleProps> = ({ title, children }) => (
  <>
    <h1
      className="mb-0 flex flex-col self-start font-polysans text-[32px] !font-light leading-[1.2] tracking-[-4%] text-rb-gray-400 xs:flex-row xs:items-center lg:text-5xl gap-2 xs:gap-4"
      data-test="page-header-title"
    >
      <span>{title}</span>
      {children}
    </h1>
  </>
)

export default CustomPageTitle
