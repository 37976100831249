/* eslint-disable camelcase */
import { Link } from 'react-router-dom'

import EmbeddedHubspotForm from 'domains/Patronage/EmbeddedHubspotForm'

import { trackCtaClicked, trackMarketingFormSubmitted } from 'utils/tracking/analytics'

import { ReactComponent as ReforgeLogo } from 'images/reforge-logo.svg'

export const TeamRenewalSalesFormHeader = () => {
  return (
    <div className="flex h-20 w-full items-center bg-black pl-7 font-sans text-xl uppercase tracking-vast text-white">
      <Link to="/">
        <ReforgeLogo className="h-5 w-full text-rb-white" />
      </Link>
    </div>
  )
}

const TeamRenewalSalesForm = () => {
  const formId = '76351026-ad1d-4011-b435-9e23c990c2f6'

  const handleFormSubmit = (formData: any) => {
    trackCtaClicked({
      cta_location: 'hubspot_billing_page_contact_form',
      cta_type: 'button',
      text: 'submit to get in touch'
    })

    const el = formData.elements ?? formData?.[0]?.elements

    if (!el) {
      return
    }

    const email = el.email?.value
    const first_name = el.firstname?.value
    const last_name = el.lastname?.value
    const job_function = el.function?.value
    const seniority_level = el.seniority_level?.value
    const company_name = el.company?.value
    const organization_size = el.organization_size?.value
    const current_subscription_type = el.current_subscription_type?.value
    const questions = el.what_questions_do_you_have_about_reforge_for_teams_?.value

    const form_fields = {
      email,
      first_name,
      last_name,
      job_function,
      seniority_level,
      company_name,
      organization_size,
      current_subscription_type,
      questions
    }

    trackMarketingFormSubmitted({
      form_fields,
      email,
      form_id: formId,
      form_type: 'hubspot',
      form_name: 'team plan questions',
      location: 'hubspot_billing_page_contact_form',
      url: '/enterprise_renewal',
      source: 'marketing site',
      type: 'page'
    })
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <TeamRenewalSalesFormHeader />
      <EmbeddedHubspotForm
        classes="w-3/5"
        region="na1"
        portalId="20491267"
        formId={formId}
        onFormSubmit={handleFormSubmit}
      />
    </div>
  )
}

export default TeamRenewalSalesForm
