import cx from 'classnames'
import { useParams } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { CaseStudy, CaseStudyDetails, useCaseStudyDetailsQuery } from 'gql'

import BackgroundGradientImage from 'images/ShallowSoft_Blue-Pink.jpg'

const CaseStudyDetailsPage = () => {
  const { slug } = useParams<{ slug: string }>()
  const { data, loading } = useCaseStudyDetailsQuery({
    variables: {
      slug: slug.replaceAll('/', '')
    }
  })
  const page = (data?.caseStudy || {}) as CaseStudyDetails

  return (
    <MarketingLayout page={page}>
      {loading ? (
        <Loading />
      ) : page.caseStudy ? (
        <CaseStudyDetailsPageContent caseStudy={page.caseStudy} />
      ) : (
        <Content404 />
      )}
    </MarketingLayout>
  )
}

export const CaseStudyDetailsPageContent = ({ caseStudy }: { caseStudy: CaseStudy }) => {
  const getContentSectionBackground = (background: string) => {
    switch (background) {
      case 'beige':
        return 'bg-rb-background-beige'
      case 'yellow_gradient':
        return 'bg-rb-white bg-yellow-green-fade-gradient bg-no-repeat bg-center bg-cover'
      case 'white':
      default:
        return 'bg-rb-white'
    }
  }

  return (
    <>
      <div className="relative px-[16px] py-[3.3vmax] md:px-[4vw]">
        <Image
          src={BackgroundGradientImage}
          fill
          className="-z-1 object-cover object-center opacity-[0.6]"
          alt=""
        />

        <div className="pointer-events-none mb-[16px] flex w-max items-center justify-center rounded-full bg-rb-black px-[1.6em] py-[0.4em] uppercase text-rb-white">
          Case Study
        </div>

        <h1 className="mb-[40px] font-normal fluid-text-5xl md:w-3/4 md:fluid-text-7xl">
          {caseStudy?.title}
        </h1>

        <p className="mb-[40px] fluid-text-base">
          <strong>Use cases:</strong> {caseStudy?.useCases}
        </p>

        <div className="mb-[20px] flex w-full flex-col md:flex-row">
          {caseStudy.contributors.map((contributor) => (
            <div
              className="mb-[40px] flex bg-rb-white drop-shadow-[-4px_0px_5px_rgba(0,0,0,0.23)] md:mr-[40px] md:mb-0 md:w-1/2 lg:w-2/5"
              key={contributor.id}
            >
              <div className="relative min-h-[200px] w-2/5 max-w-xs shrink-0 md:w-1/3">
                <Image
                  src={contributor.avatarPhoto.imageUrl || ''}
                  fill
                  className="object-cover object-center"
                  alt={contributor.name}
                />
              </div>

              <div className="px-[24px] pt-[20px] pb-[20px]">
                <h4 className="mb-[10px] !font-serif font-light fluid-text-2xl">
                  {contributor.name}
                </h4>

                <p className="mb-0 !leading-[1.2] fluid-text-base">{`${contributor.position}, ${contributor.company}`}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {(caseStudy.content || []).length > 0 &&
        caseStudy.content?.map((content) => (
          <div
            className={cx(
              'relative px-[16px] py-[3.3vmax] md:px-[4vw] [&>*:first-child]:mt-[16px]',
              getContentSectionBackground(content.background)
            )}
            key={content.id}
          >
            <div className="block-content w-full md:max-w-[70%] [&_.step-number+*]:mt-0 [&_figure]:max-w-[40rem] [&_mux-player]:max-w-[40rem] [&_.video-embed]:max-w-[40rem] [&_blockquote]:text-3xl [&_h2]:!font-light [&_h3_.font-semibold]:!font-light">
              <ComplexPortableText content={content.contentBlock} />
            </div>
          </div>
        ))}
    </>
  )
}

export default CaseStudyDetailsPage
