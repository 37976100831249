import Button from 'components/Button'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { ReactComponent as RewardIcon } from 'images/icon--reward-stars.svg'

import PersonalizeRecommendationsBanner from './PersonalizeRecommendationsBanner'

export interface FeedHeaderProps {
  setIsFocusUpdate: (isFocusUpdate: boolean) => void
  openPersonalizeModal: () => void
  hasFocusAreas?: boolean | null
}
export const FeedHeader = ({
  setIsFocusUpdate,
  openPersonalizeModal,
  hasFocusAreas
}: FeedHeaderProps) => {
  const { aiContentRecommendations } = useFeatureFlags()

  const onCustomizeClick = () => {
    openPersonalizeModal()
    setIsFocusUpdate(true)
  }

  return aiContentRecommendations || hasFocusAreas ? (
    <div className="text-[22px] leading-[1.2] font-medium lg:text-[28px] lg:font-medium lg:leading-[1.4] flex items-center gap-4 flex-wrap justify-between">
      Recommended for you
      <Button
        size="small"
        className="h-10 px-4 bg-rb-gray-50 text-rb-black border-rb-gray-50 hover:bg-rb-gray-100 hover:border-rb-gray-100 hover:text-rb-black"
        iconBefore={<RewardIcon width={15} />}
        onClick={onCustomizeClick}
      >
        Personalize
      </Button>
    </div>
  ) : (
    <PersonalizeRecommendationsBanner
      openPersonalizeModal={openPersonalizeModal}
      setIsFocusUpdate={setIsFocusUpdate}
    />
  )
}

export default FeedHeader
