import pluralize from 'pluralize'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'

import { ArtifactAuthor, WorkEntry } from 'gql'

import ButtonMarketingCTA from '../ButtonMarketingCTA'

interface ExpertCardProps {
  expert: ArtifactAuthor
  horizontal?: boolean
  href?: string
  ctaText?: string
  ctaClassName?: string
  onCardClick?: Function
  onCtaClick?: Function
}

export const ExpertCard = ({
  expert,
  horizontal,
  href,
  ctaText,
  ctaClassName,
  onCardClick = () => {},
  onCtaClick = () => {}
}: ExpertCardProps) => {
  const { name, pastCompanies, avatarPhoto, slug } = expert
  const location = useLocation()
  const isCreatorPage = location.pathname.includes('/creators')
  const artifactCount = getArtifactCount((expert as ArtifactAuthor)?.workEntries)

  function getArtifactCount(entries: WorkEntry[] | undefined | null) {
    let count = 0
    const workEntries = entries || []
    workEntries.forEach((workEntry) => {
      if ((workEntry.artifactContributions?.length || 0) > 0) {
        const { artifactContributions } = workEntry
        count += artifactContributions?.length || 0
      }
    })
    return count
  }

  return (
    <Link
      to={href || `/profiles/${slug}`}
      onClick={() => onCardClick()}
      className={twMerge(
        'group flex flex-col rounded-b-[5px] bg-rb-white text-rb-black no-underline shadow-[0px_0px_5px_rgba(0,0,0,.15)] hover:no-underline',
        horizontal && 'md:flex-row'
      )}
    >
      <div
        className={twMerge(
          'aspec aspec relative mb-[9%] aspect-[4/3] shrink-0',
          horizontal && 'md:aspect-auto md:mb-0 md:w-[48%]'
        )}
      >
        <Image
          src={avatarPhoto?.imageUrl || ''}
          width={500}
          height={500 * (avatarPhoto?.aspectRatio || 1)}
          className="absolute h-full w-full object-cover"
          alt={avatarPhoto?.imageAlt || `${name} headshot`}
        />
      </div>

      <div
        className={twMerge(
          'flex h-full flex-col justify-between px-[6%] pb-[6%]',
          horizontal && 'md:px-[3vw] md:py-[3vw]'
        )}
      >
        <div>
          <h3
            className={twMerge(
              'mb-[3%] !font-serif !font-normal leading-[1.25] fluid-text-lg',
              horizontal && 'md:mb-[2vw] md:fluid-text-xl'
            )}
          >
            {name}
          </h3>

          {pastCompanies && (
            <p
              className={twMerge(
                'mb-[4%] !leading-[1.25] fluid-text-sm group-hover:text-rb-black',
                horizontal && 'md:mb-[2vw] md:fluid-text-base'
              )}
            >
              {Array.isArray(pastCompanies)
                ? pastCompanies.slice(0, 3).join(', ')
                : pastCompanies}
            </p>
          )}
        </div>
        <div className="flex justify-between items-center">
          <ButtonMarketingCTA
            size="small"
            tabIndex={-1}
            onClick={() => onCtaClick}
            className={twMerge('mt-auto group-hover:bg-rb-gray-400', ctaClassName)}
          >
            {ctaText || 'Read Bio'}
          </ButtonMarketingCTA>

          {isCreatorPage && (
            <span className=" font-sans font-normal text-sm text-rb-gray-300">
              {pluralize(`${artifactCount} artifact`, artifactCount)}
            </span>
          )}
        </div>
      </div>
    </Link>
  )
}

export default ExpertCard
