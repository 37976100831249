import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import ContentBookmark from 'domains/Cms/ContentBookmark'

import Button from 'components/Button'
import { FacePile, FacePileUser } from 'components/FacePile'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import {
  BookmarkFolderPartsFragment,
  CourseSnapshotMarketingCoursesPartsFragment,
  CoursesPageActiveCourseSessionPartsFragment,
  CoursesPageCurrentUserPartsFragment,
  CurrentUserPartsFragment,
  MarketingCourse,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { prettyStartDateFromMarketingCourse } from 'utils/courseUtils'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { COURSE_FILTER_LIVE, COURSE_FILTER_ON_DEMAND } from './CoursesFilters'

export const MARKETPLACE_COURSE_TYPE_LEGACY = 'legacy'
export const MARKETPLACE_COURSE_TYPE_MARKETPLACE = 'marketplace'

export const COURSE_CARD_DESTINATION_TYPE_COURSE = 'courseDetails'
export const COURSE_CARD_DESTINATION_TYPE_PROGRAM = 'programDetails'
export const COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD = 'onDemandDashboard'

export const courseFilterToCourseCardDestinationType = (
  isAvailableOnDemand: boolean,
  courseFilter: typeof COURSE_FILTER_LIVE | typeof COURSE_FILTER_ON_DEMAND
) => {
  if (courseFilter === COURSE_FILTER_LIVE) {
    return COURSE_CARD_DESTINATION_TYPE_COURSE
  }
  return isAvailableOnDemand
    ? COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
    : COURSE_CARD_DESTINATION_TYPE_PROGRAM
}

interface CourseCardProps {
  course: MarketingCourse | CourseSnapshotMarketingCoursesPartsFragment
  courseSession?: CoursesPageActiveCourseSessionPartsFragment
  user?: CoursesPageCurrentUserPartsFragment | CurrentUserPartsFragment | null
  cardType: string
  pageLocation: string
  className?: string
  destinationType:
    | typeof COURSE_CARD_DESTINATION_TYPE_COURSE
    | typeof COURSE_CARD_DESTINATION_TYPE_PROGRAM
    | typeof COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
  showStartDate?: boolean
  showCtaText?: boolean
  bookmark?: ProgramBookmarkPartsFragment
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  currentFolder?: BookmarkFolderPartsFragment | null
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  hideBookmarkButton?: boolean
}

const CourseCard = ({
  pageLocation,
  course,
  courseSession,
  user,
  cardType, // live, on-demand
  destinationType,
  showStartDate,
  showCtaText = true,
  bookmark,
  bookmarkFolders,
  restoreBookmark,
  currentFolder,
  handleRemoveFromFolder,
  openAddToBookmarkFolderModal,
  hideBookmarkButton = false
}: CourseCardProps) => {
  const history = useHistory()
  let bookmarkId =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
      ? course?.bookmarkId
      : course?.course?.bookmarkId
  bookmarkId = bookmark?.id || bookmarkId
  const [isInSavedItems, setIsInSavedItems] = useState(!!bookmarkId)
  const { isLoggedIn, currentUser } = useCurrentUser()

  if (!course) {
    return null
  }

  const title = course.course?.title || course.title
  const slug = course.course?.slug || course.slug
  const description = course.course?.shortDescription || course.subtitle

  const creatorNames = course.creators?.map((c) => c.expert?.name) || []
  const creatorNamesLength = creatorNames.length

  let facepileUsers: FacePileUser[] = []
  // If the course is a legacy course, it will be on-demand only,
  // and the creators will be set on the MarketingCourse object.
  if (course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY) {
    facepileUsers =
      course.creators?.map((creator) => {
        return {
          id: creator?.id,
          avatarUrl: creator?.expert?.avatarPhoto?.imageUrl
        } as FacePileUser
      }) || []
  } else if (cardType === COURSE_FILTER_LIVE) {
    // If it's a live course, the hosts will be decorated on the top level object
    facepileUsers =
      courseSession?.hosts?.map((host) => {
        return {
          id: host?.id,
          avatarUrl: host?.avatarPhoto?.imageUrl
        } as FacePileUser
      }) || []

    // If there are no hosts set yet, show the creators in the facepile
    if (facepileUsers.length === 0) {
      facepileUsers =
        course.course?.creators?.map((courseCreator) => {
          return {
            id: courseCreator?.id,
            avatarUrl: courseCreator?.avatarPhoto?.imageUrl
          } as FacePileUser
        }) || []
    }
  } else {
    // If it's an on-demand, marketplace course,
    // the creators will be most accurate on the nested course object.
    facepileUsers =
      course.course?.creators?.map((courseCreator) => {
        return {
          id: courseCreator?.id,
          avatarUrl: courseCreator?.avatarPhoto?.imageUrl
        } as FacePileUser
      }) || []
  }

  const showStartsAt = !!(
    course.courseType === MARKETPLACE_COURSE_TYPE_MARKETPLACE &&
    course.startDate &&
    showStartDate
  )

  const programSlug = course.cmsProgramSlug

  const destination = () => {
    if (destinationType === COURSE_CARD_DESTINATION_TYPE_COURSE) {
      let destinationUrl = ''
      destinationUrl = `/courses/${slug}`

      if (isLoggedIn) {
        destinationUrl += '/details'
      }

      return destinationUrl
    } else if (destinationType === COURSE_CARD_DESTINATION_TYPE_PROGRAM) {
      if (isLoggedIn && !!user?.is?.member) {
        return `/programs/${programSlug}`
      }

      if (isLoggedIn && !user?.is?.member) {
        return `/programs/${programSlug}/preview`
      }

      if (!isLoggedIn || !user?.is?.member) {
        return `/courses/${slug}`
      }
    } else if (destinationType === COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD) {
      if (isLoggedIn && !!user?.is?.member) {
        return `/courses/${slug}/on-demand`
      } else {
        return `/courses/${slug}/details`
      }
    }

    return '/courses' // Fallback
  }

  const ctaText = showStartDate ? 'Learn more & enroll ↗' : 'Learn More ↗'
  const destinationUrl = destination()

  const handleOnClick = () => {
    trackCtaClicked({
      destination: destinationUrl,
      cta_location: `${pageLocation}_course_card`,
      text: ctaText,
      cta_type: 'card'
    })
    history.push(destinationUrl)
  }

  const popularForUserRole =
    currentUser?.role &&
    course.course?.popularWithRoles?.find((role) => role.includes(currentUser.role || ''))

  const startsDate = showStartsAt && prettyStartDateFromMarketingCourse(course)

  return (
    <li className="min-w-[250px] max-w-[350px] cursor-pointer rounded-[5px] bg-rb-white shadow-[0_0_5px_rgba(0,0,0,.15)]">
      <div
        role="link"
        tabIndex={0}
        onClick={handleOnClick}
        onKeyUp={() => onEnterKeyPress(handleOnClick)}
        className="text hover:text flex h-full flex-col justify-between p-[6%] normal-case text-black no-underline hover:normal-case hover:text-black  hover:no-underline"
      >
        <div>
          <div
            className={`mb-3 flex h-fit flex-col space-y-1 rounded-[5px] p-2 ${
              showStartsAt ? 'sm:h-[180px]' : 'sm:h-[156px]'
            } bg-gradient-to-br ${
              cardType === COURSE_FILTER_LIVE
                ? 'from-[#ebf2bd] to-[#fffff0]'
                : 'from-[#dcfaf9] to-[#fff7ff]'
            }`}
          >
            <div className="mb-4 flex flex-row justify-between">
              <div className="flex">
                <FacePile
                  groupKey={course?.id?.toString()}
                  users={facepileUsers}
                  imageSize="large"
                />
              </div>
              {!hideBookmarkButton && (
                <ContentBookmark
                  contentType={
                    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
                      ? 'Program'
                      : 'Course'
                  }
                  bookmarkId={bookmarkId}
                  isInSavedItems={isInSavedItems}
                  setIsInSavedItems={setIsInSavedItems}
                  bookmarkFolders={bookmarkFolders}
                  bookmark={bookmark}
                  cmsProgramId={
                    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
                      ? course.legacyProgramId
                      : null
                  }
                  courseSlug={
                    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY ? null : slug
                  }
                  restoreBookmark={restoreBookmark}
                  currentFolder={currentFolder}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  handleRemoveFromFolder={handleRemoveFromFolder}
                />
              )}
            </div>
            {startsDate && (
              <p className="mb-.5 w-fit bg-[#b4d44c] px-1 text-xs font-semibold sm:text-[10px]">
                Starts {startsDate}
              </p>
            )}
            <RfHeader2 className="mb-[1%] pb-[2px] font-polysans !font-light !leading-7 tracking-tight line-clamp-3 fluid-text-lg">
              {title}
            </RfHeader2>
          </div>

          <p className="mb-[16px] text-sm !leading-[1.25] text-rb-gray-300">
            {creatorNamesLength > 0 && (
              <span>
                Created by{' '}
                {creatorNames.map((cName, i) => {
                  return `${
                    i === creatorNamesLength - 1 && i !== 0 ? ' & ' : ''
                  }${cName}${i < creatorNamesLength - 2 ? ', ' : ''}`
                })}
              </span>
            )}
          </p>

          <p className="mb-[16px] !leading-[1.25] fluid-text-sm">{description}</p>
        </div>

        <div>
          {popularForUserRole && (
            <div className="mb-6 w-fit">
              <RfParagraphMini className="rounded-full bg-rb-orange-50 px-4 py-2">
                Popular with the job title{' '}
                <span className="font-semibold">{currentUser?.role}</span>
              </RfParagraphMini>
              <span className="px-4 text-xs text-rb-gray-300">Based on your role</span>
            </div>
          )}

          {!popularForUserRole &&
            course.course?.popularWithRoles &&
            course.course?.popularWithRoles.length > 0 && (
              <div className="mb-6 rounded-full bg-rb-green-50 px-4 py-2">
                <RfParagraphMini>
                  Popular with the job title{' '}
                  <span className="font-semibold">
                    {course.course.popularWithRoles?.join(', ')}
                  </span>
                </RfParagraphMini>
              </div>
            )}

          {showCtaText && (
            <Button
              variant="text-only"
              href={destinationUrl}
              size="large"
              onClick={handleOnClick}
              className="p-0 font-normal underline"
            >
              Learn More →
            </Button>
          )}
        </div>
      </div>
    </li>
  )
}

export default CourseCard
