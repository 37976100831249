import { ReactNode } from 'react'

import { ReactComponent as ArtifactsIcon } from './icons/artifacts.svg'
import { ReactComponent as CoursesIcon } from './icons/courses.svg'
import { ReactComponent as GuidesIcon } from './icons/guides.svg'
import ArtificialIntelligenceIcon from './icons/topic-artificial-intelligence.svg'
import CareerDevelopmentIcon from './icons/topic-career-development.svg'
import DataAnalysisIcon from './icons/topic-data-analysis.svg'
import GrowthIcon from './icons/topic-growth.svg'
import HiringIcon from './icons/topic-hiring.svg'
import LeadershipIcon from './icons/topic-leadership.svg'
import MarketingIcon from './icons/topic-marketing.svg'
import ProductManagementIcon from './icons/topic-product-management.svg'
import StrategyIcon from './icons/topic-strategy.svg'
import TeamOperationsIcon from './icons/topic-team-operations.svg'
import UserResearchIcon from './icons/topic-user-research.svg'

export const NUM_LIST_SUBTOPICS = 3

export const CONTENT_TYPES = [
  {
    title: 'Courses',
    subtitle: 'Gain the expertise to unlock step-change career growth',
    icon: <CoursesIcon width={40} height={40} />,
    destination: '/courses'
  },
  {
    title: 'Guides',
    subtitle: 'Follow quick step-by-step frameworks created by top experts',
    icon: <GuidesIcon width={40} height={40} />,
    destination: '/guides'
  },
  {
    title: 'Artifacts',
    subtitle: 'Get inspiration from real-world examples & helpful templates',
    icon: <ArtifactsIcon width={40} height={40} />,
    destination: '/artifacts'
  }
]

export const TOPICS: {
  icon: ReactNode & { props: { src: string } }
  slug: string
  backgroundColor: string
}[] = [
  {
    icon: <img src={ProductManagementIcon} alt="" width={132} />,
    slug: 'product-development',
    // slug: 'product-management',
    backgroundColor: '#F9F9FF'
  },
  {
    icon: <img src={GrowthIcon} alt="" width={132} />,
    slug: 'growth',
    backgroundColor: '#F8FBE9'
  },
  {
    icon: <img src={ArtificialIntelligenceIcon} alt="" width={132} />,
    slug: 'ai',
    backgroundColor: '#F2FBFB'
  },
  {
    icon: <img src={HiringIcon} alt="" width={132} />,
    slug: 'hiring',
    backgroundColor: '#FEFCEC'
  },
  {
    icon: <img src={LeadershipIcon} alt="" width={132} />,
    slug: 'leadership',
    backgroundColor: '#FFF5FA'
  },
  {
    icon: <img src={CareerDevelopmentIcon} alt="" width={132} />,
    slug: 'career-development',
    backgroundColor: '#F9F9FF'
  },
  {
    icon: <img src={DataAnalysisIcon} alt="" width={132} />,
    slug: 'data-analysis',
    backgroundColor: '#FFF9F5'
  },
  {
    icon: <img src={MarketingIcon} alt="" width={132} />,
    slug: 'marketing',
    backgroundColor: '#FFF7F2'
  },
  {
    icon: <img src={UserResearchIcon} alt="" width={132} />,
    slug: 'user-research',
    backgroundColor: '#FEFCEC'
  },
  {
    icon: <img src={TeamOperationsIcon} alt="" width={132} />,
    slug: 'team-operations',
    backgroundColor: '#FFF5FA'
  },
  {
    icon: <img src={StrategyIcon} alt="" width={132} />,
    slug: 'strategy',
    backgroundColor: '#F8FBE9'
  }
]
