import { RefCallback, useCallback, useEffect, useRef, useState } from 'react'

interface QueryConfig {
  [key: string]: {
    minWidth?: number
    maxWidth?: number
  }
}

interface Params {
  [key: string]: boolean
}

export default function useContainerQuery(
  queryConfig: QueryConfig
): [Params, RefCallback<HTMLElement>] {
  const [params, setParams] = useState<Params>({})
  const containerRef = useRef<HTMLElement | null>(null)
  const prevWidth = useRef<number | null>(null)

  const updateParams = useCallback(
    (node: HTMLElement) => {
      const { width } = node.getBoundingClientRect()

      // Prevent unnecessary updates if the width hasn't changed to avoid infinite loops
      if (prevWidth.current === width) return

      const newParams: Params = {}

      Object.keys(queryConfig).forEach((key) => {
        const { minWidth = 0, maxWidth = Infinity } = queryConfig[key]
        newParams[key] = width >= minWidth && width <= maxWidth
      })

      setParams(newParams)
      prevWidth.current = width
    },
    [queryConfig]
  )

  const observer = useRef(
    new ResizeObserver((entries) => {
      if (!entries.length) return
      updateParams(entries[0].target as HTMLElement)
    })
  ).current

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (containerRef.current) {
        // Clean up previous observer if the ref changes
        observer.disconnect()
      }

      if (node) {
        containerRef.current = node
        observer.observe(node)
        updateParams(node)
      } else {
        containerRef.current = null
      }
    },
    [observer, updateParams]
  )

  // Cleanup observer on unmount
  useEffect(() => {
    return () => {
      observer.disconnect()
    }
  }, [observer])

  return [params, setRef]
}
