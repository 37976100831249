import { cn } from '@/lib/utils'

export interface SimpleProgressBarProps {
  className?: string
  totalSteps: number
  completed: number
  backgroundColorClass?: string
  progressColorClass?: string
  showPercentage?: boolean
}

export const SimpleProgressBar = ({
  totalSteps,
  completed,
  backgroundColorClass,
  progressColorClass,
  showPercentage = false
}: SimpleProgressBarProps) => {
  const getProgress = () => {
    const progressPercent =
      totalSteps === 0 ? 0 : Math.round((completed / totalSteps) * 100)
    return progressPercent.toString()
  }

  return (
    <div className="flex flex-col rounded-md">
      <div className="flex items-center">
        <div
          className={cn(
            'mr-4 flex h-2 w-52 justify-start rounded-md bg-[rgba(227,204,189,0.30)]',
            backgroundColorClass
          )}
        >
          <div
            className={cn('bg-rb-success rounded-md', progressColorClass)}
            style={{ width: `${getProgress()}%` }}
          ></div>
        </div>
        {showPercentage && (
          <span className="mx-4 font-sans text-sm font-semibold">{`${getProgress()}%`}</span>
        )}
      </div>
    </div>
  )
}
