import CourseDetailTestimonial from 'domains/CourseDetail/CourseDetailTestimonial'

import CarouselSection from 'components/pagination/CarouselSection/CarouselSection'

import { CourseDetailPartsFragment, TestimonialPartsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

interface CourseDetailTestimonialsProps {
  testimonials: TestimonialPartsFragment[]
  courseDetail: CourseDetailPartsFragment
}

const CourseDetailTestimonials = ({
  testimonials,
  courseDetail
}: CourseDetailTestimonialsProps) => {
  const isTablet = useMediaQuery('(min-width: 960px)')
  const isMedium = useMediaQuery('(min-width: 768px)')
  const isSmall = useMediaQuery('(min-width: 640px)')
  const visibleSlides = isTablet ? 2.5 : isMedium ? 2.25 : isSmall ? 1.5 : 1

  return (
    <div className="space-y-4">
      <CarouselSection
        slideWidth={290}
        visibleSlides={visibleSlides}
        title={
          <span className="rf-h3 !mb-0 font-polysans !text-[24px] !font-light">
            What others are saying
          </span>
        }
        innerClassName="h-full"
      >
        {testimonials.map((testimonial, index) => (
          <CourseDetailTestimonial
            key={testimonial?.id}
            testimonial={testimonial}
            position={index + 1}
            courseDetail={courseDetail}
          />
        ))}
      </CarouselSection>
    </div>
  )
}

export default CourseDetailTestimonials
