import { MarketingH2, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

const InteractiveContentSectionHero = () => {
  return (
    <div className="py-12 md:pt-[80px] md:pb-16 px-4 md:px-[4vw]">
      <div className="flex flex-col tl:flex-row items-center tl:justify-center">
        <MarketingH2 className="flex flex-col items-center chromatic-ignore mb-4 md:mb-2">
          Master Your Craft and Accelerate Your Career
        </MarketingH2>
      </div>
      <MarketingH3 className="text-center">
        Gain the skills and knowledge you need to excel in every aspect of your career.
      </MarketingH3>
    </div>
  )
}

export default InteractiveContentSectionHero
