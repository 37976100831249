import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import SearchResultItem from 'domains/Search/SearchResultItem'
import { SearchLinkType } from 'domains/Search/useSearchResultsFilters'

import { Loading } from 'components'
import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'
import RfMetaSmall from 'components/typography/RfMeta/RfMetaSmall'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { SearchDocumentResultFragment, useSearchDocumentsTopResultsQuery } from 'gql'

import useURLParams from 'hooks/useURLParams'

import EmptySearchItem from './EmptySearchItem'

interface UserCanProps {
  linkToSearchResults?: boolean | null
  viewMemberProfile?: boolean | null
  viewPost?: boolean | null
}

const MemberDirectoryResultSection = ({
  sectionHeader,
  sectionResults,
  userCan,
  handleResultClick,
  linkType,
  searchQuery
}: {
  sectionHeader: string
  sectionResults: SearchDocumentResultFragment[]
  userCan: UserCanProps
  searchQuery: string
  linkType: SearchLinkType
  handleResultClick: (
    e: React.MouseEvent<HTMLAnchorElement>,
    searchPosition: number,
    resultComponent?: string,
    type?: string,
    subtype?: string | null
  ) => void
}) => {
  const { deleteParam, setParam, getQueryString } = useURLParams()

  const formatLinkUrl = () => {
    linkType === SearchLinkType.TOP_RESULTS
      ? deleteParam('type')
      : setParam('type', linkType)
    return `/search?${getQueryString()}`
  }

  const scrollToTop = () => {
    document.getElementById('page')?.scrollTo(0, 0)
  }

  return (
    <div className="mb-[70px]">
      <div className="mb-6 flex h-[40px] items-center">
        <div className="mr-4">
          <RfMetaSmall>{sectionHeader}</RfMetaSmall>
        </div>
        {!!sectionResults?.length && (
          <Link
            to={formatLinkUrl()}
            onClick={scrollToTop}
            className="text-rb-black hover:text-rb-teal-300 hover:no-underline"
          >
            <RfParagraphMini className="flex items-center space-x-2 hover:text-rb-teal-300">
              <span>view all</span>
              <ChevronRightThinIcon className="h-3 fill-current" />
            </RfParagraphMini>
          </Link>
        )}
      </div>
      <div>
        {sectionResults?.length ? (
          <ul className="grid grid-cols-1 gap-6 p-0">
            {sectionResults.map((doc: SearchDocumentResultFragment, index) => (
              <SearchResultItem
                key={doc.id}
                document={doc}
                searchQuery={searchQuery}
                searchPosition={index + 1}
                userCan={userCan}
                handleResultClick={handleResultClick}
              />
            ))}
          </ul>
        ) : (
          <EmptySearchItem
            message={`No ${sectionHeader} matching`}
            searchQuery={searchQuery}
          />
        )}
      </div>
    </div>
  )
}

const MemberDirectoryResultsList = ({
  searchQuery,
  handleResultClick,
  userCan
}: {
  searchQuery: string
  userCan: UserCanProps
  handleResultClick: (
    e: React.MouseEvent<HTMLAnchorElement>,
    searchPosition: number,
    resultComponent?: string
  ) => void
}) => {
  const topDocumentsResult = useSearchDocumentsTopResultsQuery({
    variables: {
      query: searchQuery
    }
  })
  const topResults = topDocumentsResult.data?.searchDocumentsTopResults
  const noTopResults = topResults?.members.results.length === 0

  if (!topDocumentsResult.loading && noTopResults) {
    return null
  }

  const sections = [
    {
      header: 'Directory',
      results: topResults?.members.results || [],
      sectionCount: topResults?.members.totalCount.toLocaleString() || '',
      linkType: SearchLinkType.USER,
      isLoading: topDocumentsResult.loading,
      loader: (
        <>
          <div className="mb-12 h-3 w-1/6 animate-pulse rounded-full bg-rb-gray-100" />
          <div className="mb-6 h-16 w-1/4 animate-pulse rounded-lg bg-rb-gray-100" />
          <div className="mb-6 h-16 w-1/4 animate-pulse rounded-lg bg-rb-gray-100" />
          <div className="mb-6 h-16 w-1/4 animate-pulse rounded-lg bg-rb-gray-100" />
        </>
      )
    }
  ]

  return (
    <div>
      {sections.map((section) => (
        <Fragment key={section.header}>
          {section.isLoading && (section.loader || <Loading />)}
          {!section.isLoading && (
            <MemberDirectoryResultSection
              key={section.header}
              sectionHeader={section.header}
              sectionResults={section.results}
              userCan={userCan}
              linkType={section.linkType}
              handleResultClick={handleResultClick}
              searchQuery={searchQuery}
            />
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default MemberDirectoryResultsList
